import { mdiSmoking, mdiSmokingOff } from "@mdi/js";

export const SMOKING_PREVIOUSLY = "Previously";
export const SMOKING_NEVER = "Never";
export const SMOKING_CURRENTLY = "Currently";

export const SMOKING_STATUSES = [
  {
    title: SMOKING_NEVER,
    value: SMOKING_NEVER,
    color: "success",
    icon: mdiSmokingOff,
    dataTestid: "smoker-never",
  },
  {
    title: SMOKING_PREVIOUSLY,
    value: SMOKING_PREVIOUSLY,
    color: "previously",
    icon: mdiSmokingOff,
    dataTestid: "smoker-previously",
  },
  {
    title: SMOKING_CURRENTLY,
    value: SMOKING_CURRENTLY,
    color: "error",
    icon: mdiSmoking,
    dataTestid: "smoker-currently",
  },
];

export const SMOKING_FREQUENCIES = [
  {
    value: 365,
    title: "Daily",
  },
  {
    value: 52,
    title: "Weekly",
  },
  {
    value: 12,
    title: "Monthly",
  },
  {
    value: 2,
    title: "1-2 Times/Year",
  },
];

export const CANNABIS = "Cannabis";
export const CHEW = "Chew";
export const SMOKING_TYPES = [
  CANNABIS,
  CHEW,
  "Cigarettes",
  "Cigars",
  "eCigarettes",
  "Pipe",
];
export const SINGULAR_TYPES = {
  [CANNABIS]: "Cannabis",
  [CHEW]: "Chew",
  Cigarettes: "Cigarette",
  Cigars: "Cigar",
  eCigarettes: "eCigarette",
  Pipe: "Pipe",
};
