import { defineStore } from "#src/stores/state-wrapper.js";
import {
  APPLY_ROUTE_GA,
  APPLY_ROUTE_META_NAMES,
  steps,
} from "#src/enumerations/step-enumeration.js";
import { getStepConfig } from "#src/enumerations/step-config.js";
import { StepRequirements } from "#src/enumerations/data-requirements.js";
import { PRODUCTS, CATEGORIES } from "#src/structures/ProductType.js";

import {
  START_PAGES,
  useInstanceSettingsStore,
} from "#src/stores/instance-settings.js";
import { useEappStore } from "#src/stores/electronic-application.js";
import { useQuotingStore } from "#src/stores/quoting.js";
import { useStepHealthStore } from "#src/stores/step-health.js";
import { useStepSmokerStore } from "#src/stores/step-smoker.js";
import { useStepSecondaryAddresseeStore } from "#src/stores/step-secondary-addressee.js";
import { useCaseStore } from "#src/stores/case.js";
import { useStepEmployerStore } from "#src/stores/step-employer.js";
import { useStepCriticalFailure } from "#src/stores/step-critical-failure.js";
import { useStepPersonalStore } from "#src/stores/step-personal.js";
import { useStepBeneficiaryPrimaryStore } from "#src/stores/step-beneficiary-primary.js";
import { useStepBeneficiaryContingentStore } from "#src/stores/step-beneficiary-contingent.js";
import { useStepBuildStore } from "#src/stores/step-build.js";
import { useStepAddressStore } from "#src/stores/step-address.js";
import { useStepOwnerStore } from "#src/stores/step-owner.js";
import { useStepExistingCoverageStore } from "#src/stores/step-existing-coverage.js";
import { useStepPhysicianStore } from "#src/stores/step-physician.js";
import { useStepCalculatorStore } from "#src/stores/step-calculator.js";
import { useStepUnderwritingStore } from "#src/stores/step-underwriting.js";
import { useStepPaymentStore } from "#src/stores/step-payment.js";
import { useStepContactStore } from "#src/stores/step-contact.js";
import { useStepNameStore } from "#src/stores/step-name.js";
import { useStepGenderStore } from "#src/stores/step-gender.js";
import { useStepBirthdateStore } from "#src/stores/step-birthdate.js";

import { trackPageChange as trackAnalyticsPageChange } from "#src/integrations/analytics.mjs";
import { trackPageChange as trackTagManagerPageChange } from "#src/integrations/analytics.mjs";
import { getEnv, undefinedOrNull } from "#src/util/helpers.js";

export const useFlowStore = (pinia, hot) => {
  return defineStore("flow", {
    state: () => ({ step: null }),
    getters: {
      activeStep() {
        return getStepConfig(this.step, pinia, hot);
      },
      onlyOnePermanentProduct() {
        const instanceSettingsStore = useInstanceSettingsStore(pinia);
        if (instanceSettingsStore.available.length !== 1) return false;
        return instanceSettingsStore.permanentAvailable.length === 1;
      },
      onlyOneTermProduct() {
        const instanceSettingsStore = useInstanceSettingsStore(pinia);
        if (instanceSettingsStore.available.length !== 1) return false;
        return instanceSettingsStore.termAvailable.length === 1;
      },
      onlyOneRopProduct() {
        const instanceSettingsStore = useInstanceSettingsStore(pinia);
        if (instanceSettingsStore.available.length !== 1) return false;
        return instanceSettingsStore.ropAvailable.length === 1;
      },
      onlyAccidentalDeath() {
        const instanceSettingsStore = useInstanceSettingsStore(pinia);
        if (instanceSettingsStore.available.length !== 1) return false;
        return instanceSettingsStore.accidentalDeathAvailable.length === 1;
      },
      onlyFinalExpense() {
        const instanceSettingsStore = useInstanceSettingsStore(pinia);
        if (instanceSettingsStore.available.length !== 1) return false;
        return instanceSettingsStore.products[PRODUCTS.FINAL_EXPENSE];
      },
      onlyNonMed() {
        const instanceSettingsStore = useInstanceSettingsStore(pinia);
        if (!this.onlyOneTermProduct) return false;
        return instanceSettingsStore.products[PRODUCTS.NON_MED];
      },
      isGuaranteedIssue() {
        const eAppStore = useEappStore(pinia);
        const quote = eAppStore.selectedQuote;
        if (!quote?.product?.category) return false;
        const categories = quote.product.category.split(".");
        return categories.includes(CATEGORIES.GUARANTEED_ISSUE);
      },
      // Perhaps these can be based on the quote?
      isAccidentalDeath() {
        const quoting = useQuotingStore(pinia);
        return quoting.params.selected_type === PRODUCTS.ACCIDENTAL_DEATH;
      },
      isLongTermCare() {
        const quoting = useQuotingStore(pinia);
        return quoting.params.selected_type === PRODUCTS.LTC;
      },
      isLinkedBenefit() {
        const quoting = useQuotingStore(pinia);
        return quoting.params.selected_type === PRODUCTS.LINKED_BENEFIT;
      },
      isLife() {
        const quoting = useQuotingStore(pinia);
        if (!quoting.params.selected_type) return false;
        return quoting.params.selected_type !== PRODUCTS.LTC;
      },
      initialStepIsCriticalFailure() {
        const store = useStepCriticalFailure(pinia);
        return Boolean(store.criticalFailureReason);
      },
      initialStepIsCalculator() {
        const instanceSettings = useInstanceSettingsStore(pinia);
        return instanceSettings.start_page === START_PAGES.CALCULATOR;
      },
      initialStepIsDemo() {
        const instanceSettings = useInstanceSettingsStore(pinia);
        return instanceSettings.start_page === START_PAGES.DEMO;
      },
      initialStepIsReferral() {
        const instanceSettings = useInstanceSettingsStore(pinia);
        return instanceSettings.start_page === START_PAGES.REFERRAL;
      },
      initialStepIsAgentSignup() {
        const instanceSettings = useInstanceSettingsStore(pinia);
        const signupSteps = [
          START_PAGES.AGENT_SELECT,
          START_PAGES.AGENT_SIGN_UP,
        ];
        return (
          signupSteps.includes(instanceSettings.start_page) ||
          !instanceSettings.id
        );
      },
      initialStep() {
        if (this.initialStepIsCriticalFailure) {
          return steps.CRITICAL_FAILURE;
        } else if (this.initialStepIsDemo) {
          return steps.DEMO;
        } else if (this.initialStepIsAgentSignup) {
          return steps.AGENT_SIGN_UP;
        }

        const caseStore = useCaseStore(pinia);
        if (caseStore.id && caseStore.uuid) return steps.LOGIN;

        const eApp = useEappStore(pinia);
        if (eApp.id && eApp.uuid) return steps.LOGIN;

        if (this.initialStepIsCalculator) return steps.CALCULATOR;

        if (this.initialStepIsReferral) return steps.REFERRAL;

        return steps.NAME;
      },
      carrierDoesNotRequirePhysician() {
        const eAppStore = useEappStore(pinia);
        return !eAppStore.selectedQuote?.product?.instant_aps;
      },
    },
    actions: {
      async trackEvent({ step }) {
        trackAnalyticsPageChange({ page_title: APPLY_ROUTE_GA[step] });
        trackTagManagerPageChange({ page_title: APPLY_ROUTE_GA[step] });
      },
      updateStepRequirements(stepRequirements) {
        const srDict = StepRequirements(stepRequirements);

        const name = useStepNameStore(pinia);
        name.setStepRequirements(srDict);

        const gender = useStepGenderStore(pinia);
        gender.setStepRequirements(srDict);

        const birthdate = useStepBirthdateStore(pinia);
        birthdate.setStepRequirements(srDict);

        const health = useStepHealthStore(pinia);
        health.setStepRequirements(srDict);

        const smoker = useStepSmokerStore(pinia);
        smoker.setStepRequirements(srDict);

        const build = useStepBuildStore(pinia);
        build.setStepRequirements(srDict);

        const calculator = useStepCalculatorStore(pinia);
        calculator.setStepRequirements();

        const contact = useStepContactStore(pinia);
        contact.setStepRequirements(srDict);

        const addressee = useStepSecondaryAddresseeStore(pinia);
        addressee.setStepRequirements(srDict);

        const employer = useStepEmployerStore(pinia);
        employer.setStepRequirements(srDict);

        const personal = useStepPersonalStore(pinia);
        personal.setStepRequirements(srDict);

        const primaryBeneficiary = useStepBeneficiaryPrimaryStore(pinia);
        primaryBeneficiary.setStepRequirements(srDict);

        const contingentBeneficiary = useStepBeneficiaryContingentStore(pinia);
        contingentBeneficiary.setStepRequirements(srDict);

        const address = useStepAddressStore(pinia);
        address.setStepRequirements(srDict);

        const owner = useStepOwnerStore(pinia);
        owner.setStepRequirements(srDict);

        const existing = useStepExistingCoverageStore(pinia);
        existing.setStepRequirements(srDict);

        const physician = useStepPhysicianStore(pinia);
        physician.setStepRequirements(srDict);

        const payment = useStepPaymentStore(pinia);
        payment.setStepRequirements(srDict);

        const underwriting = useStepUnderwritingStore(pinia);
        underwriting.setStepRequirements(srDict);
      },
      navToStep({ step, event, replace }) {
        const env = getEnv();
        if (!env.SSR) {
          if (event) this.trackEvent({ event, step });

          const isOnCorrectStep =
            !undefinedOrNull(history.state?.step) &&
            step === history.state.step;
          if (isOnCorrectStep) return true;

          if (replace) {
            history.replaceState({ step }, "");
          } else {
            history.pushState({ step }, "");
          }

          let suffix = document.title;
          if (suffix.includes("|")) {
            suffix = suffix.split(" | ")[1];
          }
          document.title = `${APPLY_ROUTE_META_NAMES[step]} | ${suffix}`;
        }

        this.step = step;
      },
      async goToStep({ skipSave, step = null, event = null }) {
        const noCurrentStep = (!step && step !== 0) || !this.activeStep;
        if (noCurrentStep) {
          const stateHasNoStep = this.step === null;
          step = stateHasNoStep ? this.initialStep : this.step;
        }

        const eApp = useEappStore(pinia, hot);

        const nextStepConfig = getStepConfig(step, pinia, hot);
        if (nextStepConfig.beforeLoadAction) {
          let skipsStep;
          try {
            eApp.step_is_saving = true;
            skipsStep = !(await nextStepConfig.beforeLoadAction());
          } catch (e) {
            eApp.step_is_saving = false;
            throw e;
          } finally {
            eApp.step_is_saving = false;
          }

          if (skipsStep) {
            const goPrevious = event === "previous_clicked";
            const nextStep = nextStepConfig[goPrevious ? "previous" : "next"];
            return this.goToStep({ step: nextStep, event, skipSave });
          }
        }
        //don't set step until after the save has occurred
        const eAppIsSavable = eApp.has_logged_in || eApp.has_created_eapp;
        const navIsSavable =
          !getStepConfig(step, pinia, hot)?.skipStepSave && !skipSave;

        if (eAppIsSavable && navIsSavable) {
          try {
            eApp.step_is_saving = true;
            await eApp.updateEapp({ step });
          } catch (e) {
            eApp.step_is_saving = false;
            throw e;
          } finally {
            eApp.step_is_saving = false;
          }
        }

        return { step };
      },
    },
  })(pinia, hot);
};
