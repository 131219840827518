<template>
  <v-snackbar
    id="snackbar"
    :class="{ 'widget-snackbar': isInternallyEmbedded || use_modal }"
    position="fixed"
    class="my-0 mx-auto"
    closable
    :model-value="show"
    :timeout="timeout"
    :color="color"
    @update:model-value="handleInput"
  >
    {{ message }}
    <template #actions>
      <v-btn
        color="white"
        text
        variant="text"
        :icon="mdiClose"
        @click="hideSnackbar"
      />
    </template>
  </v-snackbar>
</template>

<script>
import { storeToRefs, mapState } from "pinia";
import { useSnackbarStore } from "#src/stores/snackbar";
import { useFlowStore } from "#src/stores/flow";

import { mdiClose } from "@mdi/js";
import { useInstanceSettingsStore } from "#src/stores/instance-settings";
import { inject } from "vue";

export default {
  setup() {
    const pinia = inject("pinia");
    const snackbar = useSnackbarStore(pinia);
    const { message, timeout, show, color } = storeToRefs(snackbar);

    function handleInput(v) {
      if (v) return;
      snackbar.hideSnackbar();
    }

    return {
      message,
      timeout,
      show,
      color,
      hideSnackbar: snackbar.hideSnackbar,
      handleInput,
      mdiClose,
    };
  },
  computed: {
    ...mapState(useFlowStore, ["step"]),
    ...mapState(useInstanceSettingsStore, [
      "isInternallyEmbedded",
      "use_modal",
    ]),
  },
};
</script>

<style lang="scss">
#snackbar {
  z-index: 10000;
}

.widget-snackbar {
  justify-content: center;
  align-items: center;
  .v-overlay__content {
    left: unset !important;
    transform: unset !important;
    position: relative;
  }
}
</style>
