import { httpClient } from "#src/HttpClient.js";
import { useInstanceSettingsStore } from "#src/stores/instance-settings.js";

export class ValidationsService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(this.#pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/validations`;
    this.#httpClient = httpClient(this.#pinia);
  }

  async validateEmail(email) {
    try {
      const result = await this.#httpClient.post(
        `${this.#baseUrl}/email_verify`,
        {
          email,
        }
      );
      if (!result || !result.data) return false;
      return result.data.valid;
      // eslint-disable-next-line no-unused-vars
    } catch (e) {
      return false;
    }
  }
}
