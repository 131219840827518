import { steps, stepToRouteName } from "#src/enumerations/step-enumeration.js";
import { defineStore } from "#src/stores/state-wrapper.js";
import { REQS } from "#src/enumerations/data-requirements.js";

import { INSURED_TYPES } from "#src/stores/insured.js";
import { useStepBeneficiaryContingentStore } from "#src/stores/step-beneficiary-contingent.js";
import { useStepExistingCoverageStore } from "#src/stores/step-existing-coverage.js";

export const NOT_OLD_ENOUGH_MESSAGE = "Not old enough";

export const useStepPersonalStore = (pinia, hot) =>
  defineStore("step-personal", {
    state: () => ({
      [`${INSURED_TYPES.INSURED}_net_worth_prompt`]: true,
      [`${INSURED_TYPES.JOINT}_net_worth_prompt`]: true,
      inactive: false,

      requiredFields: {
        guardianGender: false,
        guardianBirthdate: false,
        guardianRelationship: false,
        maritalStatus: false,
        income: false,
        assets: false,
        liabilities: false,
        ssn: false,
        birthLocation: false,
        bankruptcy: false,
        driversLicense: false,
        driversLicenseExpiration: false,
      },
    }),
    getters: {
      step: () => steps.PERSONAL,
      routeName: s => stepToRouteName[s.step],
      previous() {
        const contingent = useStepBeneficiaryContingentStore(pinia);
        if (contingent.inactive) return contingent.previous;
        return contingent.step;
      },
      next() {
        const existingCoverage = useStepExistingCoverageStore(pinia);
        if (existingCoverage.inactive) return existingCoverage.next;
        return existingCoverage.step;
      },
    },
    actions: {
      setStepRequirements(reqs) {
        this.requiredFields.guardianGender = reqs[REQS.INSURED_GUARDIAN_GENDER];
        this.requiredFields.guardianBirthdate =
          reqs[REQS.INSURED_GUARDIAN_BIRTHDATE];
        this.requiredFields.guardianRelationship =
          reqs[REQS.INSURED_GUARDIAN_RELATIONSHIP];

        this.requiredFields.income = reqs[REQS.INSURED_INCOME];
        this.requiredFields.maritalStatus = reqs[REQS.INSURED_MARITAL_STATUS];
        this.requiredFields.assets = reqs[REQS.INSURED_ASSETS];
        this.requiredFields.liabilities = reqs[REQS.INSURED_LIABILITIES];
        this.requiredFields.ssn = reqs[REQS.INSURED_SSN];
        this.requiredFields.birthLocation = reqs[REQS.INSURED_BIRTH_LOCATION];
        this.requiredFields.bankruptcy = reqs[REQS.INSURED_BANKRUPTCY];
        this.requiredFields.driversLicense = reqs[REQS.INSURED_DRIVERS_LICENSE];
        this.requiredFields.driversLicenseExpiration =
          reqs[REQS.INSURED_DRIVERS_LICENSE_EXPIRATION];

        this.inactive = !Object.values(this.requiredFields).includes(true);
      },
    },
  })(pinia, hot);
