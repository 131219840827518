import { steps, stepToRouteName } from "#src/enumerations/step-enumeration.js";
import { defineStore } from "#src/stores/state-wrapper.js";
import { useFlowStore } from "#src/stores/flow.js";
import { useStepNameStore } from "#src/stores/step-name.js";

export const useStepDemoStore = (pinia, hot) =>
  defineStore("step-demo", {
    getters: {
      step: () => steps.DEMO,
      routeName: s => stepToRouteName[s.step],
      previous: () => null,
      next: () => {
        const name = useStepNameStore(pinia);
        return name.step;
      },
      inactive() {
        const flow = useFlowStore(pinia);
        return flow.initialStepIsDemo;
      },
      skipStepSave: () => true,
    },
  })(pinia, hot);
