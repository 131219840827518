<template>
  <img
    v-if="chat && approvedDomainImage"
    :src="approvedDomainImage"
    alt="logo"
    class="rounded-circle contain-object"
    style="transition: 0s"
    :style="{
      height: smIcon ? '35px' : '60px',
      width: smIcon ? '35px' : '60px',
    }"
  />
  <v-icon v-else-if="chat" :color="color" :icon="mdiChat" />
  <img
    v-else-if="!chat && approvedDomainImage"
    :src="approvedDomainImage"
    alt="logo"
    class="h-100 contain-object"
    style="max-width: 250px; max-height: 100px"
  />
</template>

<script>
import { mapState } from "pinia";
import { useInstanceSettingsStore } from "#src/stores/instance-settings";
import { mdiChat } from "@mdi/js";

export default {
  name: "AppLogo",
  props: {
    chat: Boolean,
    smIcon: Boolean,
    color: { type: String, default: "" },
  },
  data() {
    return {
      mdiChat,
    };
  },
  computed: {
    ...mapState(useInstanceSettingsStore, ["logo"]),
    approvedDomainImage() {
      if (!this.logo) return false;

      if (this.logo) {
        return this.logo;
      }
      return false;
    },
  },
};
</script>
