<template>
  <div>
    <v-text-field
      :id="props.dataTestid"
      v-model="model"
      data-lpignore="true"
      variant="outlined"
      density="compact"
      inputmode="email"
      :aria-label="$attrs?.label"
      :loading="validating"
      :class="inputClasses"
      :color="color"
      :base-color="baseColor"
      :error-messages="massagedErrorMessages"
      :data-testid="props.dataTestid"
      v-bind="$attrs"
      @update:model-value="handleInput"
    >
      <template v-if="$slots['append']" #append>
        <slot name="append" />
      </template>
      <template #append-inner>
        <active-save-indicator :controller="savingBuffer" />
      </template>
    </v-text-field>
  </div>
</template>

<script setup>
import { email as emailValidator } from "#src/util/helpers";

import ActiveSaveIndicator from "#src/components/shared/ActiveSaveIndicator.vue";
import {
  useInput,
  useInputProps,
} from "#src/composables/savable-input.composable";
import { computed, inject, ref } from "vue";

import { ValidationsService } from "#src/services/validations.service";
const pinia = inject("pinia");
const validationsService = new ValidationsService(pinia);
const props = defineProps(useInputProps());

const {
  model,
  inputClasses,
  color,
  baseColor,
  errorMessages,
  savingBuffer,
  validateAndSave,
  externallyValid,
} = useInput(props, pinia);

function handleInput() {
  handleTyping();
  validate();
}

const isTyping = ref(false);
let typingTimer;
function handleTyping() {
  isTyping.value = true;
  if (typingTimer) clearTimeout(typingTimer);
  typingTimer = setTimeout(() => {
    isTyping.value = false;
  }, 500);
}

const validating = ref(false);
let emailTimer;
async function validate() {
  externallyValid.value = null;
  if (emailTimer) clearTimeout(emailTimer);

  emailTimer = setTimeout(async () => {
    if (emailValidator(model.value)) {
      validating.value = true;
      const result = await validationsService.validateEmail(model.value);
      externallyValid.value = result;
      validateAndSave();
      validating.value = false;
    } else {
      validateAndSave();
    }
  }, 300);
}

const massagedErrorMessages = computed(() => {
  return validating.value || isTyping.value ? [] : errorMessages.value;
});
</script>
