const minHeight = 12;
const maxHeight = 90;
const heightsRaw = [];
for (let i = minHeight; i < maxHeight + 1; i++) {
  heightsRaw.push(i);
}

const heightToString = function (value) {
  const inchesInFoot = 12;

  const quotient = Math.floor(value / inchesInFoot);
  const remainder = value % inchesInFoot;

  return `${quotient}' ${remainder}"`;
};

const heights = heightsRaw.map(value => {
  if (!value) {
    return {
      title: "",
      value,
    };
  }
  return {
    title: heightToString(value),
    value,
  };
});
export default heights;
