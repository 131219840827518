<template>
  <v-row dense>
    <v-col cols="12" lg="6">
      <email-input
        v-model="email"
        label="Email"
        placeholder="your.email@example.com"
        :disabled="loading"
        :class="{
          'success-field': emailValidation.success,
          'error-field': emailValidation.errorMessages.length,
        }"
        :base-color="emailValidation.success ? 'success' : 'basic'"
        :color="emailValidation.success ? 'success' : 'primary'"
        :error-messages="emailValidation.errorMessages"
        @valid="emailIsValid = $event"
        @update:model-value="dirty.email = $event"
      />
    </v-col>
    <v-col cols="12" lg="6">
      <v-text-field
        v-model="password"
        type="password"
        name="password"
        minlength="8"
        variant="outlined"
        density="compact"
        required
        label="Create a Password"
        placeholder="Password"
        :prepend-inner-icon="mdiLockOpen"
        :class="{
          'success-field': passwordValidation.success,
          'error-field': passwordValidation.errorMessages.length,
        }"
        :base-color="passwordValidation.success ? 'success' : 'basic'"
        :color="passwordValidation.success ? 'success' : 'primary'"
        :error-messages="passwordValidation.errorMessages"
        :disabled="loading"
        @update:model-value="dirty.password = $event"
      />
    </v-col>
    <v-col cols="12">
      <v-row align="center" justify="center" class="px-3">
        <v-checkbox
          v-model="termsAccepted"
          class="checkbox-opacity"
          density="compact"
          :class="{
            'error-checkbox': termsAcceptedValidation.errorMessages.length,
            'success-checkbox': termsAcceptedValidation.success,
          }"
          :base-color="termsAcceptedValidation.success ? 'success' : 'basic'"
          :color="termsAcceptedValidation.success ? 'success' : 'primary'"
          :error-messages="termsAcceptedValidation.errorMessages"
          :disabled="loading"
          @update:model-value="dirty.termsAccepted = $event"
        >
          <template #label>
            <span>I agree to the</span>
            <a
              href="https://github.com/back9ins/terms"
              target="_blank"
              class="pl-1 text-primary clickable full-opacity"
              @click.stop="event => event.stopPropagation()"
            >
              terms and conditions.
            </a>
          </template>
        </v-checkbox>
      </v-row>
    </v-col>
    <v-col cols="12" align="center">
      <div class="app-item-max-width">
        <v-btn
          class="text-none m-w-100"
          color="primary"
          block
          :loading="loading"
          @click="submit"
        >
          <v-icon class="mr-1" :icon="mdiAccountPlus" /> Sign Up
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import EmailInput from "#src/components/inputs/EmailInput.vue";

import { SocketService } from "#src/services/socket.service";
import { UsersService } from "#src/services/users.service";

import { mapState, mapActions } from "pinia";
import { useSnackbarStore } from "#src/stores/snackbar";
import { parseErrorMessage } from "#src/util/helpers";
import { useDialogStore } from "#src/stores/dialog";
import { useEappStore } from "#src/stores/electronic-application";

import { useInstanceSettingsStore } from "#src/stores/instance-settings";
import { mdiAccountPlus, mdiLockOpen } from "@mdi/js";
import { inject } from "vue";

export default {
  name: "AgentSignupForm",
  components: { EmailInput },
  props: {
    npn: {
      type: String,
      default: "",
    },
  },
  emits: ["loading"],
  setup() {
    const pinia = inject("pinia");
    const socketService = new SocketService(pinia);
    const usersService = new UsersService(pinia);
    return { socketService, usersService };
  },
  data() {
    return {
      mdiLockOpen,
      mdiAccountPlus,
      emailTimer: null,
      email: "",
      emailIsValid: null,
      password: "",
      termsAccepted: "",
      loading: false,
      pusher: {},
      dirty: {
        email: false,
        password: false,
        termsAccepted: false,
      },
    };
  },
  computed: {
    ...mapState(useInstanceSettingsStore, [
      "assignmentNpn",
      "connections",
      "commissionLock",
      "marketingManagerId",
      "referData",
    ]),
    ...mapState(useEappStore, ["parent_url"]),
    emailValidation() {
      const errorMessages = [];
      if (this.dirty.password) {
        if (!this.email) errorMessages.push("Required");
        if (!this.emailIsValid) errorMessages.push("Invalid Email");
      }

      const success = !errorMessages.length && Boolean(this.email);
      return {
        success,
        errorMessages,
      };
    },
    passwordValidation() {
      const errorMessages = [];
      if (this.dirty.password) {
        if (!this.password) errorMessages.push("Required");
      }

      const success = !errorMessages.length && Boolean(this.password);
      return {
        success,
        errorMessages,
      };
    },
    termsAcceptedValidation() {
      const errorMessages = [];
      if (this.dirty.termsAccepted) {
        if (!this.termsAccepted) errorMessages.push("Required");
      }
      const success = !errorMessages.length && Boolean(this.termsAccepted);
      return {
        success,
        errorMessages,
      };
    },
  },
  beforeUnmount() {
    if (this.pusher.disconnect) this.pusher.disconnect();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    ...mapActions(useDialogStore, ["showMessageDialog"]),
    async submit() {
      Object.keys(this.dirty).forEach(key => (this.dirty[key] = true));

      const isValid = [
        this.emailValidation,
        this.passwordValidation,
        this.termsAcceptedValidation,
      ].every(v => !v.errorMessages.length && v.success === true);

      if (!isValid) return;

      this.loading = true;
      this.$emit("loading", true);
      this.loadingMessage = "Signing You Up for Quote & Apply";

      this.pusher = await this.socketService.initSocketForSignup(
        this.npn,
        this.createUserHandler,
        {
          readyHandler: this.readyHandler,
        }
      );
    },
    async createUserHandler() {
      const body = {
        email: this.email,
        password: this.password,
        source: null,
        connections: this.connections.value,
        npn: this.npn,
        assignment_npn: this.assignmentNpn,
        commission_lock: this.commissionLock,
        marketing_manager_id: this.marketingManagerId,
      };

      if (this.referData) {
        body.referrer_id = this.referData.referrer_id;
        body.referrer_type = this.referData.referrer_type;
      }

      if (this.parent_url) {
        try {
          const uri = new URL(this.parent_url);
          body.source = uri.origin + uri.pathname;
        } catch (e) {
          //do nothing, bad url
        }
      }
      try {
        await this.usersService.createUser(body);
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
        this.$emit("loading", false);
      }
    },
    readyHandler({ ready, url }) {
      if (!ready) return;
      this.loading = false;
      this.$emit("loading", false);

      const search = new URLSearchParams();
      const query = { ...this.$route.query };
      const skippableKeys = ["mode", "prefill"];
      let hasPrefillData = false;
      let includesPrefillKey = false;
      Object.keys(query).forEach(key => {
        if (skippableKeys.includes(key)) return;
        hasPrefillData = true;
        if (key === "prefill") includesPrefillKey = true;
        search.append(key, query[key]);
      });

      if (hasPrefillData && !includesPrefillKey) {
        search.append("prefill", "true");
      }

      const qaHref = `${url}?${search}`;
      const settingStore = useInstanceSettingsStore();
      const bossHref = `${settingStore.app_url}/sign-in?email=${this.email}`;

      this.showMessageDialog({
        title: "View Back Office Support System",
        message:
          "Signing up for Quote & Apply also gives you access to BackNine's Back Office Support System, BOSS.",
        confirmText: "Continue to BOSS",
        cancelText: "Continue to Quote & Apply",
        cancelHref: qaHref,
        confirmHref: bossHref,
        persistent: true,
        spacer: false,
      });
    },
  },
  validations() {
    return {
      email: {
        required: Boolean,
      },
      password: { required: Boolean },
      termsAccepted: { required: val => Boolean(val) },
    };
  },
};
</script>
