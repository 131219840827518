<template>
  <div
    :class="{
      'px-3':
        props.controller.saving ||
        props.controller.saved ||
        props.controller.error,
    }"
  >
    <v-progress-circular
      v-if="props.controller.saving"
      key="saving"
      indeterminate
      size="24"
      width="2"
      aria-label="Saving"
    />
    <v-icon
      v-else-if="props.controller.saved"
      key="saved"
      color="success"
      :icon="mdiCheckboxMarkedCircleOutline"
    />
    <v-tooltip
      v-else-if="props.controller.error"
      key="error"
      bottom
      attach="#application-flow"
      :aria-label="`Save failed because ${props.controller.errorMessage}`"
    >
      <template #activator="{ props: tooltipProps }">
        <div
          v-bind="tooltipProps"
          :aria-label="`Save failed because ${props.controller.errorMessage}`"
        >
          <v-icon color="error" :icon="mdiAlertCircleOutline" />
        </div>
      </template>
      <span>Save failed because {{ props.controller.errorMessage }} </span>
    </v-tooltip>
  </div>
</template>

<script setup>
import { mdiAlertCircleOutline, mdiCheckboxMarkedCircleOutline } from "@mdi/js";

const props = defineProps({
  controller: {
    type: Object,
    required: true,
  },
});
</script>
