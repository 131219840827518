<template>
  <div>
    <v-combobox
      :id="props.dataTestid"
      v-model:search="search"
      :aria-label="$attrs.label"
      variant="outlined"
      density="compact"
      item-title="description"
      return-object
      no-filter
      persistent-hint
      hide-no-data
      hint=" "
      auto-select-first="exact"
      name="address"
      :class="inputClasses"
      :color="color"
      :base-color="baseColor"
      :error-messages="errorMessages"
      :data-testid="props.dataTestid"
      :prepend-inner-icon="mdiSignRealEstate"
      :disabled="loading || loadingMaps"
      v-bind="$attrs"
      :items="places"
      :autocomplete="props.autocomplete"
      @update:model-value="handleSelect"
    >
      <template #message="{ message }">
        <v-row class="ma-0">
          {{ message }}
          <v-spacer />
          <a
            class="text-primary clickable"
            :data-testid="`${props.dataTestid}-manual-entry`"
            @click="switchToManual"
            >Manual Entry</a
          >
        </v-row>
      </template>
      <template v-if="!search.length" #no-data>
        <v-list-item>
          <v-list-item-title>
            Search for your <strong>Address</strong>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template
        v-if="loading || loadingResults || loadingMaps || loadingPlace"
        #append-inner
      >
        <v-progress-circular indeterminate aria-label="Loading" />
      </template>
      <template v-if="search" #append-item>
        <v-list-item link @click="switchToManual">
          <v-list-item-title> Can't Find Your Address? </v-list-item-title>
        </v-list-item>
      </template>
    </v-combobox>

    <input
      v-model="autocompleter.city"
      class="autocomplete-input"
      name="city"
      autocomplete="city"
      aria-hidden="true"
      tabindex="-1"
    />
    <input
      v-model="autocompleter.city"
      class="autocomplete-input"
      position="absolute"
      name="address-level2"
      autocomplete="address-level2"
      aria-hidden="true"
      tabindex="-1"
    />
    <input
      v-model="autocompleter.state"
      class="autocomplete-input"
      position="absolute"
      name="state"
      autocomplete="state"
      aria-hidden="true"
      tabindex="-1"
    />
    <input
      v-model="autocompleter.state"
      class="autocomplete-input"
      position="absolute"
      name="address-level1"
      autocomplete="address-level1"
      aria-hidden="true"
      tabindex="-1"
    />
    <input
      v-model="autocompleter.country"
      class="autocomplete-input"
      position="absolute"
      autocomplete="country"
      name="country"
      aria-hidden="true"
      tabindex="-1"
    />
    <input
      v-model="autocompleter.zip"
      class="autocomplete-input"
      position="absolute"
      autocomplete="postal-code"
      name="postal-code"
      aria-hidden="true"
      tabindex="-1"
    />
  </div>
</template>

<script setup>
import { useGoogleMaps } from "#src/stores/google-maps";
import { mdiSignRealEstate } from "@mdi/js";
import { inject, ref, onMounted, watch, computed } from "vue";

const emit = defineEmits(["switch-to-manual", "error", "update:model-value"]);

import {
  useInput,
  useInputProps,
} from "#src/composables/savable-input.composable";

const pinia = inject("pinia");
const props = defineProps({
  ...useInputProps(),
  autocomplete: {
    type: String,
    required: false,
    default: null,
  },
  loading: Boolean,
});

const autocompleter = ref({
  city: "",
  state: "",
  country: "",
  zip: "",
});
const googleMaps = useGoogleMaps(pinia);

const search = ref("");
const places = ref([]);
const loadingMaps = ref(false);
const loadingResults = ref(false);
const loadingPlace = ref(false);

const hasAutofilled = computed(() => {
  return Object.values(autocompleter.value).some(Boolean);
});
async function getPlaces() {
  try {
    loadingResults.value = true;
    const newPlaces = await googleMaps.getPlaces(search.value);
    places.value.splice(0, places.value.length);
    places.value.push(...newPlaces);
  } catch (e) {
    // do nothing
  } finally {
    loadingResults.value = false;
  }
}

async function handleSelect(item) {
  if (!item.place_id) return;
  try {
    loadingPlace.value = true;
    const address = await googleMaps.getPlaceDetails(item.place_id);
    emit("update:model-value", address);
    if (props.overrideSave) await props.overrideSave();
  } catch (e) {
    // do nothing
  } finally {
    loadingPlace.value = false;
  }
}

let timer;
function debounceAndGetPlaces() {
  if (hasAutofilled.value) return;
  if (!search.value) {
    places.value.splice(0, places.value.length);
    return;
  }

  if (timer) clearTimeout(timer);
  timer = setTimeout(getPlaces, 200);
}

async function getOrCreateLib() {
  try {
    loadingMaps.value = true;
    await googleMaps.initGoogleMaps();
  } catch (e) {
    emit("error");
  } finally {
    loadingMaps.value = false;
  }
}

function switchToManual() {
  emit("switch-to-manual");
}

const { inputClasses, color, baseColor, errorMessages } = useInput(
  props,
  pinia
);

onMounted(() => getOrCreateLib());

watch(search, debounceAndGetPlaces);
watch(hasAutofilled, v => {
  if (!v) return;
  setTimeout(() => {
    emit("update:model-value", {
      street_address: search.value,
      city: autocompleter.value.city,
      state: autocompleter.value.state,
      country: autocompleter.value.country,
      zip: autocompleter.value.zip,
    });
    if (props.overrideSave) props.overrideSave();
  }, 200);
});
</script>

<style lang="scss">
.autocomplete-input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
</style>
