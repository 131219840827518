<template>
  <rating-input
    v-model="model"
    data-lpignore="true"
    variant="outlined"
    density="compact"
    :class="inputClasses"
    :color="color"
    :base-color="baseColor"
    :error-messages="errorMessages"
    :data-testid="props.dataTestid"
    v-bind="$attrs"
    @update:model-value="debounceAndValidate"
    @click="debounceAndValidate"
  >
    <template #append-inner>
      <active-save-indicator :controller="savingBuffer" />
    </template>
    <template v-if="$slots.message" #message>
      <slot name="message" />
    </template>
  </rating-input>
</template>

<script setup>
import RatingInput from "#src/components/inputs/RatingInput.vue";
import ActiveSaveIndicator from "#src/components/shared/ActiveSaveIndicator.vue";
import {
  useInput,
  useInputProps,
} from "#src/composables/savable-input.composable";
import { inject } from "vue";
const pinia = inject("pinia");
const props = defineProps(useInputProps());

const {
  model,
  inputClasses,
  color,
  baseColor,
  errorMessages,
  savingBuffer,
  debounceAndValidate,
} = useInput(props, pinia, 0);
</script>
