import { steps, stepToRouteName } from "#src/enumerations/step-enumeration.js";
import { defineStore } from "#src/stores/state-wrapper.js";

import { useEappStore } from "#src/stores/electronic-application.js";
import { useStepBirthdateStore } from "#src/stores/step-birthdate.js";
import { useStepCompareStore } from "#src/stores/step-compare.js";
import { useStepBenefitEstimationStore } from "#src/stores/step-benefit-estimation.js";
import { useStepQuoteStore } from "#src/stores/step-quote.js";
import { useStepPreQuoteInformationStore } from "#src/stores/step-pre-quote-information.js";

export const useStepLifePriorityStore = (pinia, hot) =>
  defineStore("step-life-priority", {
    getters: {
      step: () => steps.LIFE_INSURANCE_PRIORITY,
      routeName: s => stepToRouteName[s.step],
      next() {
        const eAppStore = useEappStore(pinia);
        const priority = eAppStore.assistance.topPriority;
        const comparePriorities = ["term", "rop_term", "permanent", "ltc"];
        const benefitEstimationPriorities = ["supplement_retirement", "growth"];
        const preQuoteInformationStepPriorities = ["income_rider"];
        let goToProtection = false;

        if (!priority || comparePriorities.includes(priority)) {
          goToProtection = true;
        }
        const goToEst = benefitEstimationPriorities.includes(priority);
        const goToInfo = preQuoteInformationStepPriorities.includes(priority);
        const compareStep = useStepCompareStore(pinia);
        if (goToProtection) return compareStep.step;

        const benefit = useStepBenefitEstimationStore(pinia);
        if (goToEst) return benefit.step;

        const preQuoteInfo = useStepPreQuoteInformationStore(pinia);
        if (goToInfo) return preQuoteInfo.step;

        const quote = useStepQuoteStore(pinia);
        return quote.step;
      },
      previous: () => {
        const birthdate = useStepBirthdateStore(pinia);
        if (birthdate.inactive) return birthdate.previous;
        return birthdate.step;
      },
      skippable: () => {
        const eApp = useEappStore(pinia);
        return !eApp.assistance.topPriority;
      },
    },
  })(pinia, hot);
