import { useInstanceSettingsStore } from "#src/stores/instance-settings.js";
import {
  useInsuredStore,
  INSURED_TYPES,
  usePrimaryInsuredStore,
} from "#src/stores/insured.js";
import { useSmokingUsage } from "#src/stores/insured-smoking-usage.js";
import { useEappStore } from "#src/stores/electronic-application.js";

import { CONCEPTS, PRODUCTS } from "#src/structures/ProductType.js";
import AccidentalDeath from "#src/structures/quote-params/AccidentalDeath.js";
import LinkedBenefit from "#src/structures/quote-params/LinkedBenefit.js";
import FinalExpense from "#src/structures/quote-params/FinalExpense.js";
import LongTermCare from "#src/structures/quote-params/LongTermCare.js";
import IncomeRider from "#src/structures/quote-params/IncomeRider.js";
import { NonMedQuoteParams, Term } from "#src/structures/quote-params/Term.js";
import ReturnOfPremium from "#src/structures/quote-params/ReturnOfPremium.js";
import WholeLife from "#src/structures/quote-params/WholeLife.js";
import {
  AccumulationIUL,
  ProtectionIUL,
  ProtectionVUL,
  UniversalLife,
  AllUniversalLife,
} from "#src/structures/quote-params/UniversalLife.js";

import { JOINT_INSURED_PARTNER_DISCOUNT } from "#src/data/quoteParams.js";
import { isBoolean } from "#src/util/helpers.js";

function greaterThanZeroAndNumber(value) {
  return value >= 0 && !isNaN(value) && ![null, undefined].includes(value);
}

export function validatePartyData(
  pinia,
  partyKey,
  { validationItems = [], errorKey }
) {
  const insured = useInsuredStore(partyKey, pinia);

  const items = {};

  validationItems.forEach(i => {
    if (i === "smoking_statuses") {
      if (!insured.smoker.model) return;
      if (insured.smoker.model === "Never") return;
      if (!insured.smoking_statuses.length) {
        items[`${errorKey}.smoking_statuses`] = {
          validation: ["Must include at least one smoking_status"],
          value: null,
        };
        return;
      }
      const smokingStatusItems = [];
      insured.smoking_statuses.forEach((id, index) => {
        smokingStatusItems.push("category", "frequency");
        if (insured.smoker.model === "Previously")
          smokingStatusItems.push("last_use_date");

        const usage = useSmokingUsage(id, pinia);

        smokingStatusItems.forEach(v => {
          usage[v].validate();
          items[`${errorKey}.smoking_status.${index}.${v}`] = {
            validation: usage[v].errorMessages,
            value: usage[v].model,
          };
        });
      });
    } else {
      insured[i].validate();
      items[`${errorKey}.${i}`] = {
        validation: insured[i].errorMessages,
        value: insured[i].model,
      };
    }
  });

  return items;
}

function validateNumber(value, { isOptional = false } = {}) {
  const errorMessages = [];
  if (isOptional && !value && value !== 0) return [];
  if (!greaterThanZeroAndNumber(value)) {
    errorMessages.push("Must be greater than 0");
  }
  return errorMessages;
}

function validateSelectList(value, options) {
  const errorMessages = [];
  if (!options.includes(value)) {
    errorMessages.push(
      `Must be one of the following: ${options.map(v => (v === null ? "null" : v)).join(", ")}`
    );
  }
  return errorMessages;
}

function validateBoolean(value, { isOptional = false } = {}) {
  const errorMessages = [];
  if (isOptional && !value && value !== false) return [];
  if (!isBoolean(value)) errorMessages.push("Must be true or false");
  return errorMessages;
}

function validateState(pinia, value) {
  const instance = useInstanceSettingsStore(pinia);
  const options = instance.agent.insurance_licenses.map(l => l.state);
  return validateSelectList(value, options);
}

export const ValidateAccidentalDeathQuoteParameters = (pinia, params) => {
  const product = AccidentalDeath();

  const modeOptions = product.modes.map(v => v.value);

  const validationItems = [
    "first_name",
    "last_name",
    "birthdate",
    "gender",
    "smoker",
    "smoking_statuses",
  ];

  const eApp = useEappStore(pinia);
  const renderRules = product.renderRules(null, {
    conversion: eApp.conversion,
  });
  if (renderRules.insuredHealth) {
    validationItems.push("health");
  }

  return {
    selected_type: { validation: [], value: PRODUCTS.ACCIDENTAL_DEATH },
    face_amount: {
      validation: validateSelectList(params.face_amount, product.faceAmounts),
      value: params.face_amount,
    },
    save_age: {
      validation: validateBoolean(params.save_age, { isOptional: true }),
      value: params.save_age || product.defaults.save_age,
    },
    mode: {
      validation: validateSelectList(params.mode, modeOptions),
      value: params.mode,
    },
    state: {
      validation: validateState(pinia, params.state),
      value: params.state,
    },
    ...validatePartyData(pinia, INSURED_TYPES.INSURED, {
      validationItems,
      errorKey: "insured",
    }),
  };
};
// validate based off render rules?
export const ValidateIncomeRiderQuoteParameters = (pinia, params) => {
  const product = IncomeRider();
  const insured = usePrimaryInsuredStore(pinia);

  const ages = product.generateIncomeAges(insured.birthdate.model);

  const validationItems = ["first_name", "last_name", "birthdate", "gender"];

  const eApp = useEappStore(pinia);
  const renderRules = product.renderRules(params, {
    conversion: eApp.conversion,
  });

  let quoteParamValidation = {
    selected_type: { validation: [], value: PRODUCTS.INCOME_RIDER },
    state: {
      validation: validateState(pinia, params.state),
      value: params.state,
    },
    income_start_age: {
      validation: [...validateSelectList(params.income_start_age, ages)],
      value: params.income_start_age,
    },
    solve: {
      validation: [
        ...validateSelectList(
          params.solve,
          product.solves.map(v => v.value)
        ),
      ],
      value: params.solve,
    },
    joint_income: {
      validation: validateBoolean(params.joint_income),
      value: params.joint_income,
    },
    ...validatePartyData(pinia, INSURED_TYPES.INSURED, {
      validationItems,
      errorKey: "insured",
    }),
  };

  if (renderRules.premium) {
    quoteParamValidation.premium = {
      validation: validateNumber(params.premium),
      value: params.premium,
    };
  }

  if (renderRules.incomeAmount) {
    quoteParamValidation.income_amount = {
      validation: validateNumber(params.income_amount),
      value: params.income_amount,
    };
  }

  if (params.joint_income) {
    quoteParamValidation = {
      ...quoteParamValidation,
      ...validatePartyData(pinia, INSURED_TYPES.INSURED, {
        validationItems: ["first_name", "last_name", "birthdate"],
        errorKey: "joint_insured",
      }),
    };
  }

  return quoteParamValidation;
};

export const ValidateFinalExpenseQuoteParameters = (pinia, params) => {
  const product = FinalExpense();

  const modeOptions = product.modes.map(v => v.value);

  const validationItems = [
    "first_name",
    "last_name",
    "birthdate",
    "gender",
    "smoker",
    "smoking_statuses",
  ];

  const eApp = useEappStore(pinia);
  const renderRules = product.renderRules(null, {
    conversion: eApp.conversion,
  });
  if (renderRules.insuredHealth) {
    validationItems.push("health");
  }

  return {
    selected_type: { validation: [], value: product.type },
    face_amount: {
      validation: validateNumber(params.face_amount),
      value: params.face_amount,
    },
    guaranteed_issue: {
      validation: validateBoolean(params.guaranteed_issue),
      value: params.guaranteed_issue,
    },
    save_age: {
      validation: validateBoolean(params.save_age, { isOptional: true }),
      value: params.save_age || product.defaults.save_age,
    },
    mode: {
      validation: validateSelectList(params.mode, modeOptions),
      value: params.mode,
    },
    state: {
      validation: validateState(pinia, params.state),
      value: params.state,
    },
    ...validatePartyData(pinia, INSURED_TYPES.INSURED, {
      validationItems,
      errorKey: "insured",
    }),
  };
};

// MOVE VALIDATION ITEMS TO QUOTE ITSELF?
export const ValidateROPQuoteParameters = (pinia, params) => {
  const product = ReturnOfPremium();

  const modeOptions = product.modes.map(v => v.value);

  const validationItems = [
    "first_name",
    "last_name",
    "birthdate",
    "gender",
    "smoker",
    "smoking_statuses",
  ];

  const eApp = useEappStore(pinia);
  const renderRules = product.renderRules(null, {
    conversion: eApp.conversion,
  });
  if (renderRules.insuredHealth) {
    validationItems.push("health");
  }

  return {
    selected_type: { validation: [], value: product.type },
    face_amount: {
      validation: validateNumber(params.face_amount),
      value: params.face_amount,
    },
    active_years: {
      validation: validateSelectList(params.active_years, product.termLengths),
      value: params.active_years,
    },
    save_age: {
      validation: validateBoolean(params.save_age, { isOptional: true }),
      value: params.save_age || product.defaults.save_age,
    },
    mode: {
      validation: validateSelectList(params.mode, modeOptions),
      value: params.mode,
    },
    state: {
      validation: validateState(pinia, params.state),
      value: params.state,
    },
    ...validatePartyData(pinia, INSURED_TYPES.INSURED, {
      validationItems,
      errorKey: "insured",
    }),
  };
};

export const ValidateTermQuoteParameters = (pinia, params) => {
  return ValidateBaseTermParameters(pinia, params, Term());
};
export const ValidateNonMedQuoteParameters = (pinia, params) => {
  return ValidateBaseTermParameters(pinia, params, NonMedQuoteParams());
};

const ValidateBaseTermParameters = (pinia, params, product) => {
  const modeOptions = product.modes.map(v => v.value);

  const validationItems = [
    "first_name",
    "last_name",
    "birthdate",
    "gender",
    "smoker",
    "smoking_statuses",
  ];

  const eApp = useEappStore(pinia);
  const renderRules = product.renderRules(null, {
    conversion: eApp.conversion,
  });
  if (renderRules.insuredHealth) {
    validationItems.push("health");
  }

  return {
    selected_type: { validation: [], value: product.type },
    face_amount: {
      validation: validateNumber(params.face_amount),
      value: params.face_amount,
    },
    active_years: {
      validation: validateSelectList(params.active_years, product.termLengths),
      value: params.active_years,
    },
    living_benefits: {
      validation: validateBoolean(params.living_benefits, { isOptional: true }),
      value: params.living_benefits || product.defaults.living_benefits,
    },
    save_age: {
      validation: validateBoolean(params.save_age, { isOptional: true }),
      value: params.save_age || product.defaults.save_age,
    },
    mode: {
      validation: validateSelectList(params.mode, modeOptions),
      value: params.mode,
    },
    state: {
      validation: validateState(pinia, params.state),
      value: params.state,
    },
    ...validatePartyData(pinia, INSURED_TYPES.INSURED, {
      validationItems,
      errorKey: "insured",
    }),
  };
};

export const ValidateWholeLifeQuoteParameters = (pinia, params) => {
  const product = WholeLife();
  const eApp = useEappStore(pinia);
  const requiredFields = product.renderRules(params, {
    conversion: eApp.conversion,
  });

  const modes = product.modes.map(v => v.value);
  let quoteParamValidation = {
    selected_type: { validation: [], value: product.type },
    mode: {
      validation: validateSelectList(params.mode, modes),
      value: params.mode,
    },
    state: {
      validation: validateState(pinia, params.state),
      value: params.state,
    },
  };

  if (requiredFields.solve) {
    const solves = [
      ...product.solves.death_benefit_solves,
      ...product.solves.premium_solves,
    ].map(v => v.value);
    quoteParamValidation.solve = {
      validation: validateSelectList(params.solve, solves),
      value: params.solve,
    };
  }

  if (requiredFields.faceAmount) {
    quoteParamValidation.face_amount = {
      validation: validateNumber(params.face_amount),
      value: params.face_amount,
    };
  }

  if (requiredFields.premium) {
    quoteParamValidation.premium = {
      validation: validateNumber(params.premium),
      value: params.premium,
    };
  }

  if (requiredFields.payDuration) {
    const payDurations = product.payDurationOptions.map(v => v.value);
    quoteParamValidation.pay_duration = {
      validation: validateSelectList(params.pay_duration, payDurations),
      value: params.pay_duration,
    };
  }

  const validationItems = [
    "first_name",
    "last_name",
    "birthdate",
    "gender",
    "smoker",
    "smoking_statuses",
  ];

  if (requiredFields.insuredHealth) {
    validationItems.push("health");
  }

  return {
    ...quoteParamValidation,
    ...validatePartyData(pinia, INSURED_TYPES.INSURED, {
      validationItems,
      errorKey: "insured",
    }),
  };
};

export const ValidateAccumulationIulQuoteParameters = (pinia, params) => {
  return ValidateIULQuoteParameters(pinia, params, AccumulationIUL());
};

export const ValidateProtectionIulQuoteParameters = (pinia, params) => {
  return ValidateIULQuoteParameters(pinia, params, ProtectionIUL());
};

export const ValidateProtectionVulQuoteParameters = (pinia, params) => {
  return ValidateIULQuoteParameters(pinia, params, ProtectionVUL());
};

export const ValidateUniversalLifeQuoteParameters = (pinia, params) => {
  return ValidateIULQuoteParameters(pinia, params, UniversalLife());
};

export const ValidateAllUlQuoteParameters = (pinia, params) => {
  return ValidateIULQuoteParameters(pinia, params, AllUniversalLife());
};

const ValidateIULQuoteParameters = (pinia, params, product) => {
  const insured = usePrimaryInsuredStore(pinia);
  const eApp = useEappStore(pinia);

  const modeOptions = product.modes.map(v => v.value);
  let quoteParamValidation = {
    selected_type: { validation: [], value: product.type },
    subtype: { validation: [], value: product.subtype },
    mode: {
      value: params.mode,
      validation: validateSelectList(params.mode, modeOptions),
    },
    state: {
      value: params.state,
      validation: validateState(pinia, params.state),
    },
  };

  const requiredItems = product.renderRules(params, {
    conversion: eApp.conversion,
  });
  const advancedItems = product.advancedRenderRules(params);

  if (requiredItems.ageValue) {
    const options = product
      .generateAgeValueOptions(insured.birthdate.model)
      .map(v => v.value);
    quoteParamValidation.age_value = {
      validation: validateSelectList(params.age_value, options),
      value: params.age_value,
    };
  }

  if (requiredItems.cashValue) {
    quoteParamValidation.cash_value = {
      validation: validateNumber(params.cash_value),
      value: params.cash_value,
    };
  }

  if (requiredItems.faceAmount) {
    quoteParamValidation.face_amount = {
      validation: validateNumber(params.face_amount),
      value: params.face_amount,
    };
  }

  if (requiredItems.incomeStartAge && requiredItems.incomeEndAge) {
    const ages = product.generateIncomeAges(insured.birthdate.model);
    quoteParamValidation.income_start_age = {
      validation: [...validateSelectList(params.income_start_age, ages)],
      value: params.income_start_age,
    };
    quoteParamValidation.income_end_age = {
      validation: [...validateSelectList(params.income_end_age, ages)],
      value: params.income_end_age,
    };

    if (params.income_start_age >= params.income_end_age) {
      quoteParamValidation.income_start_age.validation.push(
        "Income start age must be less than income end age"
      );
      quoteParamValidation.income_end_age.validation.push(
        "Income start age must be less than income end age"
      );
    }
  } else if (requiredItems.incomeStartAge) {
    const ages = product.generateIncomeAges(insured.birthdate.model);
    quoteParamValidation.income_start_age = {
      validation: [...validateSelectList(params.income_start_age, ages)],
      value: params.income_start_age,
    };
  } else if (requiredItems.incomeEndAge) {
    const ages = product.generateIncomeAges(insured.birthdate.model);
    quoteParamValidation.income_end_age = {
      validation: [...validateSelectList(params.income_end_age, ages)],
      value: params.income_end_age,
    };
  }

  if (requiredItems.incomeSolve) {
    let value = params.income_solve;
    if (!product.incomeSolves.includes(value) && !value) {
      value = product.defaults.income_solve;
    }
    quoteParamValidation.income_solve = {
      validation: validateSelectList(value, product.incomeSolves),
      value,
    };
  }

  if (requiredItems.lapseProtectionToAge) {
    const options = product
      .generateLapseProtectionToAgeOptions(insured.birthdate.model)
      .map(v => v.value);

    quoteParamValidation.lapse_protection_to_age = {
      validation: validateSelectList(params.lapse_protection_to_age, options),
      value: params.lapse_protection_to_age,
    };
  }

  if (requiredItems.ltcRider) {
    let value = params.filter_chronic_illness_and_ltc;
    if (!product.ltcRiderOptions.includes(value) && !value) {
      value = product.defaults.filter_chronic_illness_and_ltc;
    }

    quoteParamValidation.filter_chronic_illness_and_ltc = {
      validation: validateSelectList(value, product.ltcRiderOptions),
      value,
    };
  }

  if (requiredItems.ltcRiderPercentage) {
    quoteParamValidation.ltc_rider_percentage = {
      validation: validateSelectList(
        params.ltc_rider_percentage,
        product.ltcRiderPercentageOptions
      ),
      value: params.ltc_rider_percentage,
    };
  }

  if (requiredItems.payDuration) {
    const options = product
      .generatePayDurationOptions(insured.birthdate.model)
      .map(v => v.value);
    quoteParamValidation.pay_duration = {
      validation: validateSelectList(params.pay_duration, options),
      value: params.pay_duration,
    };
  }

  if (requiredItems.partnerDiscount) {
    const options = product.partnerDiscountOptions.map(v => v.value);
    let value = params.partner_discount;
    if (!options.includes(value) && !value) {
      value = product.defaults.partner_discount;
    }
    quoteParamValidation.partner_discount = {
      validation: validateSelectList(value, options),
      value,
    };
  }

  if (requiredItems.premium) {
    quoteParamValidation.premium = {
      validation: validateNumber(params.premium),
      value: params.premium,
    };
  }

  if (requiredItems.solve) {
    quoteParamValidation.solve = {
      validation: validateSelectList(params.solve, product.allSolves),
      value: params.solve,
    };
  }

  // is optional
  if (advancedItems.section1035ExchangeAmount) {
    quoteParamValidation.section_1035_exchange_amount = {
      validation: validateNumber(params.section_1035_exchange_amount, {
        isOptional: true,
      }),
      value:
        params.section_1035_exchange_amount ||
        product.defaults.section_1035_exchange_amount,
    };
  }

  if (advancedItems.loanType) {
    const loanTypeOptions = product.loanTypes.map(v => v.value);
    let value = params.loan_type;
    if (!loanTypeOptions.includes(value) && !value) {
      value = product.defaults.loan_type;
    }
    quoteParamValidation.loan_type = {
      validation: validateSelectList(value, loanTypeOptions),
      value,
    };
  }

  if (advancedItems.creditingRate) {
    const creditingRates = product.creditingRates.map(v => v.value);
    let value = params.crediting_rate;
    if (!creditingRates.includes(value) && !value) {
      value = product.defaults.crediting_rate;
    }
    quoteParamValidation.crediting_rate = {
      validation: validateSelectList(value, creditingRates),
      value,
    };
  }

  const validationItems = [
    "first_name",
    "last_name",
    "birthdate",
    "gender",
    "smoker",
    "smoking_statuses",
  ];

  if (requiredItems.insuredHealth) {
    validationItems.push("health");
  }

  return {
    ...quoteParamValidation,
    ...validatePartyData(pinia, INSURED_TYPES.INSURED, {
      validationItems,
      errorKey: "insured",
    }),
  };
};

export const ValidateLinkedBenefitQuoteParameters = (pinia, params) => {
  const product = LinkedBenefit();
  const eApp = useEappStore(pinia);
  const requiredItems = product.renderRules(params, {
    conversion: eApp.conversion,
  });
  const modeOptions = product.modes.map(v => v.value);
  let quoteParamValidation = {
    selected_type: { validation: [], value: product.type },
    state: {
      validation: validateState(pinia, params.state),
      value: params.state,
    },
    mode: {
      validation: validateSelectList(params.mode, modeOptions),
      value: params.mode,
    },
  };

  if (requiredItems.premium) {
    quoteParamValidation.premium = {
      validation: validateNumber(params.premium),
      value: params.premium,
    };
  }

  if (requiredItems.monthlyBenefit) {
    quoteParamValidation.monthly_benefit = {
      validation: validateNumber(params.monthly_benefit),
      value: params.monthly_benefit,
    };
  }

  if (requiredItems.inflationPercentage) {
    const inflationOptions = product.inflationOptions.map(v => v.value);
    let value = params.inflation_percentage;
    if (!inflationOptions.includes(value) && !value) {
      value = product.defaults.inflation_percentage;
    }
    quoteParamValidation.inflation_percentage = {
      validation: validateSelectList(value, inflationOptions),
      value,
    };
  }

  if (requiredItems.benefitPeriod) {
    const benefitPeriodOptions = product.benefitPeriodOptions.map(v => v.value);
    quoteParamValidation.benefit_period = {
      validation: validateSelectList(
        params.benefit_period,
        benefitPeriodOptions
      ),
      value: params.benefit_period,
    };
  }

  if (requiredItems.payDuration) {
    const payDurationOptions = product.payDurationOptions.map(v => v.value);
    quoteParamValidation.pay_duration = {
      validation: validateSelectList(params.pay_duration, payDurationOptions),
      value: params.pay_duration,
    };
  }

  if (requiredItems.solve) {
    const solveOptions = product.solves.map(v => v.value);
    quoteParamValidation.solve = {
      validation: validateSelectList(params.solve, solveOptions),
      value: params.solve,
    };
  }

  if (requiredItems.returnOfPremium) {
    const returnOfPremiumOptions = product.returnOfPremiumOptions.map(
      v => v.value
    );
    let value = params.return_of_premium_rider;
    if (!returnOfPremiumOptions.includes(value) && !value) {
      value = product.defaults.return_of_premium_rider;
    }
    quoteParamValidation.return_of_premium_rider = {
      validation: validateSelectList(value, returnOfPremiumOptions),
      value,
    };
  }

  const partnerDiscountOptions = product.partnerDiscountOptions.map(
    v => v.value
  );
  let partnerDiscountValue = params.partner_discount;
  if (
    !partnerDiscountOptions.includes(partnerDiscountValue) &&
    !partnerDiscountValue
  ) {
    partnerDiscountValue = product.defaults.partner_discount;
  }
  quoteParamValidation.partner_discount = {
    validation: validateSelectList(
      partnerDiscountValue,
      partnerDiscountOptions
    ),
    value: partnerDiscountValue,
  };

  const insuredValidationItems = [
    "first_name",
    "last_name",
    "birthdate",
    "gender",
    "smoker",
    "smoking_statuses",
  ];

  if (requiredItems.insuredHealth) {
    insuredValidationItems.push("health");
  }

  if (params.partner_discount) {
    insuredValidationItems.push("marital_status");

    const jointInsuredValidationItems = [
      "first_name",
      "last_name",
      "marital_status",
    ];

    if (params.partner_discount === JOINT_INSURED_PARTNER_DISCOUNT) {
      if (requiredItems.jointInsuredHealth) {
        jointInsuredValidationItems.push("health");
      }
      jointInsuredValidationItems.push(
        "birthdate",
        "gender",
        "smoker",
        "smoking_statuses"
      );
    }

    quoteParamValidation = {
      ...quoteParamValidation,
      ...validatePartyData(pinia, INSURED_TYPES.JOINT, {
        validationItems: jointInsuredValidationItems,
        errorKey: "joint_insured",
      }),
    };
  }

  return {
    ...quoteParamValidation,
    ...validatePartyData(pinia, INSURED_TYPES.INSURED, {
      validationItems: insuredValidationItems,
      errorKey: "insured",
    }),
  };
};

// need to add the math for monthly_benefit and pool of money
export const ValidateLtcQuoteParameters = (pinia, params) => {
  const product = LongTermCare();
  const eApp = useEappStore(pinia);

  const modeOptions = product.modes.map(v => v.value);
  let validation = {
    selected_type: { validation: [], value: product.type },
    mode: {
      validation: validateSelectList(params.mode, modeOptions),
      value: params.mode,
    },
    state: {
      validation: validateState(pinia, params.state),
      value: params.state,
    },
  };

  const requiredItems = product.renderRules(params, {
    convrsion: eApp.conversion,
  });
  if (requiredItems.monthlyBenefit) {
    validation.monthly_benefit = {
      validation: validateNumber(params.monthly_benefit),
      value: params.monthly_benefit,
    };
  }

  if (requiredItems.moneyPool) {
    validation.pool_of_money = {
      validation: validateNumber(params.pool_of_money),
      value: params.pool_of_money,
    };
  }

  if (requiredItems.eliminationPeriod) {
    validation.elimination_period = {
      validation: validateSelectList(
        params.elimination_period,
        product.eliminationPeriodItems
      ),
      value: params.elimination_period,
    };
  }

  if (requiredItems.homeHealthCareWaiver) {
    validation.home_health_care_waiver = {
      validation: validateBoolean(params.home_health_care_waiver),
      value: params.home_health_care_waiver,
    };
  }

  if (requiredItems.returnOfPremium) {
    const returnOfPremiumOptions = product.returnOfPremiumOptions.map(
      v => v.value
    );
    let value = params.return_of_premium_rider;
    if (!returnOfPremiumOptions.includes(value) && !value) {
      value = product.defaults.return_of_premium_rider;
    }
    validation.return_of_premium_rider = {
      validation: validateSelectList(value, returnOfPremiumOptions),
      value,
    };
  }

  if (requiredItems.payDuration) {
    const payDurationOptions = product.payDurationOptions.map(v => v.value);
    validation.pay_duration = {
      validation: validateSelectList(params.pay_duration, payDurationOptions),
      value: params.pay_duration,
    };
  }

  if (requiredItems.sharedCare) {
    validation.shared_care = {
      validation: validateBoolean(params.shared_care),
      value: params.shared_care,
    };
  }

  if (requiredItems.jointPremiumWaiver) {
    validation.joint_waiver_of_premium = {
      validation: validateBoolean(params.joint_waiver_of_premium),
      value: params.joint_waiver_of_premium,
    };
  }

  if (requiredItems.inflationPercentage) {
    const options = product.inflationOptions.map(v => v.value);
    let value = params.inflation_percentage;
    if (!options.includes(value) && !value) {
      value = product.defaults.inflation_percentage;
    }
    validation.inflation_percentage = {
      validation: validateSelectList(value, options),
      value,
    };
  }

  if (requiredItems.discount) {
    const options = product.discountOptions.map(v => v.value);
    let value = params.discount;
    if (!options.includes(value) && !value) value = product.defaults.discount;
    validation.discount = {
      validation: validateSelectList(value, options),
      value,
    };
  }

  const partnerDiscountOptions = product.partnerDiscountOptions.map(
    v => v.value
  );
  let partnerDiscountValue = params.partner_discount;
  if (
    !partnerDiscountOptions.includes(partnerDiscountValue) &&
    !partnerDiscountValue
  ) {
    partnerDiscountValue = product.defaults.partner_discount;
  }
  validation.partner_discount = {
    validation: validateSelectList(
      partnerDiscountValue,
      partnerDiscountOptions
    ),
    value: partnerDiscountValue,
  };

  const insuredValidationItems = [
    "first_name",
    "last_name",
    "birthdate",
    "gender",
    "smoker",
    "smoking_statuses",
  ];

  if (requiredItems.insuredHealth) {
    insuredValidationItems.push("health");
  }

  if (params.partner_discount) {
    insuredValidationItems.push("marital_status");

    const jointInsuredValidationItems = [
      "first_name",
      "last_name",
      "marital_status",
    ];

    if (params.partner_discount === JOINT_INSURED_PARTNER_DISCOUNT) {
      if (requiredItems.jointInsuredHealth) {
        jointInsuredValidationItems.push("health");
      }
      jointInsuredValidationItems.push(
        "birthdate",
        "gender",
        "smoker",
        "smoking_statuses"
      );
    }

    validation = {
      ...validation,
      ...validatePartyData(pinia, INSURED_TYPES.JOINT, {
        validationItems: jointInsuredValidationItems,
        errorKey: "joint_insured",
      }),
    };
  }

  return {
    ...validation,
    ...validatePartyData(pinia, INSURED_TYPES.INSURED, {
      validationItems: insuredValidationItems,
      errorKey: "insured",
    }),
  };
};

export function quoteSetDefaultsAndValidate(pinia, params) {
  const typeFn = {
    [PRODUCTS.FINAL_EXPENSE]: () =>
      ValidateFinalExpenseQuoteParameters(pinia, params),
    [PRODUCTS.ACCIDENTAL_DEATH]: () =>
      ValidateAccidentalDeathQuoteParameters(pinia, params),
    [PRODUCTS.ACCUMULATION_IUL]: () =>
      ValidateAccumulationIulQuoteParameters(pinia, params),
    [PRODUCTS.PROTECTION_VUL]: () =>
      ValidateProtectionVulQuoteParameters(pinia, params),
    [PRODUCTS.PROTECTION_IUL]: () =>
      ValidateProtectionIulQuoteParameters(pinia, params),
    [PRODUCTS.UNIVERSAL_LIFE]: () =>
      ValidateUniversalLifeQuoteParameters(pinia, params),
    [PRODUCTS.LTC]: () => ValidateLtcQuoteParameters(pinia, params),
    [PRODUCTS.ROP_TERM]: () => ValidateROPQuoteParameters(pinia, params),
    [PRODUCTS.TERM]: () => ValidateTermQuoteParameters(pinia, params),
    [PRODUCTS.NON_MED]: () => ValidateNonMedQuoteParameters(pinia, params),
    [PRODUCTS.WHOLE_LIFE]: () =>
      ValidateWholeLifeQuoteParameters(pinia, params),
    [PRODUCTS.LINKED_BENEFIT]: () =>
      ValidateLinkedBenefitQuoteParameters(pinia, params),
    [PRODUCTS.INCOME_RIDER]: () =>
      ValidateIncomeRiderQuoteParameters(pinia, params),
    [CONCEPTS.ALL_UL]: () => ValidateAllUlQuoteParameters(pinia, params),
  }[params.subtype || params.selected_type];

  if (!typeFn) throw { selected_type: ["Invalid product type"] };
  return typeFn();
}

// TODO Mappings need to come from the prefill handler?
export function enforceStrictParameterValidation(validation) {
  const invalid = {};
  for (const [key, value] of Object.entries(validation)) {
    if (value.validation.length > 0) {
      let userParam = key;
      if (key === "face_amount") userParam = "death_benefit";
      else if (key === "filter_chronic_illness_and_ltc")
        userParam = "chronic_illness_and_ltc";
      invalid[userParam] = value.validation;
    }
  }
  if (Object.values(invalid).length) throw invalid;
}
