import { defineStore } from "#src/stores/state-wrapper.js";
import { REQS } from "#src/enumerations/data-requirements.js";
import { steps, stepToRouteName } from "#src/enumerations/step-enumeration.js";

import { useSavableProperty } from "#src/composables/savable-property.composable.js";

import { useStepBeneficiaryPrimaryStore } from "#src/stores/step-beneficiary-primary.js";
import { useStepEmployerStore } from "#src/stores/step-employer.js";

export const useStepOwnerStore = (pinia, hot) =>
  defineStore("step-owner", {
    state: () => ({
      continuing_with_state: useSavableProperty({
        rules: {
          isValid: {
            v: () => {
              const store = useStepOwnerStore(pinia);
              return ["switch", "notify", "continue"].includes(
                store.continuing_with_state.model
              );
            },
            message: "Must be true or false",
          },
        },
      }),
      inactive: false,
      requiredFields: {
        revocable: false,
        taxType: false,
        countryOfCitizenship: false,
        birthLocation: false,
        usCitizen: false,
        formationDate: false,
      },
    }),
    getters: {
      step: () => steps.OWNER,
      routeName: s => stepToRouteName[s.step],
      previous: () => {
        const employer = useStepEmployerStore(pinia);
        if (employer.inactive) return employer.previous;
        return employer.step;
      },
      next: () => {
        const primary = useStepBeneficiaryPrimaryStore(pinia);
        if (primary.inactive) return primary.next;
        return primary.step;
      },
    },
    actions: {
      setStepRequirements(srq) {
        this.inactive = !srq[REQS.OWNER];
        this.requiredFields.revocable = srq[REQS.OWNER_REVOCABLE];
        this.requiredFields.taxType = srq[REQS.OWNER_TAX_TYPE];
        this.requiredFields.countryOfCitizenship =
          srq[REQS.OWNER_COUNTRY_OF_CITIZENSHIP];
        this.requiredFields.birthLocation = srq[REQS.OWNER_BIRTH_LOCATION];
        this.requiredFields.usCitizen = srq[REQS.OWNER_US_CITIZEN];
        this.requiredFields.formationDate = srq[REQS.OWNER_FORMATION_DATE];
      },
    },
  })(pinia, hot);
