export const REQS = {
  HEALTH: "health_step",
  SMOKER: "smoker_step",
  BUILD_STEP: "build_step",

  BUILD: "build",

  INSURED_NAME: "insured.name",
  INSURED_GENDER: "insured.gender",
  INSURED_BIRTHDATE: "insured.birthdate",
  INSURED_INCOME: "insured.income",
  INSURED_ASSETS: "insured.assets",
  INSURED_LIABILITIES: "insured.liabilities",
  INSURED_SSN: "insured.ssn",
  INSURED_MARITAL_STATUS: "insured.marital_status",
  INSURED_BIRTH_LOCATION: "insured.birth_location",
  INSURED_BANKRUPTCY: "insured.bankruptcy",
  INSURED_DRIVERS_LICENSE: "insured.drivers_license",
  INSURED_DRIVERS_LICENSE_EXPIRATION: "insured.drivers_license.expiration",
  INSURED_ADDRESS_START_DATE: "insured.address.start_date",
  INSURED_GUARDIAN_GENDER: "guardian.insured.gender",
  INSURED_GUARDIAN_BIRTHDATE: "guardian.insured.birthdate",
  INSURED_GUARDIAN_RELATIONSHIP: "guardian.insured.relationship",
  INSURED_ADDRESS: "insured.address",

  SEND_CLIENT_INTRO_MESSAGE: "send_client_intro_message",

  SECONDARY_ADDRESSEE: "secondary_addressee",
  SECONDARY_ADDRESSEE_ADDRESS: "secondary_addressee.address",
  SECONDARY_ADDRESSEE_PHONE: "secondary_addressee.phone",
  SECONDARY_ADDRESSEE_EMAIL: "secondary_addressee.email",
  SECONDARY_ADDRESSEE_RELATIONSHIP: "secondary_addressee.relationship",
  SECONDARY_ADDRESSEE_BIRTHDATE: "secondary_addressee.birthdate",
  EMPLOYER_NAME: "employer.name",
  EMPLOYER_ADDRESS: "employer.address",

  OCCUPATION_START_DATE: "occupation.start_date",
  OCCUPATION_NAME: "occupation.name",

  CONTINGENT_BENEFICIARY: "contingent_beneficiary",
  BENEFICIARY_ADDRESS: "beneficiary.address",
  BENEFICIARY_GENDER: "beneficiary.gender",
  BENEFICIARY_PHONE: "beneficiary.phone",
  BENEFICIARY_TIN: "beneficiary.tin",
  BENEFICIARY_FORMATION_DATE: "beneficiary.formation_date",
  BENEFICIARY_RELATIONSHIP: "beneficiary.relationship",
  BENEFICIARY_BIRTHDATE_OR_SSN: "beneficiary.dob_or_ssn",
  BENEFICIARY_BIRTHDATE: "beneficiary.birthdate",
  BENEFICIARY_SSN: "beneficiary.ssn",
  BENEFICIARY_SSN_OPTIONAL: "beneficiary.ssn.optional",
  BENEFICIARY_REVOCABLE: "beneficiary.revocable",
  BENEFICIARY_SIGNER_NAME: "beneficiary.signer.name",
  BENEFICIARY_SIGNER_EMAIL: "beneficiary.signer.email",

  OWNER: "owner",
  OWNER_REVOCABLE: "owner.revocable",
  OWNER_TAX_TYPE: "owner.tax_type",
  OWNER_BIRTH_LOCATION: "owner.birth_location",
  OWNER_COUNTRY_OF_CITIZENSHIP: "owner.country_of_citizenship",
  OWNER_US_CITIZEN: "owner.us_citizen",
  OWNER_FORMATION_DATE: "owner.formation_date",

  PAYMENT: "payment",

  PAYOR_NAME: "payor.name",
  PAYOR_ADDRESS: "payor.address",
  PAYOR_EMAIL: "payor.email",
  PAYOR_PHONE: "payor.phone",
  PAYOR_PARTY_ID: "payor.party_id",
  PAYOR_BIRTHDATE: "payor.birthdate",
  PAYOR_FORMATION_DATE: "payor.formation_date",
  PAYOR_RELATIONSHIP: "payor.relationship",
  PAYOR_DRAFT_DAY_OF_MONTH: "payor.draft_day_of_month",
  PAYOR_SIGNER_NAME: "payor.signer.name",
  PAYOR_SIGNER_EMAIL: "payor.signer.email",
  PAYOR_TAX_TYPE: "payor.tax_type",

  EXISTING_COVERAGE: "existing_coverage",
  EXISTING_COVERAGE_SOLD_BY_SAME_AGENT: "existing_coverage.sold_by_same_agent",

  HUMAN_API: "human_api",
  DOCTOR_VISITS: "doctor_visits",
};

/**
 * Convert an array of requirements into a known object of requirements.
 */
export function StepRequirements(model = []) {
  const r = {};
  model.forEach(sr => (r[sr] = true));

  const requirements = {};
  Object.values(REQS).forEach(v => (requirements[v] = Boolean(r[v])));

  return requirements;
}
