import { defineStore } from "#src/stores/state-wrapper.js";
import { steps, stepToRouteName } from "#src/enumerations/step-enumeration.js";
import { REQS } from "#src/enumerations/data-requirements.js";

import { useStepOwnerStore } from "#src/stores/step-owner.js";
import { useStepAddressStore } from "#src/stores/step-address.js";

export const useStepEmployerStore = (pinia, hot) =>
  defineStore("step-employer", {
    state: () => ({
      inactive: false,

      requiredFields: {
        occupationName: false,
        occupationStartDate: false,
        employerAddress: false,
        employerName: false,
      },
    }),
    getters: {
      step: () => steps.EMPLOYER,
      routeName: s => stepToRouteName[s.step],
      next() {
        const owner = useStepOwnerStore(pinia);
        if (owner.inactive) return owner.next;
        return owner.step;
      },
      previous: () => {
        const address = useStepAddressStore(pinia);
        if (address.inactive) return address.previous;
        return address.step;
      },
    },
    actions: {
      setStepRequirements(srq) {
        this.requiredFields.occupationName = srq[REQS.OCCUPATION_NAME];
        this.requiredFields.occupationStartDate =
          srq[REQS.OCCUPATION_START_DATE];
        this.requiredFields.employerName = srq[REQS.EMPLOYER_NAME];
        this.requiredFields.employerAddress = srq[REQS.EMPLOYER_ADDRESS];

        this.inactive = !Object.values(this.requiredFields).includes(true);
      },
    },
  })(pinia, hot);
