import { defineStore } from "#src/stores/state-wrapper.js";
import { steps, stepToRouteName } from "#src/enumerations/step-enumeration.js";

import { useStepExamSchedulingStore } from "#src/stores/step-exam-scheduling.js";
import { useStepSignatureStore } from "#src/stores/step-signature.js";
import { SubmissionsService } from "#src/services/submissions.service.mjs";
import { useDialogStore } from "#src/stores/dialog.js";

export const useStepSignatureWaitingStore = (pinia, hot) =>
  defineStore("step-signature-waiting", {
    state: () => ({
      signingByEmail: null,
      carrierEappUrl: null,
      submissionMethod: null,
    }),
    getters: {
      step: () => steps.SIGNATURE_WAITING,
      routeName: s => stepToRouteName[s.step],
      previous() {
        const examScheduling = useStepExamSchedulingStore(pinia);
        return examScheduling.step;
      },
      next() {
        const signature = useStepSignatureStore(pinia);
        return signature.step;
      },
      hidesFooter: s =>
        s.signingByEmail !== null ||
        (s.carrierEappUrl &&
          s.submissionMethod !== SubmissionsService.METHOD.AGENT_ONLY),
      nextText: s =>
        s.submissionMethod === SubmissionsService.METHOD.AGENT_ONLY
          ? "Continue as Advisor"
          : "",
      nextAction: s => {
        if (s.submissionMethod === SubmissionsService.METHOD.AGENT_ONLY) {
          const dialog = useDialogStore(pinia);
          return {
            onClick: async () =>
              dialog.showMessageDialog({
                imageProps: {
                  src: new URL("#src/assets/firelight.svg", import.meta.url),
                  height: "60px",
                },
                title: "Continue to Hexure eApplication",
                message:
                  'By pressing "Continue" you will be redirected to Hexure\'s Firelight.',
                confirmHref: s.carrierEappUrl,
                confirmText: "Continue",
                cancelText: "Cancel",
              }),
          };
        }
        return null;
      },
      controlsNext: s =>
        s.submissionMethod !== SubmissionsService.METHOD.AGENT_ONLY,
    },
  })(pinia, hot);
