import { useInstanceSettingsStore } from "#src/stores/instance-settings.js";

let hasLoaded;
export function attachAnalytics(pinia) {
  const instance = useInstanceSettingsStore(pinia);
  hasLoaded = true;
  window.addEventListener("click", trackUserHasInteracted, { once: true });

  const tags = [import.meta.env.VITE_ANALYTICS_ID];
  if (instance.google_analytics_id) {
    tags.push(instance.google_analytics_id);
  }

  initializeDataLayer(tags);
}

function gtag() {
  try {
    window.dataLayer.push(arguments);
  } catch (e) {
    // do nothing
  }
}

export function initializeDataLayer(tags) {
  if (!hasLoaded) return;

  window.dataLayer = window.dataLayer || [];

  gtag("js", new Date());

  // Send page view must be false due to this being an SPA
  tags.forEach(tag => {
    gtag("config", tag, {
      send_page_view: false,
      cookie_flags: "samesite=none;secure",
      groups: "analytics",
    });
  });
}

export function trackPageChange({ page_title, page_location }) {
  if (!hasLoaded) return;
  gtag("event", "page_view", {
    page_title,
    page_location,
    send_to: "analytics",
  });
}

export function trackNewApp() {
  if (!hasLoaded) return;
  gtag("event", "new_app", { send_to: "analytics" });
}

export function trackExistingApp() {
  if (!hasLoaded) return;
  gtag("event", "existing_app", { send_to: "analytics" });
}

export function trackAppCompleted() {
  if (!hasLoaded) return;
  gtag("event", "completed_app", { send_to: "analytics" });
}

export function trackUserHasInteracted() {
  if (!hasLoaded) return;
  gtag("event", "user_has_interacted", { send_to: "analytics" });
}

export function trackUserIsSharing() {
  if (!hasLoaded) return;
  gtag("event", "user_is_sharing", { send_to: "analytics" });
}

export function trackRepeatCustomer() {
  if (!hasLoaded) return;
  gtag("event", "user_is_repeat_customer", { send_to: "analytics" });
}

export function trackUserCheckingProgress() {
  if (!hasLoaded) return;
  gtag("event", "user_checking_application_progress", { send_to: "analytics" });
}
