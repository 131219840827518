import { useSavableProperty } from "#src/composables/savable-property.composable.js";

import { validateIsBeforeNow } from "#src/composables/savable-property-validators.mjs";
import {
  SMOKING_FREQUENCIES,
  SMOKING_TYPES,
} from "#src/structures/SmokingUsage.js";
import { generateUuid } from "#src/util/helpers.js";
import { defineStore } from "#src/stores/state-wrapper.js";

export const smokingUsageNameGenerator = () =>
  `smoking-usage-${generateUuid()}`;
export const useSmokingUsage = (id, pinia, hot) =>
  defineStore(id, {
    state: () => ({
      id: null,
      last_use_date: useSavableProperty({
        requestMap: "last_use_date",
        group: "smoker",
        rules: {
          dateIsBeforeNow: validateIsBeforeNow(
            () => useSmokingUsage(id, pinia).last_use_date.model
          ),
        },
      }),
      category: useSavableProperty({
        requestMap: "category",
        group: "smoker",
        rules: {
          inList: {
            v: () => {
              const smokingUsage = useSmokingUsage(id, pinia);
              return SMOKING_TYPES.includes(smokingUsage.category.model);
            },
            message: () => {
              return `Must be in list ${SMOKING_TYPES.join(", ")}`;
            },
          },
        },
      }),
      frequency: useSavableProperty({
        requestMap: "frequency",
        group: "smoker",
        rules: {
          inList: {
            v: () => {
              const smokingUsage = useSmokingUsage(id, pinia);
              return SMOKING_FREQUENCIES.some(
                ({ value }) => value === smokingUsage.frequency.model
              );
            },
            message: () => {
              return `Must be in list ${SMOKING_FREQUENCIES.map(v => v.value).join(", ")}`;
            },
          },
        },
      }),
    }),
  })(pinia, hot);
