import { isBoolean } from "#src/util/helpers.js";

export function ExistingCoverageMapper(model = {}) {
  const face_amount =
    model?.face_amount || model.face_amount === 0 ? model?.face_amount : null;

  const monthly_benefit =
    model?.monthly_benefit || model.monthly_benefit === 0
      ? model?.monthly_benefit
      : null;

  const premium = model?.premium || model.premium === 0 ? model?.premium : null;
  let business_insurance = null;
  if (isBoolean(model?.business_insurance))
    business_insurance = model?.business_insurance;
  return {
    id: model?.id || null,
    carrier_id: model?.carrier?.id || null,
    policy_number: model?.policy_number || null,
    face_amount,
    status: model?.status || null,
    policy_date: model?.policy_date || null,
    business_insurance,
    replacement_reason: model?.replacement_reason || null,
    insured_id: model?.insured_id,
    monthly_benefit,
    premium,
  };
}

export const BUSINESS_OPTIONS = [
  { value: false, text: "No" },
  { value: true, text: "Yes" },
];

export const STATUS_OPTIONS = [
  "Active",
  "Active - Being Replaced",
  "Active - Being Replaced with 1035",
  "Active - Altering Premium",
  "Pending",
  "Sold",
];

export const LTC_STATUS_OPTIONS = [...STATUS_OPTIONS, "Lapsed"];
