<template>
  <step-layout show-logo>
    <template #title> {{ title }} </template>
    <template #body>
      <v-col cols="12">
        <loader v-if="loading" :message="loadingMessage" center />
        <template v-else-if="!showNpn && loadedAgents">
          <v-autocomplete
            v-model="agent"
            auto-select-first
            name="agent"
            :items="selectableAgents"
            return-object
            item-title="name"
            item-key="id"
            variant="outlined"
            density="compact"
            label="Select Agent"
            :prepend-inner-icon="mdiAccount"
            hint=" "
            persistent-hint
            @focus="handleFocus"
          >
            <template #message>
              <v-row class="ma-0">
                <v-spacer />
                <a class="text-primary clickable" @click="showNpn = !showNpn">
                  Name not in List?
                </a>
              </v-row>
            </template>
          </v-autocomplete>
          <v-col class="pa-0" align="center">
            <div class="app-item-max-width">
              <v-btn
                v-if="agent"
                class="text-none"
                color="primary"
                block
                :loading="fetchingUrl"
                @click="onConfirm"
              >
                Continue
              </v-btn>
            </div>
          </v-col>
        </template>
        <template v-else>
          <masked-input
            v-model="npn"
            mask="#########"
            variant="outlined"
            density="compact"
            persistent-hint
            hint=" "
            label="National Producer Number"
            placeholder="National Producer Number"
            :prepend-inner-icon="mdiPound"
            :autofocus="shouldAutofocus"
            :class="{
              'success-field': npnValidation.success,
              'error-field': npnValidation.errorMessages.length,
            }"
            :base-color="npnValidation.success ? 'success' : 'basic'"
            :color="npnValidation.success ? 'success' : 'primary'"
            :error-messages="npnValidation.errorMessages"
            :disabled="loadingNPN || registeringAgent"
            :loading="loadingNPN"
            @update:model-value="npnInput"
          >
            <template #message="{ message }">
              <v-row class="py-3">
                {{ message }}
                <v-spacer />
                <template v-if="startPage === 'select_agent'">
                  <a class="text-primary clickable" @click="showNpn = !showNpn">
                    Already Signed Up?
                  </a>
                  <span class="px-3"> · </span>
                </template>
                <a
                  class="text-center text-primary clickable text-decoration-none"
                  href="https://www.nipr.com/PacNpnSearch.htm"
                  target="_blank"
                >
                  Forgot National Producer Number?
                </a>
              </v-row>
            </template>
          </masked-input>
          <agent-signup-form
            v-if="validNpn"
            :npn="npn"
            @loading="value => (registeringAgent = value)"
          />
          <v-col v-else class="pa-0" align="center">
            <div class="app-item-max-width">
              <v-btn
                class="text-none w-100"
                color="primary"
                :loading="loadingNPN"
                block
                @click="validateNpn"
              >
                Continue
              </v-btn>
            </div>
          </v-col>
        </template>
      </v-col>
    </template>
  </step-layout>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useSnackbarStore } from "#src/stores/snackbar";
import { useInstanceSettingsStore } from "#src/stores/instance-settings";

import { NiprService } from "#src/services/nipr.service";
import { AgentsService } from "#src/services/agents.service";

import Loader from "#src/components/Loader.vue";
import AgentSignupForm from "#src/components/AgentSignupForm.vue";
import StepLayout from "#src/components/shared/StepLayout.vue";
import { parseErrorMessage } from "#src/util/helpers";
import MaskedInput from "#src/components/inputs/MaskedInput.vue";
import { mdiAccount, mdiPound } from "@mdi/js";
import { inject } from "vue";
export default {
  components: {
    AgentSignupForm,
    Loader,
    StepLayout,
    MaskedInput,
  },
  setup() {
    const pinia = inject("pinia");
    const agentsService = new AgentsService(pinia);
    const niprService = new NiprService(pinia);
    return { agentsService, niprService };
  },
  data() {
    return {
      validNpn: false,
      loadingNPN: false,
      loading: false,
      registeringAgent: false,
      loadedAgents: false,
      loadingMessage: "",
      error: "",
      selectableAgents: [],
      agent: null,
      npn: "",
      showNpn: false,
      fetchingUrl: false,
      dirty: {
        npn: false,
      },
      mdiAccount,
      mdiPound,
    };
  },
  computed: {
    ...mapState(useInstanceSettingsStore, {
      shouldAutofocus: "activeFirstInput",
      startPage: "start_page",
      greeting: "greeting",
      noNpnGreeting: "new_npn_greeting",
      domainKey: "domain_key",
      prefilledNpn: "prefilledNpn",
    }),
    title() {
      if (this.greeting) return this.greeting;
      if (!this.domainKey && this.noNpnGreeting) return this.noNpnGreeting;
      return "Welcome to Quote & Apply. Enter your National Producer Number to Sign Up.";
    },
    showRegistration() {
      return this.showNpn && this.validNpn;
    },
    npnValidation() {
      const errorMessages = [];
      if (this.dirty.npn) {
        if (!this.npn) errorMessages.push("Required");
        if (this.error)
          errorMessages.push(this.error || "Error validating NPN");
      }
      const success = !errorMessages.length && Boolean(this.npn);
      return { success, errorMessages };
    },
  },
  created() {
    this.npn = this.prefilledNpn;
    this.showNpn = this.startPage !== "select_agent";
    if (!this.showNpn) this.getAgents();
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showErrorSnackbar"]),
    npnInput() {
      this.validNpn = false;
      this.dirty.npn = true;
    },
    async onConfirm() {
      if (!this.agent?.url) return;
      this.fetchingUrl = true;
      try {
        const url = this.agent.url;
        let finishedUrl = `https://${url}`;
        if (url.indexOf("://") > -1) finishedUrl = url;

        window.open(finishedUrl, "_top");
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.fetchingUrl = false;
      }
    },
    async getAgents() {
      this.loading = true;
      this.loadingMessage = "Loading Agents...";
      try {
        const npn = this.prefilledNpn || undefined;
        const agents = await this.agentsService.getAllAgents(npn);
        this.selectableAgents = agents;
        this.loadedAgents = true;
      } catch (e) {
        this.showErrorSnackbar({ message: parseErrorMessage(e) });
      } finally {
        this.loading = false;
      }
    },
    async validateNpn() {
      this.dirty.npn = true;
      if (!this.npn) return;
      this.loadingNPN = true;
      try {
        const { url, validNpn, error } = await this.niprService.validateNpn(
          this.npn
        );
        if (url) {
          window.open(url, "_top");
        } else {
          this.validNpn = validNpn;
          this.error = error;
        }
      } catch (e) {
        this.error = e.response.data;
        this.validNpn = false;
      } finally {
        this.loadingNPN = false;
      }
    },
    handleFocus(e) {
      this.$nextTick(() => {
        e.target.select();
      });
    },
  },
};
</script>
