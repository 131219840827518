import { httpClient } from "#src/HttpClient.js";
import { useEappStore } from "#src/stores/electronic-application.js";
import { useInstanceSettingsStore } from "#src/stores/instance-settings.js";

export class QuotesService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/quotes`;
    this.#httpClient = httpClient(pinia);
  }

  #attachEappData(params) {
    const { id, uuid } = useEappStore(this.#pinia);
    return {
      electronic_application_id: id,
      uuid: uuid,
      ...params,
    };
  }

  async getQuotes({ pdf = false, body = {} }) {
    let url = this.#baseUrl;
    if (pdf) url += ".pdf";

    try {
      const res = await this.#httpClient.post(url, this.#attachEappData(body));
      return res;
    } catch (e) {
      throw e.response?.data?.details || e;
    }
  }

  async setActiveQuote(quoteId, insured_id) {
    const body = this.#attachEappData({ insured_id });
    const { data } = await this.#httpClient.put(
      `${this.#baseUrl}/${quoteId}`,
      body
    );
    return data.id;
  }
}
