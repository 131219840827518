import {
  ProductType,
  categoryToConstantProduct,
} from "#src/structures/ProductType.js";
import { currencyFormatter } from "#src/util/helpers.js";
import { SocketService } from "#src/services/socket.service.js";
import { useQuotingStore } from "#src/stores/quoting.js";
import { computed, ref } from "vue";

export function useQuoteFetch({
  pinia,
  productTypes = [],
  defaultParams = {},
  onProcessAdd = () => {},
  onProcessesAdded = () => {},
  onQuoteAdd = () => {},
  onProcessRemove = () => {},
  onEnd = () => {},
  onHint = () => {},
}) {
  const socketService = new SocketService(pinia);
  const pusher = ref({ disconnect: () => {} });

  /** public */
  function disconnect() {
    if (typeof pusher.value.disconnect === "function")
      pusher.value.disconnect();
  }

  /** public */
  function fetch(reqParams = {}) {
    // TODO: FIX THIS
    //eslint-disable-next-line
    return new Promise(async (res, rej) => {
      disconnect();
      const params = {
        ...reqParams,
        ...defaultParams,
      };

      if (productTypes.length) {
        params.categories = [];
        params.categories.push(
          ...productTypes.map(v => new ProductType(v).requestCategory)
        );
      }

      const initFunc = uuid => fireQuoteReq(uuid, params, res, rej);
      try {
        pusher.value = await socketService.initSocketForQuotes(initFunc, {
          startHandler,
          finishHandler,
          responseHandler,
          hintHandler,
          endHandler,
        });
      } catch (e) {
        rej(e);
      }
    });
  }

  /** private */
  async function fireQuoteReq(websocket_uuid, params, resolve, reject) {
    try {
      const quoting = useQuotingStore(pinia);
      const { status } = await quoting.getQuotes({
        params,
        websocket_uuid,
      });

      if (status !== 202) throw Error("Unused Pusher" + JSON.stringify(params));
      resolve();
    } catch (e) {
      disconnect();
      reject(e);
    }
  }

  /** private */
  function startHandler(processes) {
    processes.forEach(process => {
      if (productTypes.length) {
        process.categories.forEach(rawCategory => {
          categoryToConstantProduct(rawCategory).forEach(c => {
            if (!productTypes.includes(c)) return;
            onProcessAdd({ type: c, process: process.id });
          });
        });
      } else {
        onProcessAdd({ process: process.id });
      }
    });
    onProcessesAdded();
  }

  /** private */
  function finishHandler(process) {
    if (productTypes.length) {
      process.categories.forEach(rawCategory => {
        categoryToConstantProduct(rawCategory).forEach(c => {
          if (!productTypes.includes(c)) return;
          onProcessRemove({ type: c, process: process.id });
        });
      });
    } else {
      onProcessRemove({ process: process.id });
    }
  }

  /** private */
  function responseHandler(quote) {
    if (productTypes.length) {
      categoryToConstantProduct(quote.product.category).forEach(c => {
        if (!productTypes.includes(c)) return;
        onQuoteAdd({ type: c, quote });
      });
    } else {
      onQuoteAdd({ quote });
    }
  }

  /** private */
  function hintHandler(h) {
    onHint(h);
  }

  /** private */
  function endHandler() {
    onEnd();
    disconnect();
  }

  return {
    fetch,
    disconnect,
  };
}

function useGenericCompareCard({ initialOrder }) {
  const state = ref({
    order: initialOrder,
    ended: false,
    ending: false,
    processIds: [],
    quotes: [],
  });

  function reset() {
    state.value.order = initialOrder;
    state.value.ended = false;
    state.value.ending = false;
    state.value.processIds.splice(0, state.value.processIds.length);
    state.value.quotes.splice(0, state.value.quotes.length);
  }
  function removeAllProcessIds() {
    state.value.processIds.splice(0, state.value.processIds.length);
  }

  function removeProcessId(processId) {
    const index = state.value.processIds.indexOf(processId);
    if (index === -1) return;
    state.value.processIds.splice(index, 1);
  }

  function removeIfEmpty() {
    state.value.ended = !state.value.processIds.length;
  }

  function disappear() {
    state.value.ending = true;
    setTimeout(() => {
      state.value.ended = true;
      state.value.ending = false;
    }, 300);
  }

  function addQuote(quote) {
    state.value.quotes.push(quote);
  }

  function addProcess(pid) {
    state.value.processIds.push(pid);
  }

  const backgroundLoading = computed(() => {
    return Boolean(state.value.processIds.length);
  });

  function getQuotes() {
    return state.value.quotes;
  }

  function setOrder(order) {
    state.value.order = order;
  }

  return {
    state,
    backgroundLoading,
    setOrder,
    removeProcessId,
    removeAllProcessIds,
    disappear,
    reset,
    addQuote,
    addProcess,
    removeIfEmpty,
    getQuotes,
  };
}

export function useCompareCard({ type, title, initialOrder = 0 }) {
  const card = useGenericCompareCard({ initialOrder });

  const primaryQuote = computed(() => {
    if (!card.state.value.quotes.length) return null;
    let primaryQuote = card.state.value.quotes[0];
    card.state.value.quotes.forEach(quote => {
      if (quote.premium > primaryQuote.premium) return;
      primaryQuote = quote;
    });
    return primaryQuote;
  });

  return {
    type,
    title,
    primaryQuote,
    setOrder: card.setOrder,
    state: card.state,
    backgroundLoading: card.backgroundLoading,
    removeProcessId: card.removeProcessId,
    removeAllProcessIds: card.removeAllProcessIds,
    disappear: card.disappear,
    reset: card.reset,
    addQuote: card.addQuote,
    addProcess: card.addProcess,
    removeIfEmpty: card.removeIfEmpty,
  };
}

export function useLtcCard({
  title,
  type,
  explanationType,
  initialOrder = 0,
  age,
}) {
  const card = useGenericCompareCard({ initialOrder });

  const primaryQuote = computed(() => {
    if (!card.state.value.quotes.length) return;
    let quote;
    card.state.value.quotes.forEach(currQuote => {
      if (quote?.premium && currQuote.premium > quote?.premium) return;
      if (!currQuote.ltc_pool_of_money_values?.length) return;
      if (currQuote.ltc_pool_of_money_values.length < 85 - age) return;
      quote = currQuote;
    });

    return quote;
  });

  const ltcBenefit = computed(() => {
    if (!primaryQuote.value?.ltc_pool_of_money_values) return 0;
    return primaryQuote.value.ltc_pool_of_money_values[85 - age] || 0;
  });

  const deathBenefit = computed(() => {
    if (!primaryQuote.value) return;
    return primaryQuote.value.initial_death_benefit || 0;
  });

  const chart = computed(() => {
    if (!primaryQuote.value) return;
    let v;
    if (ltcBenefit.value === -1) v = 1;
    else v = Math.trunc(ltcBenefit.value);
    const data = [v, Math.trunc(deathBenefit.value)];
    return {
      labels: ["Age 85 Care Coverage", "Death Benefit"],
      datasets: [
        {
          datalabels: {
            anchor: "end",
            align: "end",
            clamp: false,
            formatter: v => {
              if (v === 1) return "Unlimited";
              return currencyFormatter(v);
            },
          },
          data,
          fill: true,
          showLine: false,
          borderColor: ["rgb(25, 118, 210)", "rgb(40,167,70)"],
          backgroundColor: ["rgba(25, 118, 210, 0.8)", "rgba(40,167,70,.8)"],
          borderRadius: 4,
        },
      ],
    };
  });

  return {
    title,
    type,
    chart,
    primaryQuote,
    ltcBenefit,
    deathBenefit,
    explanationType,
    getQuotes: card.getQuotes,
    state: card.state,
    backgroundLoading: card.backgroundLoading,
    addQuote: card.addQuote,
    addProcess: card.addProcess,
    removeProcessId: card.removeProcessId,
    removeAllProcessIds: card.removeAllProcessIds,
    removeIfEmpty: card.removeIfEmpty,
    disappear: card.disappear,
    reset: card.reset,
  };
}
