import { defineStore } from "#src/stores/state-wrapper.js";
import { scrollToTop } from "#src/composables/scroll-to.composable.js";
export const useDialogStore = (pinia, hot) =>
  defineStore("dialog", {
    state: () => {
      return {
        show: false,
        dialogProps: {},
        resolve: null,
      };
    },
    actions: {
      showDialog(payload) {
        if (!payload) return;
        this.dialogProps = payload || {};
        this.show = true;

        scrollToTop(pinia, "smooth");
        return new Promise(resolve => {
          this.resolve = resolve;
        });
      },
      closeDialog(payload) {
        this.show = false;
        this.dialogProps = {};
        if (this.resolve) this.resolve(payload || {});
      },
      showPrintSummaryDialog(initialType = null) {
        return this.showDialog({
          component: "PrintSummaryDialog",
          scrollable: true,
          initialType,
        });
      },
      showMessageDialog({
        title,
        message,
        confirmText,
        cancelText,
        cancelHref,
        confirmHref,
        persistent,
        textAction,
        imageProps = null,
        spacer = true,
      }) {
        const body = {};
        if (title) body.title = title;
        if (message) body.message = message;
        if (confirmText) body.confirmText = confirmText;
        if (cancelText) body.cancelText = cancelText;
        if (cancelHref) body.cancelHref = cancelHref;
        if (confirmHref) body.confirmHref = confirmHref;
        if (persistent) body.persistent = persistent;
        if (textAction) body.textAction = textAction;
        if (imageProps) body.imageProps = imageProps;
        if (spacer) body.spacer = spacer;
        return this.showDialog({
          component: "MessageDialog",
          ...body,
        });
      },
      showMaritalStatusDialog() {
        return this.showDialog({
          component: "MaritalStatusDialog",
          scrollable: true,
        });
      },
      showEnvelopePreviewDialog(pages) {
        return this.showDialog({
          component: "EnvelopePreviewDialog",
          scrollable: true,
          pages,
          width: "40rem",
        });
      },
      showHelpDialog() {
        return this.showDialog({ component: "HelpDialog", scrollable: true });
      },
      showEmailFormDialog() {
        return this.showDialog({ component: "EmailFormDialog" });
      },
      showShareApplicationDialog() {
        return this.showDialog({ component: "ShareApplicationDialog" });
      },
      showComplianceDialog() {
        return this.showDialog({ component: "ComplianceDialog" });
      },
      showEftDialog({ module }) {
        return this.showDialog({
          component: "EftDialog",
          module,
          persistent: true,
          width: "40rem",
        });
      },
      showSessionTimedOutDialog() {
        return this.showDialog({
          component: "SessionTimedOutDialog",
          persistent: true,
        });
      },
      showJointInsuredInformationDialog({ collect, isJointInsured }) {
        return this.showDialog({
          component: "JointInsuredInformationDialog",
          scrollable: true,
          isJointInsured,
          collect,
        });
      },
      showPolicyTypeExplanationDialog({
        policyType,
        partnerDiscount,
        termLength,
      }) {
        return this.showDialog({
          component: "PolicyTypeExplanation",
          scrollable: true,
          width: "40rem",
          policyType,
          partnerDiscount,
          termLength,
        });
      },
      showInformationCaptureDialog() {
        return this.showDialog({
          component: "InformationCaptureDialog",
          persistent: true,
        });
      },
      showConfirmUserIsAgentDialog({
        title = null,
        subtitle = null,
        softConsent = false,
      } = {}) {
        const props = {
          component: "ConfirmUserIsAgentDialog",
          persistent: true,
          width: "40rem",
          softConsent,
        };

        if (title) props.title = title;
        if (subtitle) props.subtitle = subtitle;

        return this.showDialog(props);
      },
      showQuoteHintHelperDialog(hints) {
        return this.showDialog({
          component: "QuoteHintHelper",
          isDialog: true,
          hints,
          width: "40rem",
        });
      },
      showConfirmationDialog({
        title = "",
        subtitle = "",
        confirmText = "Confirm",
        cancelText = "Cancel",
        persistent = false,
        titleIcon = null,
        confirmButtonProps = {},
        cancelButtonProps = {},
      }) {
        return this.showDialog({
          component: "ConfirmationDialog",
          title,
          subtitle,
          confirmText,
          confirmButtonProps,
          cancelButtonProps,
          cancelText,
          persistent,
          titleIcon,
          width: "40rem",
        });
      },
      showCoverageCalculatorDialog() {
        return this.showDialog({
          component: "CoverageCalculatorDialog",
          width: "40rem",
          scrollable: true,
        });
      },
      showShareQuoteAndApplyDialog({ url }) {
        return this.showDialog({
          component: "ShareQuoteAndApplyDialog",
          scrollable: true,
          url,
        });
      },
    },
  })(pinia, hot);
