<template>
  <component
    :is="components[props.field.is] || props.field.is"
    v-bind="props.field.componentProps"
  >
    <template
      v-for="slot in props.field?.slots"
      #[`${slot.name}`]
      :key="slot.name"
    >
      <form-builder-inner-component
        v-for="f in slot.fields"
        :key="f.key"
        :field="f"
      />
    </template>
  </component>
</template>

<script setup>
import FormBuilderAddressAutofillInput from "#src/components/form-builder/FormBuilderAddressAutofillInput.vue";
import FormBuilderAdvancedHealthInput from "#src/components/form-builder/FormBuilderAdvancedHealthInput.vue";
import FormBuilderAutocompleteInput from "#src/components/form-builder/FormBuilderAutocompleteInput.vue";
import FormBuilderButtonsInput from "#src/components/form-builder/FormBuilderButtonsInput.vue";
import FormBuilderCheckAlertInput from "#src/components/form-builder/FormBuilderCheckAlertInput.vue";
import FormBuilderCurrencyInput from "#src/components/form-builder/FormBuilderCurrencyInput.vue";
import FormBuilderDateInput from "#src/components/form-builder/FormBuilderDateInput.vue";
import FormBuilderEmailInput from "#src/components/form-builder/FormBuilderEmailInput.vue";
import FormBuilderSubheader from "#src/components/form-builder/FormBuilderSubheader.vue";
import FormBuilderBasicSubheader from "#src/components/form-builder/FormBuilderBasicSubheader.vue";
import FormBuilderIntegerInput from "#src/components/form-builder/FormBuilderIntegerInput.vue";
import FormBuilderMaskedInput from "#src/components/form-builder/FormBuilderMaskedInput.vue";
import FormBuilderSelectInput from "#src/components/form-builder/FormBuilderSelectInput.vue";
import FormBuilderRatingInput from "#src/components/form-builder/FormBuilderRatingInput.vue";
import FormBuilderSsnInput from "#src/components/form-builder/FormBuilderSsnInput.vue";
import FormBuilderTextInput from "#src/components/form-builder/FormBuilderTextInput.vue";
import FormBuilderTextareaInput from "#src/components/form-builder/FormBuilderTextareaInput.vue";
import FormBuilderBirthdateInput from "#src/components/form-builder/FormBuilderBirthdateInput.vue";
import FormBuilderRadioInput from "#src/components/form-builder/FormBuilderRadioInput.vue";
import FormBuilder from "#src/components/form-builder/FormBuilder.vue";
import FormBuilderCheckboxGroup from "#src/components/form-builder/FormBuilderCheckboxGroup.vue";
import Loader from "#src/components/Loader.vue";
import FormBuilderTextLink from "#src/components/form-builder/FormBuilderTextLink.vue";
import {
  VAlert,
  VBtn,
  VExpansionPanel,
  VExpansionPanelText,
  VExpansionPanelTitle,
  VExpansionPanels,
  VIcon,
  VSpacer,
} from "vuetify/lib/components/index.mjs";
import { markRaw } from "vue";

const components = markRaw({
  FormBuilderAddressAutofillInput,
  FormBuilderAdvancedHealthInput,
  FormBuilderAutocompleteInput,
  FormBuilderButtonsInput,
  FormBuilderCheckAlertInput,
  FormBuilderCurrencyInput,
  FormBuilderDateInput,
  FormBuilderEmailInput,
  FormBuilderSubheader,
  FormBuilderBasicSubheader,
  FormBuilderIntegerInput,
  FormBuilderMaskedInput,
  FormBuilderSelectInput,
  FormBuilderRatingInput,
  FormBuilderSsnInput,
  FormBuilderTextInput,
  FormBuilderTextareaInput,
  FormBuilderBirthdateInput,
  FormBuilderRadioInput,
  FormBuilder,
  FormBuilderCheckboxGroup,
  Loader,
  FormBuilderTextLink,
  VAlert,
  VBtn,
  VExpansionPanel,
  VExpansionPanelText,
  VExpansionPanelTitle,
  VExpansionPanels,
  VIcon,
  VSpacer,
});

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
});
</script>
