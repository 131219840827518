import { defineStore } from "#src/stores/state-wrapper.js";
import {
  ProductType,
  PRODUCTS,
  CONCEPTS,
} from "#src/structures/ProductType.js";
import { THEME_TYPES, ApprovedDomain } from "#src/structures/ApprovedDomain.js";
import { TermConceptParams } from "#src/structures/quote-params/Term.js";
import { AllUniversalLife } from "#src/structures/quote-params/UniversalLife.js";
import {
  FINAL_EXPENSE_MAX_AGE,
  FINAL_EXPENSE_MIN_AGE,
} from "#src/structures/quote-params/FinalExpense.js";

import { usePrimaryInsuredStore } from "#src/stores/insured.js";
import { useFlowStore } from "#src/stores/flow.js";
import {
  booleanPrefillValue,
  generateUuid,
  getEnv,
  numberPrefillValue,
  objectPrefillValue,
  stringPrefillValue,
} from "#src/util/helpers.js";
import { useEappStore } from "#src/stores/electronic-application.js";
import { useQuotingStore } from "#src/stores/quoting.js";
import {
  MONTHLY_BENEFIT_MOST_COMMON,
  WITHOUT_CHRONIC_LTC,
} from "#src/data/quoteParams.js";
import { OverridePrefillBehavior } from "#src/structures/OverridePrefillBehavior.js";

export const START_PAGES = {
  CALCULATOR: "life_insurance_calculator",
  DEMO: "track_demo",
  REFERRAL: "refer",
  AGENT_SELECT: "select_agent",
  AGENT_SIGN_UP: "agent_sign_up",
  APPLY: "apply",
};

const UTM_METADATA_TAGS = new Map();
UTM_METADATA_TAGS.set("utm_source", "utm_source");
UTM_METADATA_TAGS.set("utm_medium", "utm_medium");
UTM_METADATA_TAGS.set("utm_campaign", "utm_campaign");
UTM_METADATA_TAGS.set("utm_content", "utm_content");
UTM_METADATA_TAGS.set("utm_term", "utm_term");
UTM_METADATA_TAGS.set("utm_id", "utm_id");
UTM_METADATA_TAGS.set("utm_source_platform", "utm_source_platform");

export const PRIORITIES = {
  TERM: "term",
  ROP_TERM: "rop_term",
  PERMANENT: "permanent",
  SUPPLEMENT_RETIREMENT: "supplement_retirement",
  GROWTH: "growth",
  INCOME_RIDER: "income_rider",
  BURIAL_EXPENSES: "burial_expenses",
  LTC: "ltc",
  ACCIDENTAL_DEATH: "accidental_death",
  GUARANTEED_ISSUE: "guaranteed_issue",
};

export function useInstanceSettingsStore(pinia, hot) {
  const env = getEnv();
  return defineStore("instance-settings", {
    state: () => ({
      api_url: env.VITE_API_URL,
      app_url: env.VITE_APP_URL,
      activity_id: null,
      api_key: null,
      app_slug: null,
      nonce: null,
      loaded_approved_domain: null,
      isDarkMode: false,
      errors: { google_tag_manager_id: null, resume_url: null },
      isAgent: false,
      isAgentSoftConsent: false,
      useFullscreen: false,
      new_npn_greeting: null,
      parent_url: null,
      prefill: {
        script: {},
        parent: {},
        location: {},
      },

      // TODO: switch `use_webapp` etc to `layout: webapp | modal | embedded`
      use_webapp: false,
      use_modal: false,
      isInternallyEmbedded: false,

      isExternallyEmbedded: false, // isExternallyEmbedded = is embedded from script
      ...ApprovedDomain(),
      overridePrefillBehavior: OverridePrefillBehavior(),
    }),
    getters: {
      domainContacts() {
        const contacts = [];

        if (this.market_as_agency) {
          contacts.push({
            type: this.commissions_paid_to.type,
            title: this.commissions_paid_to.name,
            subtitle: this.commissions_paid_to.type,
            email: null,
          });
        } else if (
          this.commissions_paid_to.id === this.agent.id &&
          this.commissions_paid_to.type === this.agent.type
        ) {
          contacts.push({
            type: this.agent.type,
            title: this.agent.name,
            subtitle: this.agent.type,
            email: this.agent.email,
          });
        } else {
          contacts.push(
            {
              type: this.agent.type,
              title: this.agent.name,
              subtitle: this.agent.type,
              email: this.agent.email,
            },
            {
              type: this.commissions_paid_to.type,
              title: this.commissions_paid_to.name,
              subtitle: this.commissions_paid_to.type,
              email: null,
            }
          );
        }
        return contacts;
      },
      schedulingLink() {
        if (!this.scheduling_link) return null;
        return `https://${this.scheduling_link}`;
      },
      zohoChatIsEnabled() {
        return Boolean(this.integrations.zoho_chat.key);
      },
      activeFirstInput() {
        // pre-fill has priority)
        if (this.use_webapp || this.use_modal) return true;
        if (this.primaryParams?.active_first_input) {
          return this.primaryParams.active_first_input === "true";
        } else if (this.queryWithKey("eapp_id")) {
          return true;
        }
        return this.active_first_input;
      },
      primaryParams() {
        return this.queryWithKey("prefill") || null;
      },
      available() {
        const available = [];
        Object.keys(this.products).map(val => {
          if (this.products[val]) {
            const type = ProductType(val);
            available.push(type);
          }
        });
        return available;
      },
      termAvailable() {
        return this.available.filter(val => val.isTerm);
      },
      ropAvailable() {
        return this.available.filter(val => val.isRopTerm);
      },
      accidentalDeathAvailable() {
        return this.available.filter(val => val.isAccidentalDeath);
      },
      permanentAvailable() {
        return this.available.filter(val => val.isPermanent);
      },
      ltcAvailable() {
        return this.available.filter(val => val.isLTC);
      },
      lifeAvailable() {
        return this.available.filter(val => !val.isLTC);
      },
      linkedBenefitAvailable() {
        return this.available.filter(val => val.isLinkedBenefit);
      },
      finalExpenseAvailable() {
        return this.available.filter(val => val.isFinalExpense);
      },
      isGradientLife() {
        return this.theme.type === THEME_TYPES.GRADIENT_LIFE;
      },
      isLifeEssentials() {
        return this.theme.type === THEME_TYPES.LIFE_ESSENTIALS;
      },
      availablePriorities() {
        const insuredStore = usePrimaryInsuredStore(pinia);
        const age = insuredStore.ageInMonths / 12;
        const priorities = [];

        if (this.products[PRODUCTS.TERM] && age >= 17.5 && age <= 80) {
          priorities.push(PRIORITIES.TERM);
        }

        if (this.products[PRODUCTS.ROP_TERM] && age >= 17.5 && age <= 65) {
          priorities.push(PRIORITIES.ROP_TERM);
        }

        if (this.permanentAvailable.length > 0) {
          priorities.push(PRIORITIES.PERMANENT);
        }

        if (this.products[PRODUCTS.ACCUMULATION_IUL]) {
          priorities.push(PRIORITIES.SUPPLEMENT_RETIREMENT);
        }

        if (this.products[PRODUCTS.WHOLE_LIFE]) {
          priorities.push(PRIORITIES.GROWTH);
        }

        if (this.products[PRODUCTS.INCOME_RIDER] && age >= 40) {
          priorities.push(PRIORITIES.INCOME_RIDER);
        }

        if (
          this.products[PRODUCTS.FINAL_EXPENSE] &&
          age >= FINAL_EXPENSE_MIN_AGE &&
          age <= FINAL_EXPENSE_MAX_AGE
        ) {
          priorities.push(PRIORITIES.BURIAL_EXPENSES);
        }

        const ltcProducts = [
          PRODUCTS.LTC,
          PRODUCTS.UNIVERSAL_LIFE,
          PRODUCTS.LINKED_BENEFIT,
        ];

        const ltcAvailable = ltcProducts.some(p => this.products[p]);

        if (ltcAvailable && age < 80 && age >= 17.5) {
          priorities.push(PRIORITIES.LTC);
        }

        if (
          this.products[PRODUCTS.ACCIDENTAL_DEATH] &&
          age >= 18 &&
          age <= 49
        ) {
          priorities.push(PRIORITIES.ACCIDENTAL_DEATH);
        } else if (
          this.products[PRODUCTS.FINAL_EXPENSE] &&
          age >= 50 &&
          age <= 80
        ) {
          priorities.push(PRIORITIES.GUARANTEED_ISSUE);
        }

        return priorities;
      },
      //Concepts can be composites of other product types. For Quote params reasons, it's handled like a product
      verboseConcepts() {
        const allUl = AllUniversalLife();
        if (this.products[PRODUCTS.UNIVERSAL_LIFE]) {
          allUl.permitFetchableType(PRODUCTS.UNIVERSAL_LIFE);
        }
        if (this.products[PRODUCTS.ACCUMULATION_IUL]) {
          allUl.permitFetchableType(PRODUCTS.ACCUMULATION_IUL);
        }
        if (this.products[PRODUCTS.PROTECTION_IUL]) {
          allUl.permitFetchableType(PRODUCTS.PROTECTION_IUL);
        }
        if (this.products[PRODUCTS.PROTECTION_VUL]) {
          allUl.permitFetchableType(PRODUCTS.PROTECTION_VUL);
        }

        const termConcept = TermConceptParams();
        if (this.products[PRODUCTS.TERM]) {
          termConcept.permitFetchableType(PRODUCTS.TERM);
        }
        if (this.products[PRODUCTS.NON_MED]) {
          termConcept.permitFetchableType(PRODUCTS.NON_MED);
        }

        return {
          [CONCEPTS.ALL_UL]: allUl,
          [CONCEPTS.TERM]: termConcept,
        };
      },
      concepts() {
        const concepts = {};
        Object.keys(this.verboseConcepts).forEach(key => {
          concepts[key] = Boolean(
            this.verboseConcepts[key].fetchableTypes.length
          );
        });
        return concepts;
      },
      metadata() {
        const metadata = objectPrefillValue(
          "metadata",
          this.queryPrefillForValue
        );
        if (!metadata.valid) return metadata;

        const prefillWithUtm = this.queryPrefillForValue(v =>
          Object.keys(v).some(k => UTM_METADATA_TAGS.has(k))
        );

        const utmMetadata = {};
        if (prefillWithUtm) {
          Object.keys(prefillWithUtm).forEach(f => {
            if (UTM_METADATA_TAGS.has(f)) {
              utmMetadata[f] = prefillWithUtm[f];
            }
          });
        }

        let metadataValue = {};
        if (typeof metadata.value === "string")
          metadataValue = JSON.parse(metadata.value);
        else if (typeof metadata.value === "object")
          metadataValue = metadata.value;

        let value = { ...metadataValue, ...utmMetadata };
        if (Object.values(value).length) value = JSON.stringify(value);
        else value = null;

        return { valid: true, value };
      },
      connections() {
        return objectPrefillValue("connections", this.queryPrefillForValue);
      },
      prefilledNpn() {
        return numberPrefillValue("npn", this.queryPrefillForValue);
      },
      assignmentNpn() {
        return numberPrefillValue("assignment_npn", this.queryPrefillForValue);
      },
      marketingManagerId() {
        return numberPrefillValue(
          "marketing_manager_id",
          this.queryPrefillForValue
        );
      },
      commissionLock() {
        return booleanPrefillValue(
          "commission_lock",
          this.queryPrefillForValue
        );
      },
      referData() {
        const referrer_id = numberPrefillValue(
          "referrer_id",
          this.queryPrefillForValue
        );
        const referrer_type = stringPrefillValue(
          "referrer_type",
          this.queryPrefillForValue
        );
        if (!referrer_id || !referrer_type) return null;
        return {
          referrer_id,
          referrer_type,
        };
      },
    },
    actions: {
      setDefaults() {
        const flow = useFlowStore(pinia);
        const eApp = useEappStore(pinia);
        const quoting = useQuotingStore(pinia);

        this.activity_id = generateUuid();
        flow.updateStepRequirements(this.data_requirements);
        eApp.parent_url = this.parent_url;
        quoting.params.mode = this.default_params.mode;
        quoting.params.age_value = 120;
        quoting.params.benefit_period = 4;
        quoting.params.cash_value = 1;
        quoting.params.crediting_rate = "Default Rate";
        quoting.params.monthly_benefit = MONTHLY_BENEFIT_MOST_COMMON;
        quoting.params.filter_chronic_illness_and_ltc = WITHOUT_CHRONIC_LTC;
        quoting.params.home_health_care_waiver = false;
        quoting.params.income_end_age = 90;
        quoting.params.income_solve = "No";
        quoting.params.inflation_percentage = "3% Compound";
        quoting.params.lapse_protection_to_age = 120;
        quoting.params.living_benefits = false;
        quoting.params.ltc_rider_percentage = 4;
        quoting.params.pay_duration = -1;
        quoting.params.save_age = false;
        quoting.params.shared_care = false;
        quoting.params.joint_waiver_of_premium = false;
        quoting.params.solve = "";
        quoting.params.vitality_level = "Bronze";
      },

      queryWithKey(key) {
        return this.queryPrefillForValue(v => key in v);
      },
      queryPrefillForValue(valueFn) {
        if (valueFn(this.prefill.parent)) {
          return this.prefill.parent;
        } else if (valueFn(this.prefill.script)) {
          return this.prefill.script;
        } else if (valueFn(this.prefill.location)) {
          return this.prefill.location;
        }
      },
    },
  })(pinia, hot);
}
