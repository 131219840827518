import { COUNTRIES_WITH_US, STATES } from "#src/data/states-and-countries.js";
import {
  mdiCalendarBlankMultiple,
  mdiCity,
  mdiEarth,
  mdiHomeGroup,
  mdiMapMarker,
  mdiSignRealEstate,
} from "@mdi/js";
import { nextTick } from "vue";

function BasicFieldProps({
  storeKey,
  dataTestid,
  label,
  store,
  savableProperty,
}) {
  return { storeKey, dataTestid, label, store, savableProperty };
}

export function AddressAutofillField(componentProps = BasicFieldProps()) {
  return { is: "FormBuilderAddressAutofillInput", componentProps };
}

export function FormBuilderField(componentProps = BasicFieldProps()) {
  return { is: "FormBuilder", componentProps };
}

export function CustomButtonsField(
  componentProps = {
    ...BasicFieldProps(),
    ignoreChangelessClick: false,
    skipValidateOnChange: false,
    items: [],
    justify: "start",
    ariaLabelledBy: null,
  }
) {
  return {
    is: "FormBuilderButtonsInput",
    type: "button-input",
    componentProps,
  };
}

export function YesNoField(
  componentProps = {
    ...BasicFieldProps(),
    ignoreChangelessClick: false,
    justify: "start",
  }
) {
  return CustomButtonsField({
    ...componentProps,
    items: [
      {
        title: "Yes",
        value: true,
        dataTestid: `${componentProps.dataTestid}-yes`,
      },
      {
        title: "No",
        value: false,
        dataTestid: `${componentProps.dataTestid}-no`,
      },
    ],
  });
}

export function FormIcon(icon, key, componentProps = { color: null }) {
  return {
    is: "VIcon",
    componentProps: {
      icon,
      ...componentProps,
    },
    mdColSize: componentProps?.mdColSize || "auto",
    colSize: componentProps?.colSize || "auto",
    key,
  };
}

export function FormSpacer(key) {
  return {
    is: "VSpacer",
    key,
  };
}

export function TextField(
  componentProps = {
    ...BasicFieldProps(),
    placeholder: null,
    prependInnerIcon: null,
  }
) {
  return { is: "FormBuilderTextInput", componentProps };
}

export function TextareaField(
  componentProps = {
    ...BasicFieldProps(),
    placeholder: null,
    prependInnerIcon: null,
  }
) {
  return { is: "FormBuilderTextareaInput", componentProps };
}

export function EmailField(
  componentProps = {
    ...BasicFieldProps(),
    placeholder: null,
    prependInnerIcon: null,
  }
) {
  return { is: "FormBuilderEmailInput", componentProps };
}

export function DateField(
  componentProps = {
    ...BasicFieldProps(),
    placeholder: null,
    prependInnerIcon: null,
  }
) {
  return { is: "FormBuilderDateInput", componentProps };
}

export function BirthdateField(componentProps = BasicFieldProps()) {
  return {
    is: "FormBuilderBirthdateInput",
    type: "birthdate-input",
    componentProps,
  };
}

export function CurrencyField(
  componentProps = {
    ...BasicFieldProps(),
    placeholder: null,
    prependInnerIcon: null,
  }
) {
  return { is: "FormBuilderCurrencyInput", componentProps };
}

export function SsnField(
  componentProps = { ...BasicFieldProps(), prependInnerIcon: null }
) {
  return { is: "FormBuilderSsnInput", componentProps };
}

export function MaskedField(
  componentProps = { ...BasicFieldProps(), prependInnerIcon: null, mask: null }
) {
  return { is: "FormBuilderMaskedInput", componentProps };
}

export function TinField(
  componentProps = { ...BasicFieldProps(), prependInnerIcon: null }
) {
  return MaskedField({
    ...componentProps,
    mask: "##-#######",
    inputmode: "numeric",
  });
}

export function PhoneField(
  componentProps = { ...BasicFieldProps(), prependInnerIcon: null }
) {
  return MaskedField({
    ...componentProps,
    mask: "(###) ###-####",
    inputmode: "tel",
  });
}

export function RoutingNumberField(
  componentProps = { ...BasicFieldProps(), prependInnerIcon: null }
) {
  return MaskedField({
    ...componentProps,
    mask: "#########",
    placeholder: "#########",
    inputmode: "numeric",
  });
}

export function AccountNumberField(
  componentProps = { ...BasicFieldProps(), prependInnerIcon: null }
) {
  return MaskedField({
    ...componentProps,
    mask: "#################",
    placeholder: "#################",
    inputmode: "numeric",
  });
}

export function CheckAlertField(
  componentProps = { ...BasicFieldProps(), alertProps: {} }
) {
  return { is: "FormBuilderCheckAlertInput", componentProps };
}

export function IntegerField(
  componentProps = {
    ...BasicFieldProps(),
    prependInnerIcon: null,
    placeholder: null,
    min: null,
    max: null,
    mask: null,
  }
) {
  return { is: "FormBuilderIntegerInput", componentProps };
}

export function SelectField(
  componentProps = {
    ...BasicFieldProps(),
    placeholder: null,
    prependInnerIcon: null,
    items: [],
  }
) {
  return { is: "FormBuilderSelectInput", componentProps };
}

export function RadioField(
  componentProps = { ...BasicFieldProps(), items: [], centered: false }
) {
  return { is: "FormBuilderRadioInput", componentProps };
}

export function CheckboxField(
  componentProps = { ...BasicFieldProps(), items: [] }
) {
  return { is: "FormBuilderCheckboxGroup", componentProps };
}

export function RatingField(
  componentProps = {
    ...BasicFieldProps(),
    placeholder: null,
    prependInnerIcon: null,
    items: [],
  }
) {
  return { is: "FormBuilderRatingInput", type: "rating-input", componentProps };
}

export function AdvancedHealthField(
  componentProps = {
    ...BasicFieldProps(),
    onlyActiveLine: false,
    autofocus: false,
  }
) {
  return { is: "FormBuilderAdvancedHealthInput", componentProps };
}

export function AutocompleteField(
  componentProps = {
    ...BasicFieldProps(),
    prependInnerIcon: null,
    placeholder: null,
    items: [],
    customFilter: null,
  }
) {
  return { is: "FormBuilderAutocompleteInput", componentProps };
}

export function FormSubheader(title, key, componentProps = {}) {
  return {
    is: "FormBuilderSubheader",
    colSize: 12,
    mdColSize: 12,
    key,
    componentProps: {
      title,
      ...componentProps,
    },
  };
}

export function FormBasicSubheader(title, key, componentProps = {}) {
  return {
    is: "FormBuilderBasicSubheader",
    colSize: 12,
    mdColSize: componentProps?.mdColSize || 12,
    key,
    componentProps: {
      title,
      ...componentProps,
    },
  };
}

export function FormLoader(message, key, componentProps) {
  return {
    is: "Loader",
    colSize: 12,
    mdColSize: 12,
    key,
    componentProps: {
      message,
      ...componentProps,
    },
  };
}

export function FormTextLink(textLinkParts, key, componentProps = {}) {
  return {
    is: "FormBuilderTextLink",
    key,
    mdColSize: 12,
    colSize: 12,
    componentProps: {
      textLinkParts,
      ...componentProps,
    },
  };
}

export function FormAlert(text, key, componentProps) {
  return {
    is: "VAlert",
    colSize: 12,
    mdColSize: 12,
    key,
    componentProps: {
      text,
      ...componentProps,
    },
  };
}

export function FormButton(text, key, componentProps) {
  return {
    is: "VBtn",
    colSize: componentProps?.colSize || 12,
    mdColSize: componentProps?.mdColSize || 12,
    key,
    componentProps: {
      text,
      ...componentProps,
    },
  };
}

// used for orphan storable form properties
export function FormFieldAdapter(
  fieldConfig,
  builderProps = { key: "" },
  additionalProps = {}
) {
  return {
    is: fieldConfig.is,
    ...builderProps,
    componentProps: {
      ...fieldConfig.componentProps,
      ...additionalProps,
    },
  };
}

export function AddressField({
  switcher = {
    manualEntry: false,
    setManualEntry: () => {},
  },
  fieldOptions = {
    street: {
      dataTestid: null,
      label: null,
      placeholder: null,
      savableProperty: null,
    },
    state: {
      dataTestid: null,
      label: null,
      placeholder: null,
      savableProperty: null,
    },
    city: {
      dataTestid: null,
      label: null,
      placeholder: null,
      savableProperty: null,
    },
    zip: {
      dataTestid: null,
      label: null,
      placeholder: null,
      savableProperty: null,
    },
    country: {
      visible: false,
      dataTestid: null,
      label: null,
      placeholder: null,
      savableProperty: null,
    },
    autofill: {
      dataTestid: null,
      label: null,
      placeholder: null,
      savableProperty: null,
      autofocus: false,
      save: null,
      autocomplete: null,
    },
    years_at_address: {
      visible: false,
      dataTestid: null,
      label: null,
      placeholder: null,
      savableProperty: null,
      maxYears: null,
    },
  },
}) {
  const fields = [];
  if (fieldOptions.street.savableProperty.model || switcher.manualEntry) {
    switcher.setManualEntry(true);
    const mdColSize =
      fieldOptions?.country?.visible || fieldOptions?.years_at_address?.visible
        ? 3
        : 4;
    fields.push(
      FormFieldAdapter(
        TextField({
          savableProperty: fieldOptions.street.savableProperty,
          prependInnerIcon: mdiSignRealEstate,
          ...fieldOptions.street,
        }),
        { key: "street", mdColSize: 12, colSize: 12 }
      ),
      FormFieldAdapter(
        TextField({
          savableProperty: fieldOptions.city.savableProperty,
          prependInnerIcon: mdiCity,
          ...fieldOptions.city,
        }),
        { key: "city", mdColSize, colSize: 12 }
      ),
      FormFieldAdapter(
        AutocompleteField({
          savableProperty: fieldOptions.state.savableProperty,
          prependInnerIcon: mdiHomeGroup,
          items: STATES,
          ...fieldOptions.state,
        }),
        { key: "state", mdColSize, colSize: 12 }
      ),
      FormFieldAdapter(
        MaskedField({
          savableProperty: fieldOptions.zip.savableProperty,
          prependInnerIcon: mdiMapMarker,
          inputmode: "numeric",
          mask: "#####",
          ...fieldOptions.zip,
        }),
        { key: "zip", mdColSize, colSize: 12 }
      )
    );

    if (fieldOptions?.country?.visible) {
      fields.push(
        FormFieldAdapter(
          AutocompleteField({
            savableProperty: fieldOptions.country.savableProperty,
            prependInnerIcon: mdiEarth,
            items: COUNTRIES_WITH_US,
            ...fieldOptions.country,
          }),
          { key: "country", mdColSize, colSize: 12 }
        )
      );
    }

    if (fieldOptions?.years_at_address?.visible) {
      fields.push(
        FormFieldAdapter(
          IntegerField({
            savableProperty: fieldOptions.years_at_address.savableProperty,
            prependInnerIcon: mdiCalendarBlankMultiple,
            ...fieldOptions.years_at_address,
          }),
          { key: "years-at-address", mdColSize }
        )
      );
    }
  } else {
    fields.push(
      FormFieldAdapter(
        AddressAutofillField({
          label: fieldOptions.autofill.label,
          placeholder: fieldOptions.autofill.placeholder,
          dataTestid: fieldOptions.autofill.dataTestid,
          savableProperty: fieldOptions.autofill.savableProperty,
          autocomplete: fieldOptions.autofill.autocomplete,
        }),
        {
          colSize: 12,
          mdColSize: 12,
          key: fieldOptions.autofill.dataTestid,
        },
        {
          autofocus: fieldOptions.autofill.autofocus,
          loading: Boolean(fieldOptions.street.savableProperty.requests.length),
          disabled: Boolean(
            fieldOptions.street.savableProperty.requests.length
          ),
          onError: () => switcher.setManualEntry(true),
          onSwitchToManual: () => switcher.setManualEntry(true),
          "onUpdate:modelValue": address => {
            fieldOptions.street.savableProperty.load(address.street_address);
            fieldOptions.state.savableProperty.load(address.state);
            fieldOptions.city.savableProperty.load(address.city);
            fieldOptions.zip.savableProperty.load(address.zip);
            if (fieldOptions.country?.savableProperty) {
              fieldOptions.country.savableProperty.load(address.country);
            }
          },
          overrideSave: async () => {
            await fieldOptions.autofill.save();
            switcher.setManualEntry(true);
          },
        }
      )
    );
  }

  return fields;
}

export function FormExpansionPanels(key, fields, componentProps) {
  return FormFieldAdapter(
    { is: "VExpansionPanels" },
    { key, slots: [{ name: "default", fields }] },
    componentProps
  );
}

export function FormExpansionPanel(key, fields, componentProps) {
  return FormFieldAdapter(
    { is: "VExpansionPanel" },
    { key, slots: [{ name: "default", fields }] },
    { eager: true, ...componentProps }
  );
}

export function FormExpansionPanelBody(key, structure) {
  return FormFieldAdapter(
    { is: "VExpansionPanelText" },
    {
      key: "body",
      slots: [
        {
          name: "default",
          fields: [
            FormFieldAdapter({ is: "FormBuilder" }, { key }, { structure }),
          ],
        },
      ],
    }
  );
}

export function FormExpansionPanelTitle(key, fields, componentProps) {
  return FormFieldAdapter(
    { is: "VExpansionPanelTitle" },
    { key, slots: [{ name: "default", fields }] },
    componentProps
  );
}

export function validateFormStructure(structure, throwOnFailure) {
  const failures = getFillableFormStructure(structure)
    .map(item => {
      const field = item.property;
      const noPendingRequests = !field.requests.length;

      if (item.formField.componentProps.isOptional) {
        const isNotDefined = !field.model && field.model !== 0;
        if (isNotDefined && noPendingRequests) return { valid: true, ...item };
      }

      const valid = field.validate(true);
      return { valid, ...item };
    })
    .filter(v => !v.valid);

  if (failures.length && throwOnFailure) {
    throw failures;
  }
  return !failures.length;
}

function getFillableFormStructure(structure) {
  const fieldsToValidate = getAllStructure(structure);

  return fieldsToValidate.map(({ field: v, parents }) => {
    let field;

    if (v.componentProps.savableProperty) {
      field = v.componentProps.savableProperty;
    } else {
      field = v.componentProps.store()[v.componentProps.storeKey];
    }

    return {
      property: field,
      formField: v,
      key: v.key,
      parents,
      optional: v.componentProps.isOptional,
    };
  });
}

const getAllStructure = structure => {
  const structureAnon = (accumulator, field, parents = []) => {
    const newParents = [...parents, field];
    if (Array.isArray(field)) {
      return field.forEach(s => structureAnon(accumulator, s, newParents));
    }

    if (field.structure)
      field.structure.forEach(s => structureAnon(accumulator, s, newParents));
    if (field.slots)
      field.slots.forEach(s => structureAnon(accumulator, s, newParents));
    if (field.fields)
      field.fields.forEach(s => structureAnon(accumulator, s, newParents));

    if (field.componentProps?.structure) {
      field.componentProps.structure.forEach(s =>
        structureAnon(accumulator, s, newParents)
      );
    }

    if (
      field.componentProps?.storeKey ||
      field.componentProps?.savableProperty
    ) {
      accumulator.push({ field, parents });
    }
  };

  const acc = [];
  structureAnon(acc, structure);
  return acc;
};

const setFocusOnField = (field, { isEmpty = false } = {}) => {
  if (isEmpty) {
    if (field.componentProps?.store) {
      if (
        field.componentProps.store()[field.componentProps.storeKey].model !==
        null
      )
        return;
    }

    if (field.componentProps?.savableProperty) {
      if (field.componentProps.savableProperty.model !== null) return;
    }
  }

  let el;

  switch (field.type) {
    case "button-input":
    case "rating-input": {
      const parent = document.getElementById(
        `${field.componentProps.dataTestid}-group`
      );
      el = parent.querySelector(`button[tabindex="0"]`);
      break;
    }
    case "birthdate-input": {
      el = document.querySelector('[data-testid="birthdate-month"] input');
      break;
    }
    default: {
      el = document.querySelector(
        `[data-testid="${field.componentProps.dataTestid}"] input`
      );
    }
  }

  try {
    if (
      document.activeElement &&
      ["INPUT", "TEXTAREA"].includes(document.activeElement.tagName)
    )
      return;
    if (el) el.focus();
    // eslint-disable-next-line no-unused-vars
  } catch (e) {
    return;
  }

  return Boolean(el);
};

export async function focusOnFirstElement(structure) {
  await nextTick();
  await nextTick();
  const focusable = getAllStructure(structure);
  focusable.find(({ field }) => setFocusOnField(field, { isEmpty: false }));
}

export async function focusOnFirstEmptyElement(
  structure,
  previousFieldList = [],
  behavingOnChange = false
) {
  await nextTick();
  await nextTick();
  const focusable = getAllStructure(structure);

  const previousFields = new Set(previousFieldList);
  const newFields = [];
  const currentFields = [];
  focusable.forEach(({ field }) => {
    currentFields.push(field.key);
    if (previousFields.has(field.key)) return;
    newFields.push({ key: field.key, field });
  });

  const focusStruct = behavingOnChange ? newFields : focusable;
  focusStruct.find(({ field }) => setFocusOnField(field, { isEmpty: true }));

  return currentFields;
}
