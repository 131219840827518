<template>
  <div>
    <v-text-field
      :id="props.dataTestid"
      ref="el"
      v-model="model"
      data-lpignore="true"
      variant="outlined"
      density="compact"
      :aria-label="$attrs.label"
      :class="inputClasses"
      :color="color"
      :base-color="baseColor"
      :error-messages="errorMessages"
      :data-testid="props.dataTestid"
      v-bind="$attrs"
      @update:model-value="debounceAndValidate"
    >
      <template #append-inner>
        <active-save-indicator :controller="savingBuffer" />
      </template>
      <template v-if="$slots.message" #message>
        <slot name="message" />
      </template>
    </v-text-field>
  </div>
</template>

<script setup>
import ActiveSaveIndicator from "#src/components/shared/ActiveSaveIndicator.vue";
import {
  useInput,
  useInputProps,
} from "#src/composables/savable-input.composable";
import { inject } from "vue";

const pinia = inject("pinia");
const props = defineProps(useInputProps());

const {
  model,
  inputClasses,
  color,
  baseColor,
  errorMessages,
  savingBuffer,
  debounceAndValidate,
} = useInput(props, pinia);
</script>
