<template>
  <v-row class="ma-0 h-100 white" justify="center" align="center">
    <v-card flat>
      <v-card-title data-testid="error-message">
        {{ error.title }}
      </v-card-title>
      <v-card-subtitle
        v-if="error.text"
        class="full-opacity"
        data-testid="error-subtitle"
      >
        <include-links-in-text :text="error.text" />
      </v-card-subtitle>
      <v-row class="ma-0" justify="center" align="center">
        <v-card
          style="max-width: min(80vw, 35rem); width: 100%"
          height="25rem"
          class="my-6 overflow-hidden"
          :color="
            error.style === CRITICAL_FAILURE_STYLES.BAD_DOMAIN
              ? null
              : '#525252'
          "
        >
          <div class="window-container">
            <div class="window-icon bg-red" />
            <div class="window-icon bg-orange" />
            <div class="window-icon bg-green" />
          </div>

          <v-row
            v-if="error.style === CRITICAL_FAILURE_STYLES.BAD_DOMAIN"
            class="pa-0 ma-0 h-100"
            justify="center"
            align="center"
            data-testid="bad-domain-img"
          >
            <bad-domain
              style="max-width: min(80vw, 20rem); max-height: 19rem"
            />
          </v-row>
          <div v-else data-testid="default-img" class="h-100">
            <div class="connection-container">
              <div class="connection-container-accent"></div>
              <connection-issue
                style="
                  width: 35rem;
                  height: 25rem;
                  position: absolute;
                  top: 50%;
                  bottom: 50%;
                  left: 50%;
                  right: 50%;
                  transform: translateX(-50%) translateY(-50%) rotate(26deg);
                "
              />
            </div>
          </div>
        </v-card>
      </v-row>
    </v-card>
  </v-row>
</template>

<script setup>
import IncludeLinksInText from "#src/components/shared/IncludeLinksInText.vue";
import BadDomain from "#src/components/shared/icons/BadDomain.vue";
import ConnectionIssue from "#src/components/shared/icons/ConnectionIssue.vue";
import { useInstanceSettingsStore } from "#src/stores/instance-settings";
import {
  CRITICAL_FAILURE_REASONS,
  useStepCriticalFailure,
} from "#src/stores/step-critical-failure";
import { storeToRefs } from "pinia";
import { computed, inject } from "vue";

const pinia = inject("pinia");
const criticalFailureStep = useStepCriticalFailure(pinia);
const instanceSettings = useInstanceSettingsStore(pinia);
const { error_message } = storeToRefs(instanceSettings);

const CRITICAL_FAILURE_STYLES = {
  CONNECTION_ISSUE: "CONNECTION_ISSUE",
  BAD_DOMAIN: "BAD_DOMAIN",
};
// NOTE(tim): BAD_DOMAIN, REQUIRES_SUBSCRIPTION, and NO_PRODUCTS could be handled by Fairway
const error = computed(() => {
  switch (criticalFailureStep.criticalFailureReason) {
    case CRITICAL_FAILURE_REASONS.SERVER_BLOCKED: {
      return {
        title: error_message.value.title,
        text: error_message.value.text,
        style: CRITICAL_FAILURE_STYLES.CONNECTION_ISSUE,
      };
    }
    case CRITICAL_FAILURE_REASONS.BAD_DOMAIN: {
      return {
        title: "Unable to start Quote & Apply",
        text: "The Quote & Apply website you are trying to access is either deleted or does not exist.\nPlease go to https://app.back9ins.com/quote-and-apply to verify this Quote & Apply website exists.",
        style: CRITICAL_FAILURE_STYLES.BAD_DOMAIN,
      };
    }
    case CRITICAL_FAILURE_REASONS.REQUIRES_SUBSCRIPTION: {
      return {
        title: "Unable to start Quote & Apply",
        text: "Embedding Quote & Apply requires a subscription.\nPlease go to https://app.back9ins.com/settings?page=subscriptions to subscribe.",
        style: CRITICAL_FAILURE_STYLES.CONNECTION_ISSUE,
      };
    }
    case CRITICAL_FAILURE_REASONS.NO_PRODUCTS: {
      return {
        title: "No Quote & Apply Products Detected",
        text: "",
        style: CRITICAL_FAILURE_STYLES.CONNECTION_ISSUE,
      };
    }
    default: {
      return {
        title: "We're sorry, Quote & Apply is not working correctly",
        text: "We are aware of the issue and it will be resolved shortly",
        style: CRITICAL_FAILURE_STYLES.CONNECTION_ISSUE,
      };
    }
  }
});
</script>

<style lang="scss">
.window-icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 5px;
}
.window-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  display: flex;
  z-index: 3;
}
.connection-container {
  position: absolute;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  transform: rotate(-28deg);
}
.connection-container-accent {
  width: 50.1%;
  background-color: #ffffff;
  height: 100%;
  position: absolute;
}
</style>
