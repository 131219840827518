import { steps, stepToRouteName } from "#src/enumerations/step-enumeration.js";
import { REQS } from "#src/enumerations/data-requirements.js";
import { CoverageService } from "#src/services/coverage.service.js";
import { defineStore } from "#src/stores/state-wrapper.js";

import { useStepPhysicianStore } from "#src/stores/step-physician.js";
import { useEappStore } from "#src/stores/electronic-application.js";
import { usePrimaryInsuredStore } from "#src/stores/insured.js";
import { useStepPersonalStore } from "#src/stores/step-personal.js";

export const useStepExistingCoverageStore = (pinia, hot) =>
  defineStore("step-existing-coverage", {
    state: () => ({
      inactive: false,
      requiredFields: {
        soldBySameAgent: false,
      },
      carriers: [],
      gettingCarriers: false,
    }),
    getters: {
      step: () => steps.EXISTING_COVERAGE,
      routeName: s => stepToRouteName[s.step],
      previous: () => {
        const personal = useStepPersonalStore(pinia);
        if (personal.inactive) return personal.previous;
        return personal.step;
      },
      next() {
        const physician = useStepPhysicianStore(pinia);
        if (physician.inactive) return physician.next;
        return physician.step;
      },
      skippable() {
        const eApp = useEappStore(pinia);
        if (eApp.isDualInsured) {
          return !eApp.existing_coverages.length;
        }
        const insured = usePrimaryInsuredStore(pinia);
        return !eApp.existingCoveragesByInsuredId[insured.id]?.length;
      },
    },
    actions: {
      setStepRequirements(srq) {
        this.inactive = !srq[REQS.EXISTING_COVERAGE];

        this.requiredFields.soldBySameAgent =
          srq[REQS.EXISTING_COVERAGE_SOLD_BY_SAME_AGENT];
      },
      async getCarriers() {
        const coverageService = new CoverageService(pinia);
        this.carriers = await coverageService.getCoverageCarriers();
      },
    },
  })(pinia, hot);
