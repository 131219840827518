import { httpClient } from "#src/HttpClient.js";
import { useEappStore } from "#src/stores/electronic-application.js";
import { useInstanceSettingsStore } from "#src/stores/instance-settings.js";

export class EntityService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/entities`;
    this.#httpClient = httpClient(pinia);
  }

  #attachEappData(params) {
    const { id, uuid } = useEappStore(this.#pinia);
    return {
      electronic_application_id: id,
      uuid: uuid,
      ...params,
    };
  }

  async createEntity({
    application_roles_attributes,
    addresses_attributes,
    irrevocable,
  }) {
    const entity = {
      irrevocable,
      application_roles_attributes,
      addresses_attributes,
    };

    const { data } = await this.#httpClient.post(
      this.#baseUrl,
      this.#attachEappData({ entity })
    );
    return data.entity;
  }

  async updateEntity(id, entity) {
    const { data } = await this.#httpClient.put(
      `${this.#baseUrl}/${id}`,
      this.#attachEappData({ entity })
    );
    return data?.entity;
  }

  async deleteEntity(id) {
    return this.#httpClient.delete(`${this.#baseUrl}/${id}`, {
      params: this.#attachEappData({}),
    });
  }

  async updateEft(id, { routing_number, account_number, draft_day_of_month }) {
    const body = this.#attachEappData({
      routing_number,
      account_number,
      draft_day_of_month,
    });

    const { data } = await this.#httpClient.put(
      `${this.#baseUrl}/${id}/eft`,
      body
    );
    return {
      account_last_4: data.account_number_last_4,
      routing_last_4: data.routing_number_last_4,
    };
  }
}
