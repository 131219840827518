// Note the api does not support active saving for smoking usages
import { useInsuredStore } from "#src/stores/insured.js";
import { mdiDelete, mdiPlus } from "@mdi/js";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import {
  SMOKING_NEVER,
  SMOKING_PREVIOUSLY,
  SMOKING_CURRENTLY,
} from "#src/structures/SmokingUsage.js";

import {
  useLastUseDateProperty,
  useCategoryProperty,
  useFrequencyProperty,
} from "#src/composables/smoking-usage-form-property.composable.js";

import {
  useInsuredSmokerButtonsProperty,
  useInsuredSmokerSelectProperty,
} from "#src/composables/insured-form-property.composable.js";

import {
  FormBasicSubheader,
  FormButton,
  FormExpansionPanels,
  FormExpansionPanel,
  FormExpansionPanelBody,
  FormExpansionPanelTitle,
  validateFormStructure,
} from "#src/components/form-builder/FormBuilderTemplates.js";
import { isServerSide } from "#src/util/helpers.js";

export const useSmokerForm = (
  pinia,
  module,
  { skipCb, dense, titleId } = {}
) => {
  const insuredStore = useInsuredStore(module, pinia);
  const { smoker, smoking_statuses } = storeToRefs(insuredStore);

  const deletable = computed(() => smoking_statuses.value.length > 1);
  const smokingStatusCount = computed(() => smoking_statuses.value.length);
  const smokingType = computed(() => smoker.value.model);
  const showSmokingUsage = computed(() => {
    return [SMOKING_PREVIOUSLY, SMOKING_CURRENTLY].includes(smoker.value.model);
  });

  const activeUsageIndex = ref(null);

  function addUsage() {
    const id = insuredStore.createSmokingUsage();
    const index = smoking_statuses.value.findIndex(v => v === id);
    if (index > -1) activeUsageIndex.value = index;
  }

  if (
    !isServerSide() &&
    showSmokingUsage.value &&
    !smoking_statuses.value.length
  ) {
    addUsage();
  }

  const smokingStatusField = () => {
    let property = useInsuredSmokerButtonsProperty;
    if (dense) property = useInsuredSmokerSelectProperty;

    return property(pinia, module, {
      mdColSize: 12,
      justify: "center",
      ariaLabelledBy: titleId,
      onAfterSave() {
        if (smoker.value.model === SMOKING_NEVER) {
          smoking_statuses.value.forEach(id =>
            insuredStore.deleteSmokingUsage(id)
          );
          if (skipCb) skipCb();
        } else if (
          [SMOKING_PREVIOUSLY, SMOKING_CURRENTLY].includes(smoker.value.model)
        ) {
          if (!smoking_statuses.value.length) addUsage();
        }
      },
    });
  };

  const addUsageButton = () => {
    return FormButton("Add Usage", "add-usage", {
      prependIcon: mdiPlus,
      onClick: addUsage,
      variant: "outlined",
      rounded: true,
      color: "primary",
      class: "text-none text-left mb-5",
    });
  };

  const usageTitle = (usageId, index) => {
    return FormBasicSubheader(
      `Smoking Status ${index + 1}`,
      usageId + "subheader",
      {
        class: "text-white mb-0",
      }
    );
  };

  const deleteUsageButton = usageId => {
    return FormButton("", "delete-usage", {
      icon: mdiDelete,
      variant: "text",
      onClick: () => insuredStore.deleteSmokingUsage(usageId),
      color: "error",
      class: "text-none",
      style: "position: absolute; right: -42px",
      size: "small",
    });
  };

  const categoryField = (usageId, index) => {
    const key = `smoking_status.${index}.category`;
    return useCategoryProperty(pinia, usageId, { overrideSave: () => {}, key });
  };

  const frequencyField = (usageId, index) => {
    const key = `smoking_status.${index}.frequency`;
    return useFrequencyProperty(pinia, usageId, {
      overrideSave: () => {},
      key,
    });
  };

  const lastUseDateField = (usageId, index) => {
    const key = `smoking_status.${index}.last_use_date`;
    return useLastUseDateProperty(pinia, usageId, {
      mdColSize: 12,
      overrideSave: () => {},
      key,
    });
  };

  const expansionPanelTitle = (usageId, index) => {
    const titleFields = [usageTitle(usageId, index)];

    if (deletable.value) {
      titleFields.push(deleteUsageButton(usageId));
    }
    return FormExpansionPanelTitle(`${usageId}-title`, titleFields, {
      color: "primary",
      class: "text-white",
    });
  };

  const expansionPanelBody = (usageId, index) => {
    const usageStructure = {
      fields: [categoryField(usageId, index), frequencyField(usageId, index)],
      key: `${usageId}-smoker-fields`,
    };

    if (smoker.value.model === SMOKING_PREVIOUSLY) {
      usageStructure.fields.push(lastUseDateField(usageId, index));
    }
    return FormExpansionPanelBody(`${usageId}-body`, [usageStructure]);
  };

  const expansionPanel = (usageId, index, panelFields) => {
    return FormExpansionPanel(`${usageId}-panel`, panelFields, {
      index,
      "data-testid": `${module}-usage-${index}`,
    });
  };

  const expansionPanels = () => {
    let panelClasses = "";
    if (deletable.value) {
      panelClasses = "pr-9";
    }

    return FormExpansionPanels(
      "smoking-statuses-container",
      smoking_statuses.value.map((usageId, index) => {
        const panelFields = [
          expansionPanelTitle(usageId, index),
          expansionPanelBody(usageId, index),
        ];
        return expansionPanel(usageId, index, panelFields);
      }),
      {
        accordion: true,
        class: panelClasses,
        modelValue: activeUsageIndex,
        "onUpdate:modelValue"(v) {
          activeUsageIndex.value = v;
        },
      }
    );
  };

  const buildRoot = () => {
    return { fields: [smokingStatusField()], key: `${module}-smoker-root` };
  };

  const buildAddUsage = () => {
    return {
      fields: [addUsageButton()],
      key: "add-usage",
      componentProps: { class: "text-left" },
    };
  };

  const buildUsages = () => {
    return { fields: [expansionPanels()], key: "usages-list" };
  };

  function buildStructure() {
    const forms = [buildRoot()];
    if (!showSmokingUsage.value) return forms;

    forms.push(buildUsages(), buildAddUsage());

    return forms;
  }

  function validate({ throwOnFailure = false } = {}) {
    try {
      validateFormStructure(buildStructure(), true);
    } catch (e) {
      if (throwOnFailure) throw e;
      if (Array.isArray(e)) {
        const parentPanel = e[0].parents.find(v => v.is === "VExpansionPanel");
        if (parentPanel)
          activeUsageIndex.value = parentPanel.componentProps.index;
      }
      return false;
    }
    if (smoker.value.model === SMOKING_NEVER) return true;

    const validSmokingStatusCount = smoking_statuses.value.length > 0;
    if (throwOnFailure && !validSmokingStatusCount) {
      throw [
        { key: "smoking_statuses", property: { errorMessages: ["required"] } },
      ];
    }

    return validSmokingStatusCount;
  }

  const reactiveDependencies = [
    smokingStatusCount,
    showSmokingUsage,
    smokingType,
  ];

  return { buildStructure, validate, activeUsageIndex, reactiveDependencies };
};
