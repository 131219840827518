<template>
  <step-layout>
    <template #title> Who is this demo for? </template>
    <template #body>
      <form-builder class="pa-3 mt-3" :structure="structure" />
    </template>
  </step-layout>
</template>

<script setup>
import FormBuilder from "#src/components/form-builder/FormBuilder.vue";
import StepLayout from "#src/components/shared/StepLayout.vue";
import { mdiAccount } from "@mdi/js";
import { useEappDemoNameProperty } from "#src/composables/eapp-form-property.composable";
import { validateFormStructure } from "#src/components/form-builder/FormBuilderTemplates.js";
import { inject } from "vue";

const pinia = inject("pinia");

const structure = [
  {
    key: "demo-step",
    fields: [
      useEappDemoNameProperty(pinia, {
        label: "Client Name",
        placeholder: "Client Name",
        prependInnerIcon: mdiAccount,
      }),
    ],
  },
];

function valid() {
  return validateFormStructure(structure);
}

defineExpose({ valid });
</script>
