import { storeToRefs } from "pinia";
import {
  useInsuredStore,
  usePrimaryInsuredStore,
  useJointInsuredStore,
} from "#src/stores/insured.js";
import { useEappStore } from "#src/stores/electronic-application.js";

import {
  ALL_OPTIONS,
  LIFE_ONLY_OPTIONS,
  LTC_ONLY_OPTIONS,
} from "#src/data/healthTypes.js";
import { useInstanceSettingsStore } from "#src/stores/instance-settings.js";
import { computed } from "vue";
import {
  useInsuredAdvancedHealthProperty,
  useInsuredHealthRatingProperty,
} from "#src/composables/insured-form-property.composable.js";
import {
  FormButton,
  validateFormStructure,
} from "#src/components/form-builder/FormBuilderTemplates.js";

export const useHealthForm = (
  pinia,
  module,
  { onAfterSave, dense, autofocus, onlyActiveLine, titleId } = {}
) => {
  const { health } = storeToRefs(useInsuredStore(module, pinia));
  const { advanced_health, isDualInsured } = storeToRefs(useEappStore(pinia));

  const { ltcAvailable, available } = storeToRefs(
    useInstanceSettingsStore(pinia)
  );

  const { health: insuredHealth } = storeToRefs(usePrimaryInsuredStore(pinia));
  const { health: jointInsuredHealth } = storeToRefs(
    useJointInsuredStore(pinia)
  );

  const canToggleAdvancedHealth = computed(() => {
    if (dense) return false;
    const mayToggle = health => !health || health > 0;

    const insuredMayToggle = mayToggle(insuredHealth.value.model);
    if (!isDualInsured.value) return insuredMayToggle;

    const jointInsuredMayToggle = mayToggle(jointInsuredHealth.value.model);
    return insuredMayToggle && jointInsuredMayToggle;
  });

  const simpleHealthOptions = computed(() => {
    let options = ALL_OPTIONS;
    if (ltcAvailable.value.length === available.value.length) {
      options = LTC_ONLY_OPTIONS;
    } else if (!ltcAvailable.value.length) {
      options = LIFE_ONLY_OPTIONS;
    }
    return options;
  });

  function toggleAdvancedHealth() {
    advanced_health.value = !advanced_health.value;
    setTimeout(() => {
      health.value.load(null);
    }, 50);
  }

  const advancedHealthField = () => {
    return useInsuredAdvancedHealthProperty(pinia, module, {
      onAfterSave,
      mdColSize: 12,
      autofocus,
      onlyActiveLine,
      ariaLabelledBy: titleId,
    });
  };

  const healthField = () => {
    return useInsuredHealthRatingProperty(pinia, module, {
      onAfterSave,
      items: simpleHealthOptions.value,
      mdColSize: 12,
      autofocus,
      onlyActiveLine,
      ariaLabelledBy: `${module}-`,
    });
  };

  const toggleButton = (text, key) => {
    return FormButton(text, key, {
      class: "text-none",
      color: "primary",
      variant: "text",
      dataTestid: "advanced-toggle",
      onClick: toggleAdvancedHealth,
      mdColSize: 12,
    });
  };

  function buildStructure() {
    const fields = [];

    let toggleText;
    let toggleKey;

    if (advanced_health.value || dense) {
      toggleText = "Click for Simplified Health Classes";
      toggleKey = "simplified-health-toggle";
      fields.push(advancedHealthField());
    } else {
      toggleText = "Click for Advanced Health Classes";
      toggleKey = "advanced-health-toggle";
      fields.push(healthField());
    }

    if (canToggleAdvancedHealth.value) {
      fields.push(toggleButton(toggleText, toggleKey));
    }

    return [{ fields, key: `${module}-health-root` }];
  }

  function validate({ throwOnFailure = false } = {}) {
    return validateFormStructure(buildStructure(), throwOnFailure);
  }

  const reactiveDependencies = [advanced_health];

  return { buildStructure, validate, reactiveDependencies };
};
