import { steps } from "#src/enumerations/step-enumeration.js";
import { useStepNameStore } from "#src/stores/step-name.js";
import { useStepGenderStore } from "#src/stores/step-gender.js";
import { useStepHealthStore } from "#src/stores/step-health.js";
import { useStepSmokerStore } from "#src/stores/step-smoker.js";
import { useStepCompareStore } from "#src/stores/step-compare.js";
import { useStepAgentSignupStore } from "#src/stores/step-agent-signup.js";
import { useStepBirthdateStore } from "#src/stores/step-birthdate.js";
import { useStepBenefitEstimationStore } from "#src/stores/step-benefit-estimation.js";
import { useStepLifePriorityStore } from "#src/stores/step-life-priority.js";
import { useStepContactStore } from "#src/stores/step-contact.js";
import { useStepAddressStore } from "#src/stores/step-address.js";
import { useStepEmployerStore } from "#src/stores/step-employer.js";
import { useStepOwnerStore } from "#src/stores/step-owner.js";
import { useStepBeneficiaryPrimaryStore } from "#src/stores/step-beneficiary-primary.js";
import { useStepBeneficiaryContingentStore } from "#src/stores/step-beneficiary-contingent.js";
import { useStepPersonalStore } from "#src/stores/step-personal.js";
import { useStepExistingCoverageStore } from "#src/stores/step-existing-coverage.js";
import { useStepPhysicianStore } from "#src/stores/step-physician.js";
import { useStepUnderwritingStore } from "#src/stores/step-underwriting.js";
import { useStepPaymentStore } from "#src/stores/step-payment.js";
import { useStepExamSchedulingStore } from "#src/stores/step-exam-scheduling.js";
import { useStepSignatureWaitingStore } from "#src/stores/step-signature-waiting.js";
import { useStepSignatureStore } from "#src/stores/step-signature.js";
import { useStepCompletedStore } from "#src/stores/step-completed.js";
import { useStepBuildStore } from "#src/stores/step-build.js";
import { useStepQuoteStore } from "#src/stores/step-quote.js";
import { useStepDemoStore } from "#src/stores/step-demo.js";
import { useStepLoginStore } from "#src/stores/step-login.js";
import { useStepCalculatorStore } from "#src/stores/step-calculator.js";
import { useStepSecondaryAddresseeStore } from "#src/stores/step-secondary-addressee.js";
import { useStepCriticalFailure } from "#src/stores/step-critical-failure.js";
import { useStepReferralStore } from "#src/stores/step-referral.js";
import { useStepPreQuoteInformationStore } from "#src/stores/step-pre-quote-information.js";

export const getStepConfig = (step, pinia, hot = null) => {
  const store = {
    [steps.PRE_QUOTE_INFORMATION_STEP]: useStepPreQuoteInformationStore,
    [steps.BENEFIT_ESTIMATION]: useStepBenefitEstimationStore,
    [steps.LIFE_INSURANCE_PRIORITY]: useStepLifePriorityStore,
    [steps.CONTACT]: useStepContactStore,
    [steps.ADDRESS]: useStepAddressStore,
    [steps.EMPLOYER]: useStepEmployerStore,
    [steps.OWNER]: useStepOwnerStore,
    [steps.PRIMARY_BENEFICIARY]: useStepBeneficiaryPrimaryStore,
    [steps.CONTINGENT_BENEFICIARY]: useStepBeneficiaryContingentStore,
    [steps.PERSONAL]: useStepPersonalStore,
    [steps.EXISTING_COVERAGE]: useStepExistingCoverageStore,
    [steps.PHYSICIAN]: useStepPhysicianStore,
    [steps.UNDERWRITING]: useStepUnderwritingStore,
    [steps.PAYMENT]: useStepPaymentStore,
    [steps.EXAM_SCHEDULING]: useStepExamSchedulingStore,
    [steps.SIGNATURE_WAITING]: useStepSignatureWaitingStore,
    [steps.SIGNATURE]: useStepSignatureStore,
    [steps.SECONDARY_ADDRESSEE]: useStepSecondaryAddresseeStore,
    [steps.COMPLETED]: useStepCompletedStore,
    [steps.NAME]: useStepNameStore,
    [steps.GENDER]: useStepGenderStore,
    [steps.HEALTH]: useStepHealthStore,
    [steps.SMOKER]: useStepSmokerStore,
    [steps.BUILD]: useStepBuildStore,
    [steps.BIRTHDATE]: useStepBirthdateStore,
    [steps.REFERRAL]: useStepReferralStore,
    [steps.COMPARE]: useStepCompareStore,
    [steps.QUOTE]: useStepQuoteStore,
    [steps.AGENT_SIGN_UP]: useStepAgentSignupStore,
    [steps.DEMO]: useStepDemoStore,
    [steps.LOGIN]: useStepLoginStore,
    [steps.CALCULATOR]: useStepCalculatorStore,
    [steps.CRITICAL_FAILURE]: useStepCriticalFailure,
  }[step];

  if (store) return store(pinia, hot);
};
