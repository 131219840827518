import {
  JOINT_INSURED_PARTNER_DISCOUNT,
  MONTHLY_BENEFITS,
  MONTHLY_BENEFIT_MOST_COMMON,
  SPOUSE_PARTNER_DISCOUNT,
} from "#src/data/quoteParams.js";
import { closestCalc, isBoolean } from "#src/util/helpers.js";
import { insuredQuoteData } from "#src/structures/quote-params/quote-params-helpers.js";
import modes from "#src/data/modes.js";
import { PRODUCTS } from "#src/structures/ProductType.js";

export const BENEFIT_PERIOD_OPTIONS = (() => {
  const options = [];
  for (let i = 2; i <= 8; i++) {
    options.push({ value: i, text: `${i} Years` });
  }

  options.push({ value: -1, text: "Unlimited" });
  return options;
})();

export default function LinkedBenefit() {
  return {
    get type() {
      return PRODUCTS.LINKED_BENEFIT;
    },
    get isLinkedBenefit() {
      return true;
    },
    get display() {
      return "Linked Benefit";
    },
    get modes() {
      return modes;
    },
    get defaults() {
      return {
        solve: "Base Premium",
        categories: [this.requestCategory],
        monthly_benefit: MONTHLY_BENEFIT_MOST_COMMON,
        inflation_percentage: null,
        pay_duration: -1,
        benefit_period: 6,
        return_of_premium_rider: null,
        partner_discount: null,
        save_age: false,
      };
    },
    get requestCategory() {
      return "universal_life.linked_benefit";
    },
    get solves() {
      return [
        { value: "Base Premium", text: "Premium" },
        { value: "LTC Benefit", text: "LTC Benefit" },
      ];
    },
    get partnerDiscountOptions() {
      return [
        { text: "Single", value: null, requiresJointInfo: null },
        {
          text: "Married, Applying with Spouse",
          value: JOINT_INSURED_PARTNER_DISCOUNT,
          requiresJointInfo: "verbose",
        },
        {
          text: "Married, Applying Individually",
          value: SPOUSE_PARTNER_DISCOUNT,
          requiresJointInfo: null,
        },
      ];
    },
    get returnOfPremiumOptions() {
      return [
        { text: "None", value: null, subtitle: "" },
        {
          text: "One Time Step-Up",
          value: "One Time Step-Up",
          subtitle: "Increases the surrender value.",
        },
        {
          text: "Vested",
          value: "Vested",
          subtitle: "Increases the surrender value at the fastest rate.",
        },
      ];
    },
    get payDurationOptions() {
      return [
        { value: 1, title: "Single Pay" },
        { value: 5, title: "5 Pay" },
        { value: 10, title: "10 Pay" },
        { value: -65, title: "Pay to 65" },
        { value: -1, title: "Lifetime Pay" },
      ];
    },
    get inflationOptions() {
      return [
        {
          value: null,
          text: "None",
          decimalValue: 0,
        },
        {
          value: "3% Simple",
          text: "3% Simple",
          decimalValue: 0.03,
          simple: true,
        },
        {
          value: "3% Compound",
          text: "3% Compound",
          decimalValue: 0.03,
        },
        {
          value: "5% Compound",
          text: "5% Compound",
          decimalValue: 0.05,
        },
      ];
    },
    get benefitPeriodOptions() {
      return BENEFIT_PERIOD_OPTIONS;
    },
    showMonthlyLtcBenefit({ solve }) {
      return solve === "Base Premium";
    },
    showPremium({ solve }) {
      return solve === "LTC Benefit";
    },
    eAppParser(model = {}) {
      let solve = model?.solve;
      if (!this.solves.some(({ value }) => value === solve)) {
        solve = this.defaults.solve;
      }

      const monthly_benefit = closestCalc(
        model.monthly_benefit || this.defaults.monthly_benefit,
        MONTHLY_BENEFITS
      );

      let inflation_percentage = model?.inflation_percentage;
      if (
        !this.inflationOptions.some(
          ({ value }) => value === inflation_percentage
        )
      ) {
        inflation_percentage = this.defaults.inflation_percentage;
      }

      let pay_duration = model?.pay_duration;
      if (
        !this.payDurationOptions.some(({ value }) => pay_duration === value)
      ) {
        pay_duration = this.defaults.pay_duration;
      }

      let benefit_period = this.defaults.benefit_period;
      if (model?.benefit_period) {
        benefit_period = closestCalc(
          model.benefit_period,
          BENEFIT_PERIOD_OPTIONS.map(({ value }) => value)
        );
      }

      let return_of_premium_rider = model?.return_of_premium_rider;
      if (
        !this.returnOfPremiumOptions.some(
          ({ value }) => value === return_of_premium_rider
        )
      ) {
        return_of_premium_rider = this.defaults.return_of_premium_rider;
      }

      let partner_discount = model?.partner_discount;
      if (
        !this.partnerDiscountOptions.some(
          ({ value }) => value === partner_discount
        )
      ) {
        partner_discount = this.defaults.partner_discount;
      }

      let saveAge = model.save_age;
      if (!isBoolean(saveAge)) saveAge = this.defaults.save_age;
      return {
        solve,
        monthly_benefit,
        inflation_percentage,
        pay_duration,
        benefit_period,
        return_of_premium_rider,
        partner_discount,
        save_age: saveAge,
      };
    },
    showHealth(conversion) {
      return !conversion;
    },
    renderRules(params = {}, options = { conversion: false }) {
      const showHealth = this.showHealth(options.conversion);
      const jointInsuredHealth =
        params.partner_discount === JOINT_INSURED_PARTNER_DISCOUNT &&
        showHealth;

      return {
        saveAge: true,
        premium: this.showPremium(params),
        monthlyBenefit: this.showMonthlyLtcBenefit(params),
        inflationPercentage: true,
        benefitPeriod: true,
        payDuration: true,
        solve: true,
        partnerDiscount: true,
        returnOfPremium: true,
        insuredHealth: showHealth,
        jointInsuredHealth,
        mode: true,
      };
    },
    toQuoteRequest({ eAppParams = {}, insured, otherInsured, agentId }) {
      const paramObj = eAppParams;

      const monthlyBenefitOrPremium = {
        monthly_benefit: undefined,
        premium: undefined,
      };
      if (this.showMonthlyLtcBenefit(paramObj)) {
        monthlyBenefitOrPremium.monthly_benefit = paramObj.monthly_benefit;
      } else if (this.showPremium(paramObj)) {
        monthlyBenefitOrPremium.premium = paramObj.premium;
      }

      const body = {
        agent: { id: agentId },
        compare: false,

        ...insuredQuoteData(insured),

        selected_type: eAppParams.selected_type,
        state: eAppParams.state,
        mode: eAppParams.mode,
        categories: this.defaults.categories,

        save_age: paramObj.save_age,
        solve: paramObj.solve,
        inflation_percentage: paramObj.inflation_percentage,
        benefit_period: paramObj.benefit_period,
        pay_duration: paramObj.pay_duration,
        return_of_premium_rider: paramObj.return_of_premium_rider,
        partner_discount: paramObj.partner_discount,
        ...monthlyBenefitOrPremium,
      };

      if (paramObj.partner_discount === "both") {
        body.joint_party = insuredQuoteData(otherInsured);
        body.support_joint = true;
      }

      return body;
    },
  };
}
