import { useInsuredStore } from "#src/stores/insured.js";
import {
  useInsuredGenderButtonsProperty,
  useInsuredGenderProperty,
} from "#src/composables/insured-form-property.composable.js";
import { validateFormStructure } from "#src/components/form-builder/FormBuilderTemplates.js";
import { GENDER_ITEMS } from "#src/data/genders.js";
import { mdiAccount } from "@mdi/js";

export const useGenderForm = (
  pinia,
  module,
  { autofocus, onAfterSave, dense, titleId } = {}
) => {
  const genderField = () => {
    let property = useInsuredGenderButtonsProperty;
    if (dense) property = useInsuredGenderProperty;
    return property(pinia, module, {
      mdColSize: 12,
      justify: "center",
      autofocus,
      prependInnerIcon: mdiAccount,
      onChange() {
        const insured = useInsuredStore(module, pinia);

        if (insured.weight.model) return;
        insured.height.load(
          GENDER_ITEMS.find(g => g.value === insured.gender.model).defaultHeight
        );
      },
      onAfterSave,
      ariaLabelledBy: titleId,
    });
  };

  function buildStructure() {
    return [
      {
        key: `${module}-gender-root`,
        fields: [genderField()],
      },
    ];
  }

  function validate({ throwOnFailure = false } = {}) {
    return validateFormStructure(buildStructure(), throwOnFailure);
  }

  return { buildStructure, validate };
};
