<template>
  <v-row
    class="ma-0 flex-nowrap"
    :class="{ 'select-group': !onlyOneLine && showHealthInput }"
  >
    <div
      v-if="!onlyOneLine"
      :style="{ width: showHealthInput && !onlyOneLine ? '12rem' : '100%' }"
      style="transition: all 100ms linear"
      class="left"
    >
      <div>
        <v-select
          :id="`${props.dataTestid}-product-type`"
          v-model="line"
          aria-label="Product Type"
          label="Product Type"
          :data-testid="`${dataTestid}-product-type`"
          :items="lines"
          :autofocus="!line && props.autofocus"
          item-title="text"
          variant="outlined"
          density="compact"
          :style="
            showHealthInput
              ? 'border-top-right-radius: 0; border-bottom-right-radius: 0'
              : ''
          "
          :error-messages="errorMessages"
          :class="inputClasses"
          :base-color="baseColor"
          :color="color"
        />
      </div>
    </div>
    <v-fade-transition mode="out-in" class="w-100">
      <div>
        <v-select
          v-if="showHealthInput"
          :id="props.dataTestid"
          v-model="model"
          class="right w-100"
          :prepend-inner-icon="onlyOneLine ? mdiHeartPulse : null"
          :data-testid="dataTestid"
          :label="props.label"
          variant="outlined"
          density="compact"
          item-title="text"
          :autofocus="!model && props.autofocus"
          :items="healthClassItems"
          :style="
            onlyOneLine
              ? ''
              : 'border-top-left-radius:0; border-bottom-left-radius: 0'
          "
          :class="inputClasses"
          :base-color="baseColor"
          :color="color"
          :error-messages="errorMessages"
          @update:model-value="validateAndSave"
        >
          <template #append-inner>
            <active-save-indicator :controller="savingBuffer" />
          </template>
          <template v-if="$slots.message" #message>
            <slot name="message" />
          </template>
        </v-select>
      </div>
    </v-fade-transition>
  </v-row>
</template>

<script setup>
import ActiveSaveIndicator from "#src/components/shared/ActiveSaveIndicator.vue";

import {
  AVAILABLE_HEALTH_CLASSES,
  AVAILABLE_LTC_HEALTH_CLASSES,
} from "#src/data/healthTypes";
const LTC = "LTC";
const LIFE = "Life";
import { closestCalc } from "#src/util/helpers";
import { useFlowStore } from "#src/stores/flow";
import { useInstanceSettingsStore } from "#src/stores/instance-settings";
import { storeToRefs } from "pinia";
import { watch, computed, ref, inject } from "vue";
import {
  useInput,
  useInputProps,
} from "#src/composables/savable-input.composable";
import { mdiHeartPulse } from "@mdi/js";

const pinia = inject("pinia");
const props = defineProps({
  ...useInputProps(),
  onlyActiveLine: Boolean,
  autofocus: Boolean,
  label: {
    type: String,
    required: true,
  },
});

const instance = useInstanceSettingsStore(pinia);
const { isLife, isLongTermCare } = storeToRefs(useFlowStore(pinia));

const line = ref(null);
const lines = ref(getLines());

line.value = getDefaultLine();

const onlyOneLine = computed(() => lines.value.length === 1);
const showHealthInput = computed(() => Boolean(line.value));

const healthClassItems = computed(() => {
  let items = [];
  if (line.value === LIFE) items = AVAILABLE_HEALTH_CLASSES;
  else if (line.value === LTC) items = AVAILABLE_LTC_HEALTH_CLASSES;

  return items.map(({ value: v, text, range }) => {
    let value = v;
    if (range && range.includes(model.value)) value = model.value;
    return { value, text };
  });
});

watch([isLife, isLongTermCare], () => {
  if (isLife.value) line.value = LIFE;
  if (isLongTermCare.value) line.value = LTC;
  lines.value = getLines();
});

watch(line, () => {
  if (!model.value) return;
  model.value = closestCalc(
    model.value,
    healthClassItems.value.map(({ value }) => value)
  );
});

function getDefaultLine() {
  let defaultLine = LIFE;
  if (instance.ltcAvailable.length && isLongTermCare.value) return LTC;
  else if (instance.lifeAvailable.length) return LIFE;
  else if (instance.ltcAvailable.length) return LTC;
  return defaultLine;
}

function getLines() {
  if (props.onlyActiveLine) return [getDefaultLine()];

  const availableLines = [];
  if (instance.lifeAvailable.length) availableLines.push(LIFE);
  if (instance.ltcAvailable.length) availableLines.push(LTC);
  return availableLines;
}

const {
  model,
  inputClasses,
  color,
  baseColor,
  errorMessages,
  validateAndSave,
  savingBuffer,
} = useInput(props, pinia, 0);
</script>

<style lang="scss">
.select-group {
  .left {
    .v-field__outline__end {
      border-right-width: 0;
      border-radius: 0;
    }
  }

  .right {
    .v-field__outline__start {
      // border-left-width: 0;
      border-radius: 0;
    }
  }
}
</style>
