import { httpClient } from "#src/HttpClient";
import { useInstanceSettingsStore } from "#src/stores/instance-settings";

export class PingService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(this.#pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/ping`;
    this.#httpClient = httpClient(this.#pinia);
  }

  pingServer() {
    return this.#httpClient.post(this.#baseUrl);
  }
}
