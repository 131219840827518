import { parseErrorMessage } from "#src/util/helpers.js";
import { reactive, toRaw } from "vue";

export function useSavableProperty({
  rules,
  requestMap,
  group,
  requestFormatter,
  validationSideEffects = () => {},
} = {}) {
  return {
    success: null,
    errorMessages: [],
    dirty: false,
    validate(markDirty = false) {
      this.errorMessages.splice(0, this.errorMessages.length);

      Object.keys(rules).forEach(rule => {
        try {
          if (rules[rule].v()) return;

          let message = rules[rule].message;
          if (typeof message === "function") message = rules[rule].message();

          this.errorMessages.push(message);
          // eslint-disable-next-line no-unused-vars
        } catch (e) {
          this.errorMessages.push("error");
        }
      });

      if (this.errorMessage) {
        this.errorMessages.push(this.errorMessage);
      }

      this.success = !this.errorMessages.length;
      if (markDirty) this.dirty = true;
      return this.success === true;
    },
    requests: [],
    errorMessage: "",
    requestMap,
    group,
    format() {
      let reqValue = this.model;

      if (requestFormatter) reqValue = requestFormatter(this.model);

      return reqValue;
    },
    deleteRequest(uuid) {
      const index = this.requests.findIndex(({ id }) => id === uuid);
      if (index > -1) this.requests.splice(index, 1);
    },
    load(v) {
      this._externallyValid = true;
      this.model = v;
      this.dirty = false;
    },
    _internalValue: null,
    get model() {
      return this._internalValue;
    },
    set model(v) {
      this._internalValue = v;
      this.errorMessage = "";
      this.validate();
      validationSideEffects();
    },
    _externallyValid: null,
    get externallyValid() {
      return this._externallyValid;
    },
    set externallyValid(v) {
      this._externallyValid = v;
      this.validate();
    },
  };
}

// This is useful if we need the returnType of a savableProperty needs to line up with pinia's storeToRefs
//  -> pretty much any time we use it in a non pinia stateful fashion.

// should we always just return a reactive value (even in pinia stores)? why would we use reactive instead of nested refs?
export const useInComponentSavableProperty = args =>
  reactive(toRaw(useSavableProperty(args)));

export const savablePropertyRequestWrapper = async (
  func,
  { composableAttributes, body, uuid }
) => {
  composableAttributes.forEach(a => {
    a.requests.push({
      id: uuid,
      initiatedAt: new Date().getTime(),
      payload: JSON.stringify(body),
    });
  });
  let errorMessage, response, error;
  try {
    response = await func();
  } catch (e) {
    error = e;
    errorMessage = parseErrorMessage(e);
  } finally {
    composableAttributes.forEach(a => {
      const index = a.deleteRequest(uuid);
      if (index > -1) a.requests.splice(index, 1);
      if (errorMessage) a.errorMessage = errorMessage;

      a.externallyValid = !errorMessage;
      if (errorMessage) a.errorMessage = errorMessage;
      a.validate();
    });
  }

  if (error) throw error;
  return response;
};
