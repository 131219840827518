import { useStepBeneficiaryContingentStore } from "#src/stores/step-beneficiary-contingent.js";
import { useStepOwnerStore } from "#src/stores/step-owner.js";

import { steps, stepToRouteName } from "#src/enumerations/step-enumeration.js";
import { defineStore } from "#src/stores/state-wrapper.js";
import { REQS } from "#src/enumerations/data-requirements.js";

export const useStepBeneficiaryPrimaryStore = (pinia, hot) =>
  defineStore("step-beneficiary-primary", {
    state: () => ({
      inactive: false,
      requiredFields: {
        address: false,
        phone: false,
        tin: false,
        gender: false,
        formationDate: false,
        relationship: false,
        birthdate: false,
        ssn: false,
        birthdateOrSsn: false,
        ssnOptional: false,
        revocable: false,
        signerName: false,
        signerEmail: false,
      },
    }),
    getters: {
      step: () => steps.PRIMARY_BENEFICIARY,
      routeName: s => stepToRouteName[s.step],
      previous() {
        const owner = useStepOwnerStore(pinia);
        if (owner.inactive) return owner.previous;
        return owner.step;
      },
      next() {
        const contingent = useStepBeneficiaryContingentStore(pinia);
        if (contingent.inactive) return contingent.next;
        return contingent.step;
      },
    },
    actions: {
      setStepRequirements(srq) {
        this.requiredFields.address = srq[REQS.BENEFICIARY_ADDRESS];
        this.requiredFields.phone = srq[REQS.BENEFICIARY_PHONE];
        this.requiredFields.tin = srq[REQS.BENEFICIARY_TIN];
        this.requiredFields.formationDate =
          srq[REQS.BENEFICIARY_FORMATION_DATE];
        this.requiredFields.birthdateOrSsn =
          srq[REQS.BENEFICIARY_BIRTHDATE_OR_SSN];
        this.requiredFields.relationship = srq[REQS.BENEFICIARY_RELATIONSHIP];
        this.requiredFields.birthdate = srq[REQS.BENEFICIARY_BIRTHDATE];
        this.requiredFields.ssn = srq[REQS.BENEFICIARY_SSN];
        this.requiredFields.ssnOptional = srq[REQS.BENEFICIARY_SSN_OPTIONAL];
        this.requiredFields.gender = srq[REQS.BENEFICIARY_GENDER];
        this.requiredFields.signerName = srq[REQS.BENEFICIARY_SIGNER_NAME];
        this.requiredFields.signerEmail = srq[REQS.BENEFICIARY_SIGNER_EMAIL];
        this.requiredFields.revocable = srq[REQS.BENEFICIARY_REVOCABLE];

        this.inactive = !Object.values(this.requiredFields).includes(true);
      },
    },
  })(pinia, hot);
