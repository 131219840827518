<template>
  <div>
    <v-text-field
      :id="dataTestid"
      :ref="name"
      v-model="visibleSSN"
      :aria-label="$attrs?.label"
      autocomplete="false"
      data-lpignore="true"
      type="text"
      maxlength="11"
      inputmode="numeric"
      variant="outlined"
      density="compact"
      class="button-append-inner"
      placeholder="###-##-####"
      :data-testid="dataTestid"
      :prepend-inner-icon="icon"
      :autofocus="autofocus"
      :name="name"
      :label="label"
      v-bind="$attrs"
      :hint="hint"
      @keydown="keydown"
      @paste.prevent="handlePaste"
    >
      <template #append-inner>
        <slot name="append-inner" />
        <v-icon
          :icon="appendIcon"
          variant="text"
          color="inherit"
          role="button"
          aria-label="Toggle Visibility"
          data-testid="toggle-visibility"
          @click="showSSN = !showSSN"
        />
      </template>
      <template v-if="$slots.append" #append>
        <slot name="append" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { mdiAsterisk, mdiEye, mdiEyeOff } from "@mdi/js";
const PERMITTED_KEYCODES = {
  BACKSPACE: 8,
  ZERO: 48,
  ONE: 49,
  TWO: 50,
  THREE: 51,
  FOUR: 52,
  FIVE: 53,
  SIX: 54,
  SEVEN: 55,
  EIGHT: 56,
  NINE: 57,
  NUMPAD_0: 96,
  NUMPAD_1: 97,
  NUMPAD_2: 98,
  NUMPAD_3: 99,
  NUMPAD_4: 100,
  NUMPAD_5: 101,
  NUMPAD_6: 102,
  NUMPAD_7: 103,
  NUMPAD_8: 104,
  NUMPAD_9: 105,
};
const META_KEYCODES = {
  V: 86,
  TAB: 9,
};
export default {
  props: {
    dirty: Boolean,
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Social Security",
    },
    modelValue: {
      default: "",
      type: String,
    },
    dataTestid: {
      type: String,
      required: false,
      default: "ssn",
    },
    hint: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: mdiAsterisk,
    },
  },
  emits: ["update:modelValue"],
  data() {
    let visibleSSN = null;
    let hiddenSSN = null;
    if (this.modelValue === null) {
      visibleSSN = "";
      hiddenSSN = "";
    } else {
      const val = this.modelValue.replace(/-/g, "");
      visibleSSN = this.createStarsAndFormatSSN(val);
      hiddenSSN = val;
    }
    return {
      visibleSSN,
      hiddenSSN, // hidden ssn is the unformatted SSN for easy manip
      showSSN: false,
      timer: null,
      autofocus: false,
    };
  },
  computed: {
    appendIcon() {
      return this.showSSN ? mdiEye : mdiEyeOff;
    },
  },
  watch: {
    showSSN() {
      this.formatVisibleSsn();
    },
    hiddenSSN() {
      this.$emit("update:modelValue", this.formatSSN(this.hiddenSSN));
    },
  },
  methods: {
    createStarsAndFormatSSN(ssn) {
      return this.formatSSN(this.createStars(ssn.length));
    },
    formatSSN(ssn) {
      const currSSN = ssn.replace(/-/g, "");
      const part1 = currSSN.substring(0, 3);
      if (currSSN.length <= 3) return part1;
      const part2 = currSSN.substring(3, 5);
      if (currSSN.length <= 5) return `${part1}-${part2}`;
      const part3 = currSSN.substring(5, 9);
      return `${part1}-${part2}-${part3}`;
    },
    createStars(n) {
      return "X".repeat(n);
    },
    formatVisibleSsn() {
      if (!this.showSSN) {
        const stars = this.createStars(this.hiddenSSN.length);
        this.visibleSSN = this.formatSSN(stars);
        return;
      }

      this.visibleSSN = this.formatSSN(this.hiddenSSN);
    },

    handlePaste(e) {
      try {
        let clipboardData, pastedData;

        e.stopPropagation();
        e.preventDefault();

        clipboardData = e.clipboardData || window.clipboardData;
        pastedData = clipboardData.getData("Text");

        const val = pastedData.replace(/\D/g, "");
        this.hiddenSSN = val;
        this.formatVisibleSsn();
      } catch (error) {
        console.error(error);
      }
    },
    keydown(e) {
      const code = e.which;
      const ctrlDown = e.ctrlKey || e.metaKey;
      if (ctrlDown && code === META_KEYCODES.V) return;
      if (code === META_KEYCODES.TAB) return;
      e.stopPropagation();
      e.preventDefault();
      if (!Object.values(PERMITTED_KEYCODES).includes(code)) return;
      // only delete is permitted for the max ssn length
      let withoutDashes = this.visibleSSN.replace(/-/g, "");
      let length = withoutDashes.length;
      if (code !== PERMITTED_KEYCODES.BACKSPACE && length >= 9) return;

      if (this.timer) clearTimeout(this.timer);
      if (code === PERMITTED_KEYCODES.BACKSPACE) {
        this.hiddenSSN = this.hiddenSSN.slice(0, -1);
        this.formatVisibleSsn();
        return;
      }

      this.hiddenSSN += e.key;

      if (this.showSSN) {
        this.formatVisibleSsn();
        return;
      }

      withoutDashes = this.hiddenSSN.replace(/-/g, "");
      length = withoutDashes.length;

      this.visibleSSN = this.formatSSN(
        this.createStars(length - 1) + this.hiddenSSN.substring(length - 1)
      );

      this.timer = setTimeout(() => {
        this.formatVisibleSsn();
      }, 800);
    },
  },
};
</script>

<style lang="scss">
.button-append-inner {
  .v-field__append-inner {
    padding-top: 0 !important;
  }
}
</style>
