import { httpClient } from "#src/HttpClient.js";
import { useEappStore } from "#src/stores/electronic-application.js";
import { useInstanceSettingsStore } from "#src/stores/instance-settings.js";

export class HttpDoctorVisitsService {
  #pinia;
  #httpClient;
  #baseUrl;
  constructor(pinia) {
    this.#pinia = pinia;
    this.#httpClient = httpClient(pinia);

    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/doctor_visits`;
  }

  #attachEappData(params) {
    const { uuid, id } = useEappStore(this.#pinia);
    return {
      electronic_application_id: id,
      uuid: uuid,
      ...params,
    };
  }

  async createDoctorVisit(doctor_visit) {
    const body = this.#attachEappData({ doctor_visit });
    const { data } = await this.#httpClient.post(this.#baseUrl, body);
    return data.doctor_visit.id;
  }

  updateDoctorVisit(id, doctor_visit) {
    const body = this.#attachEappData({ doctor_visit });
    return this.#httpClient.put(`${this.#baseUrl}/${id}`, body);
  }

  deleteDoctorVisit(id) {
    const params = this.#attachEappData({});
    return this.#httpClient.delete(`${this.#baseUrl}/${id}`, { params });
  }
}
