import { generateUuid, getEnv } from "#src/util/helpers.js";

export class SocketService {
  #pinia;
  constructor(pinia) {
    this.#pinia = pinia;
  }

  async #getNewPusherInstance() {
    const env = getEnv();
    if (env.VITE_MOCK_PUSHER) {
      const pusherMock = await import("pusher-js-mock");
      return new pusherMock.PusherMock();
    }
    const Pusher = await import("pusher-js");
    return new Pusher.default(env.VITE_PUSHER_KEY, {
      enabledTransports: ["ws"],
      forceTLS: true,
      cluster: "mt1",
    });
  }

  async initSocketForQuotes(
    fetchQuotesHandler,
    {
      responseHandler = null,
      startHandler = null,
      finishHandler = null,
      hintHandler = null,
      endHandler = null,
      failHandler = null,
      subscriptionErrorHandler = null,
    } = {}
  ) {
    if (!fetchQuotesHandler) throw "a fetch quotes handler is required";
    let uuid = generateUuid();
    const env = getEnv();
    if (env.VITE_MOCK_PUSHER) {
      const testHelpers = await import("#src/stores/test-helpers.js");
      uuid = testHelpers.useTestHelpers(this.#pinia).pusherUuid;
    }
    const pusher = await this.#getNewPusherInstance();

    const channel = pusher.subscribe(`quote-and-apply@${uuid}`);
    channel.bind("pusher:subscription_succeeded", () => {
      fetchQuotesHandler(uuid);
      channel.unbind("pusher:subscription_succeeded");
    });
    if (responseHandler) channel.bind("quote:response", responseHandler);
    if (startHandler) channel.bind("quote:start", startHandler);
    if (finishHandler) channel.bind("quote:finish", finishHandler);
    if (hintHandler) channel.bind("quote:hint", hintHandler);
    if (endHandler) channel.bind("quote:end", endHandler);
    if (failHandler) channel.bind("quote:fail", failHandler);
    if (subscriptionErrorHandler) {
      channel.bind("pusher:subscription_error", subscriptionErrorHandler);
    }

    return pusher;
  }

  async initSocketForIllustration(
    fetchIllustration,
    eAppUuid,
    {
      startHandler = null,
      successHandler = null,
      errorHandler = null,
      endHandler = null,
    } = {}
  ) {
    if (!fetchIllustration) throw "a fetch illustration handler is required";

    const pusher = await this.#getNewPusherInstance();

    const channel = pusher.subscribe(`quote-and-apply@${eAppUuid}`);
    channel.bind("pusher:subscription_succeeded", data => {
      fetchIllustration(data);
      channel.unbind("pusher:subscription_succeeded");
    });

    if (startHandler) channel.bind("illustration:start", startHandler);
    if (successHandler) channel.bind("illustration:success", successHandler);
    if (errorHandler) channel.bind("illustration:error", errorHandler);
    if (endHandler) channel.bind("illustration:end", endHandler);

    return pusher;
  }

  async initSocketForSignup(
    npn,
    createUserHandler,
    { readyHandler = null } = {}
  ) {
    const pusher = await this.#getNewPusherInstance();

    const channel = pusher.subscribe(`npn-${npn}`);
    channel.bind("pusher:subscription_succeeded", data => {
      createUserHandler(data);
      channel.unbind("pusher:subscription_succeeded");
    });
    if (readyHandler) channel.bind("approved-domain-ready", readyHandler);

    return pusher;
  }

  async initSocketForEnvelope(
    uuid,
    startProcessHandler,
    { readyHandler = null, errorHandler = null, startHandler = null } = {}
  ) {
    const pusher = await this.#getNewPusherInstance();

    const channel = pusher.subscribe(`strife.envelope-${uuid}`);
    channel.bind("pusher:subscription_succeeded", data => {
      startProcessHandler(data);
      channel.unbind("pusher:subscription_succeeded");
    });
    if (startHandler) channel.bind("start", startHandler);
    if (readyHandler) channel.bind("ready", readyHandler);
    if (errorHandler) channel.bind("error", errorHandler);

    return pusher;
  }

  async initSocketForInsuredSigned(
    uuid,
    getEnvelopeHandler,
    { insuredSignedHandler, insuredDeclinedHandler }
  ) {
    const pusher = await this.#getNewPusherInstance();

    const channel = pusher.subscribe(`strife.insured_signed-${uuid}`);
    channel.bind("pusher:subscription_succeeded", data => {
      getEnvelopeHandler(data);
      channel.unbind("pusher:subscription_succeeded");
    });
    channel.bind("insured-signed", v => {
      if (v === "decline") return insuredDeclinedHandler(v);
      return insuredSignedHandler(v);
    });

    return pusher;
  }
}
