import { setAgentFromRequest, Agent } from "#src/structures/Agent.js";
import { PRODUCTS } from "#src/structures/ProductType.js";
import { setQuestionFromRaw } from "#src/structures/CustomQuestions.js";

import { boolOrNull, numOrNull, valOrNull } from "#src/util/helpers.js";

export function ApprovedDomain(model = {}) {
  return {
    error_message: {
      title: valOrNull(model?.error_message?.title),
      text: valOrNull(model?.error_message?.text),
    },
    requires_subscription: Boolean(model?.requires_subscription),
    active_first_input: boolOrNull(model?.active_first_input),
    agent: Agent(model?.agent),
    chat: Boolean(model?.chat),
    hide_test_mode_banner: boolOrNull(model?.hide_test_mode_banner),
    disclosure: valOrNull(model?.disclosure),
    domain_key: valOrNull(model?.domain_key),
    domain: valOrNull(model?.domain),
    email_capture: valOrNull(model?.email_capture),
    google_analytics_id: valOrNull(model?.google_analytics_id),
    google_tag_manager_id: valOrNull(model?.google_tag_manager_id),
    greeting: valOrNull(model?.greeting),
    id: numOrNull(model?.id),
    logo: valOrNull(model?.logo),
    market_as_agency: boolOrNull(model?.market_as_agency),
    override_phone: valOrNull(model?.override_phone),
    override_state: valOrNull(model?.override_state),
    phone: valOrNull(model?.phone),

    scheduling_link: valOrNull(model?.scheduling_link),
    start_page: valOrNull(model?.start_page),
    test: boolOrNull(model?.test),

    show_refer_text: boolOrNull(model?.show_refer_text),
    data_requirements: model?.data_requirements || [],
    round_robin_agents: model?.round_robin_agents || [],
    approved_domain_questions: model?.approved_domain_questions || [],

    commissions_paid_to: {
      id: numOrNull(model?.commissions_paid_to?.id),
      type: valOrNull(model?.commissions_paid_to?.type),
      name: valOrNull(model?.commissions_paid_to?.name),
      address: valOrNull(model?.commissions_paid_to?.address),
    },
    default_params: {
      mode: numOrNull(model?.default_params?.mode),
    },
    default_slider_values: {
      fex: numOrNull(model?.default_slider_values?.fex),
      permanent: numOrNull(model?.default_slider_values?.permanent),
      term: numOrNull(model?.default_slider_values?.term),
      accidental_death: numOrNull(
        model?.default_slider_values?.accidental_death
      ),
    },
    integrations: {
      cobrowse_io: {
        key: valOrNull(model?.integrations?.cobrowse_io?.key),
      },
      zoho_chat: {
        key: valOrNull(model?.integrations?.zoho_chat?.key),
        phone: valOrNull(model?.integrations?.zoho_chat?.phone),
      },
      zendesk_chat: {
        key: valOrNull(model?.integrations?.zendesk_chat?.key),
      },
    },
    theme: {
      type: model?.theme?.type || THEME_TYPES.BARE,
      hero_image_url: valOrNull(model?.theme?.hero_image_url),
      hide_additional_services: model?.theme?.hide_additional_services || false,
      light: {
        primary: model?.theme?.light?.primary || COLORS.LIGHT_PRIMARY,
        accent: model?.theme?.light?.accent || COLORS.LIGHT_ACCENT,
      },
      dark: {
        primary: model?.theme?.dark?.primary || COLORS.DARK_PRIMARY,
        accent: model?.theme?.dark?.accent || COLORS.DARK_ACCENT,
      },
    },
    products: ApprovedDomainProducts(model),
  };
}

export function setApprovedDomainFromRequest(raw = {}) {
  const approvedDomain = ApprovedDomain();
  if (!raw) return approvedDomain;

  approvedDomain.error_message.title = valOrNull(raw.error_message?.title);
  approvedDomain.error_message.text = valOrNull(raw.error_message?.text);

  approvedDomain.requires_subscription = Boolean(raw.requires_subscription);
  approvedDomain.active_first_input = Boolean(raw.active_first_input);
  approvedDomain.chat = Boolean(raw.chat);
  approvedDomain.hide_test_mode_banner = Boolean(raw.hide_test_mode_banner);
  approvedDomain.market_as_agency = Boolean(raw.market_as_agency);
  approvedDomain.test = Boolean(raw.test);

  approvedDomain.disclosure = valOrNull(raw.disclosure);
  approvedDomain.domain_key = valOrNull(raw.domain_key);
  approvedDomain.domain = valOrNull(raw.domain);
  approvedDomain.email_capture = valOrNull(raw.email_capture);
  approvedDomain.google_analytics_id = valOrNull(raw.google_analytics_id);
  approvedDomain.google_tag_manager_id = valOrNull(raw.google_tag_manager_id);
  approvedDomain.greeting = valOrNull(raw.greeting);
  approvedDomain.id = numOrNull(raw.id);
  approvedDomain.logo = valOrNull(raw.logo);
  approvedDomain.override_phone = valOrNull(raw.override_phone);
  approvedDomain.override_state = valOrNull(raw.override_state);
  approvedDomain.phone = valOrNull(raw.phone);
  approvedDomain.scheduling_link = valOrNull(raw.scheduling_link);
  approvedDomain.start_page = valOrNull(raw.start_page);
  approvedDomain.show_refer_text = boolOrNull(raw.show_refer_text);

  approvedDomain.commissions_paid_to.id = valOrNull(
    raw.commissions_paid_to?.id
  );
  approvedDomain.commissions_paid_to.type = valOrNull(
    raw.commissions_paid_to?.type
  );
  approvedDomain.commissions_paid_to.name = valOrNull(
    raw.commissions_paid_to?.name
  );
  approvedDomain.commissions_paid_to.address = valOrNull(
    raw.commissions_paid_to?.address
  );

  approvedDomain.default_params.mode = valOrNull(raw.default_params?.mode);

  approvedDomain.default_slider_values.fex = numOrNull(
    raw.default_slider_values?.fex
  );
  approvedDomain.default_slider_values.permanent = numOrNull(
    raw.default_slider_values?.permanent
  );
  approvedDomain.default_slider_values.term = numOrNull(
    raw.default_slider_values?.term
  );
  approvedDomain.default_slider_values.accidental_death = numOrNull(
    raw.default_slider_values?.accidental_death
  );

  if (raw.theme) approvedDomain.theme.type = raw.theme;
  if (raw.hero_image_url)
    approvedDomain.theme.hero_image_url = raw.hero_image_url;
  approvedDomain.theme.hide_additional_services = Boolean(
    raw.hide_additional_services
  );
  if (raw.color_primary) approvedDomain.theme.light.primary = raw.color_primary;
  if (raw.color_accent) approvedDomain.theme.light.accent = raw.color_accent;
  if (raw.color_dark_primary)
    approvedDomain.theme.dark.primary = raw.color_dark_primary;
  if (raw.color_dark_accent)
    approvedDomain.theme.dark.accent = raw.color_dark_accent;

  approvedDomain.agent = setAgentFromRequest(raw.agent);

  approvedDomain.products = setApprovedDomainProductsFromRequest(
    raw?.product_types
  );

  if (Array.isArray(raw.approved_domain_questions)) {
    approvedDomain.approved_domain_questions =
      raw.approved_domain_questions.map(setQuestionFromRaw);
  }

  if (Array.isArray(raw.round_robin_agents)) {
    approvedDomain.round_robin_agents = raw.round_robin_agents.map(agent => ({
      title: agent.name,
      value: agent.id,
    }));
  }

  if (Array.isArray(raw.data_requirements)) {
    approvedDomain.data_requirements = raw.data_requirements;
  }

  if (Array.isArray(raw.approved_domain_integrations)) {
    const cobrowse = raw.approved_domain_integrations.find(
      i => i.name === "Cobrowse.io"
    );
    if (cobrowse)
      approvedDomain.integrations.cobrowse_io.key = valOrNull(
        cobrowse.client_id
      );

    const zoho = raw.approved_domain_integrations.find(
      i => i.name === "Zoho SalesIQ"
    );
    if (zoho) {
      approvedDomain.integrations.zoho_chat.key = valOrNull(zoho.client_id);
      approvedDomain.integrations.zoho_chat.phone = valOrNull(zoho.phone);
    }

    const zendesk = raw.approved_domain_integrations.find(
      i => i.name === "Zendesk"
    );
    if (zendesk)
      approvedDomain.integrations.zendesk_chat.key = valOrNull(
        zendesk.client_id
      );

    if (zoho || zendesk) approvedDomain.chat = false; // stop double chat
  }

  return approvedDomain;
}

export function ApprovedDomainProducts(model = {}) {
  return {
    [PRODUCTS.INCOME_RIDER]: Boolean(model?.products?.[PRODUCTS.INCOME_RIDER]),
    [PRODUCTS.TERM]: Boolean(model?.products?.[PRODUCTS.TERM]),
    [PRODUCTS.ROP_TERM]: Boolean(model?.products?.[PRODUCTS.ROP_TERM]),
    [PRODUCTS.ACCIDENTAL_DEATH]: Boolean(
      model?.products?.[PRODUCTS.ACCIDENTAL_DEATH]
    ),
    [PRODUCTS.NON_MED]: Boolean(model?.products?.[PRODUCTS.NON_MED]),
    [PRODUCTS.FINAL_EXPENSE]: Boolean(
      model?.products?.[PRODUCTS.FINAL_EXPENSE]
    ),
    [PRODUCTS.ACCUMULATION_IUL]: Boolean(
      model?.products?.[PRODUCTS.ACCUMULATION_IUL]
    ),
    [PRODUCTS.UNIVERSAL_LIFE]: Boolean(
      model?.products?.[PRODUCTS.UNIVERSAL_LIFE]
    ),
    [PRODUCTS.PROTECTION_IUL]: Boolean(
      model?.products?.[PRODUCTS.PROTECTION_IUL]
    ),
    [PRODUCTS.PROTECTION_VUL]: Boolean(
      model?.products?.[PRODUCTS.PROTECTION_VUL]
    ),
    [PRODUCTS.WHOLE_LIFE]: Boolean(model?.products?.[PRODUCTS.WHOLE_LIFE]),
    [PRODUCTS.LTC]: Boolean(model?.products?.[PRODUCTS.LTC]),
    [PRODUCTS.LINKED_BENEFIT]: Boolean(
      model?.products?.[PRODUCTS.LINKED_BENEFIT]
    ),
  };
}

export function setApprovedDomainProductsFromRequest(raw = {}) {
  const products = ApprovedDomainProducts();

  products[PRODUCTS.TERM] = Boolean(raw?.term);
  products[PRODUCTS.ROP_TERM] = Boolean(raw?.rop_term);
  products[PRODUCTS.ACCIDENTAL_DEATH] = Boolean(raw?.accidental_death);
  products[PRODUCTS.NON_MED] = Boolean(raw?.non_med);
  products[PRODUCTS.FINAL_EXPENSE] = Boolean(raw?.fex);
  products[PRODUCTS.ACCUMULATION_IUL] = Boolean(raw?.iul);
  products[PRODUCTS.UNIVERSAL_LIFE] = Boolean(raw?.ul);
  products[PRODUCTS.PROTECTION_IUL] = Boolean(raw?.piul);
  products[PRODUCTS.PROTECTION_VUL] = Boolean(raw?.pvul);
  products[PRODUCTS.WHOLE_LIFE] = Boolean(raw?.whole_life);
  products[PRODUCTS.LTC] = Boolean(raw?.ltc);
  products[PRODUCTS.LINKED_BENEFIT] = Boolean(raw?.linked_benefit);
  products[PRODUCTS.INCOME_RIDER] = Boolean(raw?.income_rider);

  return products;
}

export const THEME_TYPES = {
  BARE: "bare",
  GRADIENT_LIFE: "gradient-life",
  LTC: "ltc",
  LIFE_ESSENTIALS: "life-essentials",
};

export const COLORS = {
  LIGHT_PRIMARY: "#1871c9",
  LIGHT_ACCENT: "#28a746",
  DARK_PRIMARY: "#4188d9",
  DARK_ACCENT: "#28a746",
};
