import { useSavableProperty } from "#src/composables/savable-property.composable.js";
import { defineStore } from "#src/stores/state-wrapper.js";
import { QuestionsService } from "#src/services/questions.service.js";
import {
  isNotDefined,
  parseErrorMessage,
  generateUuid,
} from "#src/util/helpers.js";

export const underwritingQuestionNameGenerator = id =>
  `underwriting-question-${id}`;

const checkboxValidator = question => {
  if (!question.answer.model?.length) return false;
  return question.answer.model.every(v => {
    return question.items.some(({ value }) => value === v);
  });
};

const defaultValidator = question => {
  if (question.items?.length) {
    return question.items.some(v => v.value === question.answer.model);
  }
  return ![undefined, null].includes(question.answer.model);
};

export const UNDERWRITING_QUESTION_TYPES = {
  CHECKBOX: "checkbox",
  SELECT: "select",
  DATE: "date",
  TEXT: "text",
  RADIO: "radio",
  INTEGER: "integer",
};

const UNDERWRITING_RADIO_OPTIONS = [
  { title: "Yes", value: "1" },
  { title: "No", value: "0" },
];

const mapTextToTitle = options =>
  options.map(({ text, value }) => ({
    title: text,
    value,
  }));

export function useUnderwritingQuestion(id, pinia, hot) {
  const questionsService = new QuestionsService(pinia);
  return defineStore(id, {
    state: () => ({
      id: null,
      questionId: null,
      placeholder: null,
      items: null,
      parentId: null,
      question: null,
      label: null,
      inputLabel: null,
      requiredParentValue: null,
      requiredValue: null,
      type: null,

      answer: useSavableProperty({
        requestMap: "answer",
        group: "root",
        rules: {
          inList: {
            v: () => {
              const question = useUnderwritingQuestion(id, pinia);
              if (question.type === UNDERWRITING_QUESTION_TYPES.CHECKBOX)
                return checkboxValidator(question);
              return defaultValidator(question);
            },
            message: "Required",
          },
          isRequiredValue: {
            v: () => {
              const question = useUnderwritingQuestion(id, pinia);
              if (question.requiredValue === null) return true;
              return question.answer.model === question.requiredValue;
            },
            message: "Required",
          },
        },
      }),
    }),
    actions: {
      setFromRequest(question, { parentId }) {
        this.type = question.field_type;
        this.label = question.verbatim_question;
        this.questionId = question.id;
        this.id = id;
        this.requiredParentValue = question.required_parent_value;
        this.requiredValue = question.required_value;
        this.parentId = parentId;

        let answer = question.answer.answer;
        let inputLabel = question.field_label || "Please explain";
        if (this.type === UNDERWRITING_QUESTION_TYPES.CHECKBOX) {
          answer = question.answer2 || [];
          inputLabel = null;
          this.items = mapTextToTitle(question.field_options);
        } else if (this.type === UNDERWRITING_QUESTION_TYPES.RADIO) {
          inputLabel = null;
          this.items = UNDERWRITING_RADIO_OPTIONS;
        } else if (this.type === UNDERWRITING_QUESTION_TYPES.SELECT) {
          inputLabel = question.field_label || "Please select";
          this.items = mapTextToTitle(question.field_options);
        } else if (this.type === UNDERWRITING_QUESTION_TYPES.INTEGER) {
          inputLabel = question.field_label || "Enter a Number";
        }

        if (this.type === UNDERWRITING_QUESTION_TYPES.CHECKBOX) {
          this.answer.load(answer);
          if (Array.isArray(answer) && answer.length) {
            this.answer.validate(true);
          }
        } else if (!isNotDefined(answer)) {
          this.answer.load(answer);
          this.answer.validate(true);
        }

        this.inputLabel = inputLabel;
      },
      // only answer is supported
      async saveAttributes() {
        const value = this.answer.format();
        const reqUuid = generateUuid();
        this.answer.requests.push({
          id: reqUuid,
          initiatedAt: new Date().getTime(),
          payload: JSON.stringify(value),
        });
        let errorMessage;
        try {
          await questionsService.updateUnderwritingQuestion(
            this.questionId,
            value
          );
        } catch (e) {
          errorMessage = parseErrorMessage(e);
        } finally {
          this.answer.deleteRequest(reqUuid);
          if (errorMessage) this.answer.errorMessage = errorMessage;
        }
      },
    },
  })(pinia, hot);
}
