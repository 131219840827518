import { defineStore } from "#src/stores/state-wrapper.js";
import { REQS } from "#src/enumerations/data-requirements.js";
import { steps, stepToRouteName } from "#src/enumerations/step-enumeration.js";

import { useStepSmokerStore } from "#src/stores/step-smoker.js";
import { useStepGenderStore } from "#src/stores/step-gender.js";
import { useHealthForm } from "#src/components/steps/health/health-form.composable.js";

export const useStepHealthStore = (pinia, hot) =>
  defineStore("step-health", {
    state: () => ({ inactive: false }),
    getters: {
      step: () => steps.HEALTH,
      routeName: s => stepToRouteName[s.step],
      next: () => {
        const smoker = useStepSmokerStore(pinia);
        if (smoker.inactive) return smoker.next;
        return smoker.step;
      },
      previous: () => {
        const gender = useStepGenderStore(pinia);
        if (gender.inactive) return gender.previous;
        return gender.step;
      },
    },
    actions: {
      setStepRequirements(srq) {
        this.inactive = !srq[REQS.HEALTH];
      },
      generateForm(module, args) {
        return useHealthForm(pinia, module, args);
      },
    },
  })(pinia, hot);
