import { httpClient } from "#src/HttpClient.js";
import { useEappStore } from "#src/stores/electronic-application.js";
import { useInstanceSettingsStore } from "#src/stores/instance-settings.js";
import {
  underwritingQuestionNameGenerator,
  useUnderwritingQuestion,
} from "#src/stores/underwriting-question.js";

export class QuestionsService {
  #pinia;
  #baseUrl;
  #httpClient;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/questions`;
    this.#httpClient = httpClient(pinia);
  }

  #attachEappData(params) {
    const { id, uuid } = useEappStore(this.#pinia);
    return {
      electronic_application_id: id,
      uuid: uuid,
      ...params,
    };
  }

  updateUnderwritingQuestion(questionId, answer) {
    const params = this.#attachEappData({ answer });
    return this.#httpClient.put(`${this.#baseUrl}/${questionId}`, params);
  }

  async getUnderwritingQuestions() {
    const { id, uuid } = useEappStore(this.#pinia);

    const params = {
      electronic_application_id: id,
      uuid,
    };
    const { data } = await this.#httpClient.get(this.#baseUrl, { params });
    const insuredQuestions = [];
    const jointInsuredQuestions = [];
    data.questions.forEach(q => {
      if (q.insured_index === 0) insuredQuestions.push(q);
      if (q.insured_index === 1) jointInsuredQuestions.push(q);
    });

    return {
      insuredQuestions: this.#generateFlatUnderwritingList(insuredQuestions),
      jointInsuredQuestions: this.#generateFlatUnderwritingList(
        jointInsuredQuestions
      ),
    };
  }

  async getTiaUnderwritingQuestions() {
    const { id, uuid } = useEappStore(this.#pinia);
    const params = {
      electronic_application_id: id,
      uuid,
      tia: true,
    };

    const { data } = await this.#httpClient.get(this.#baseUrl, {
      params,
    });

    const { questions, is_eligible } = data;
    const jointInsuredQuestions =
      questions?.filter(v => v.insured_index === 1) || [];
    const insuredQuestions =
      questions?.filter(v => v.insured_index === 0) || questions || [];
    return {
      jointInsuredQuestions: this.#generateFlatUnderwritingList(
        jointInsuredQuestions
      ),
      insuredQuestions: this.#generateFlatUnderwritingList(insuredQuestions),
      canUseTia: Boolean(is_eligible),
    };
  }

  #generateFlatUnderwritingList(questions) {
    const accumulator = [];
    const addToObj = (values, parentId = null) => {
      values.forEach(question => {
        const id = underwritingQuestionNameGenerator(question.id);
        useUnderwritingQuestion(id).setFromRequest(question, { parentId });
        accumulator.push(id);
        if (question.children?.length) {
          addToObj(question.children, id);
        }
      });
    };

    addToObj(questions);

    return accumulator;
  }
}
