import { steps, stepToRouteName } from "#src/enumerations/step-enumeration.js";
import { defineStore } from "#src/stores/state-wrapper.js";
import { REQS } from "#src/enumerations/data-requirements.js";

import { useStepBuildStore } from "#src/stores/step-build.js";
import { useStepHealthStore } from "#src/stores/step-health.js";
import { useSmokerForm } from "#src/components/steps/smoker/smoker-form.composable.js";

export const useStepSmokerStore = (pinia, hot) =>
  defineStore("step-smoker", {
    getters: {
      step: () => steps.SMOKER,
      routeName: s => stepToRouteName[s.step],
      next: () => {
        const build = useStepBuildStore(pinia);
        if (build.inactive) return build.next;
        return build.step;
      },
      previous: () => {
        const health = useStepHealthStore(pinia);
        if (health.inactive) return health.previous;
        return health.step;
      },
    },
    actions: {
      setStepRequirements(srq) {
        this.inactive = !srq[REQS.SMOKER];
      },
      generateForm(module, args) {
        return useSmokerForm(pinia, module, args);
      },
    },
  })(pinia, hot);
