import { createSSRApp } from "vue";
import { createRouter } from "#src/plugins/router.mjs";
import { init } from "@sentry/vue";

import vuetify from "#src/plugins/vuetify.mjs";

import "#src/styles/main.scss";
import { useInstanceSettingsStore } from "#src/stores/instance-settings.js";
import { useEappStore } from "#src/stores/electronic-application.js";
import { useCaseStore } from "#src/stores/case.js";
import supportedBrowsers from "#src/util/supported-browsers.js";
export function createApp(RootComponent, pinia, slug, nonce) {
  const app = createSSRApp(RootComponent, { name: "QuoteAndApply" });

  app.use(pinia);
  app.provide("pinia", pinia);

  let appNonce = nonce;
  let appSlug = slug;
  let resumeFn = null;

  let instance;
  if (import.meta.env.SSR) {
    instance = useInstanceSettingsStore(pinia);
    instance.nonce = appNonce;
  } else {
    init({
      app,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      beforeSend(event) {
        const isModernBrowser = supportedBrowsers.test(navigator.userAgent);
        if (!isModernBrowser) return null;
        return event;
      },
    });

    pinia.state.value = window.__PINIA_STATE__ || {};

    // we need instance settings here so that the store may initialize with the pinia's hydrate method
    const eApp = useEappStore(pinia);
    const url = new URL(location);
    if (
      url.hash &&
      url.searchParams.get("eapp_id") &&
      url.searchParams.get("eapp_uuid")
    ) {
      const resumeToken = location.hash.replace("#resume=", "");
      history.pushState("", document.title, url.pathname + url.search);
      resumeFn = () =>
        new Promise(res => {
          eApp
            .resumeEApp(
              resumeToken,
              url.searchParams.get("eapp_id"),
              url.searchParams.get("eapp_uuid")
            )
            .then(() => res())
            .catch(() => res());
        });
    }

    instance = useInstanceSettingsStore(pinia);
    const prefillWithEapp = instance.queryPrefillForValue(
      v => v.eapp_id && v.eapp_uuid
    );
    if (prefillWithEapp) {
      eApp.id = prefillWithEapp.eapp_id;
      eApp.uuid = prefillWithEapp.eapp_uuid;
    }

    if (!eApp.id && !eApp.uuid) {
      try {
        eApp.id = sessionStorage.getItem("eapp_id");
        eApp.uuid = sessionStorage.getItem("eapp_uuid");
        // eslint-disable-next-line no-unused-vars
      } catch (e) {
        // do nothing
      }
    }
    const prefillWithCase = instance.queryPrefillForValue(
      v => v.case_id && v.case_uuid
    );
    if (prefillWithCase) {
      const caseStore = useCaseStore(pinia);
      caseStore.id = prefillWithCase.case_id;
      caseStore.uuid = prefillWithCase.case_uuid;
    }

    if (!instance.parent_url) instance.parent_url = window.location.href;
    appNonce = instance.nonce;
    appSlug = instance.app_slug;
  }

  app.use(
    vuetify(appNonce, {
      light: instance.theme.light,
      dark: instance.theme.dark,
    })
  );
  const router = createRouter(appSlug);
  app.use(router);

  return { app, router, pinia, resumeFn };
}
