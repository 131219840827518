import { httpClient } from "#src/HttpClient.js";
import { useInstanceSettingsStore } from "#src/stores/instance-settings.js";

export class CasesService {
  #baseUrl;
  #httpClient;
  #pinia;

  constructor(pinia) {
    this.#pinia = pinia;
    const instance = useInstanceSettingsStore(this.#pinia);
    this.#baseUrl = `${instance.api_url}/strife/v1/cases`;
    this.#httpClient = httpClient(this.#pinia);
  }

  async getCase({ id, uuid, birthdate }) {
    const params = new URLSearchParams();
    params.append("id", id);
    params.append("uuid", uuid);
    params.append("birthdate", birthdate);
    const response = await this.#httpClient.get(`${this.#baseUrl}/${id}`, {
      params,
    });
    return response.data.case;
  }
}
