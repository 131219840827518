import axios from "axios";
import { useInstanceSettingsStore } from "#src/stores/instance-settings.js";

export const httpClient = pinia => {
  const instance = useInstanceSettingsStore(pinia);
  const $httpClient = axios.create({
    baseURL: `${instance.api_url}/strife/v1`,
  });

  $httpClient.interceptors.response.use(
    response => {
      // uncomment to debug responses
      // console.log(response.data);
      return response;
    },
    error => {
      // Fairway returned an error
      if (!error.status && error.message === "Network Error") {
        throw "Please check your internet connection and try again.";
      }
      throw error;
    }
  );

  $httpClient.interceptors.request.use(config => {
    // uncomment to debug requests
    // console.log(config.data);
    const instance = useInstanceSettingsStore(pinia);
    if (instance.prefilledNpn) {
      config.headers["X-STRIFE-ID"] = instance.prefilledNpn;
    }

    if (instance.domain_key) {
      config.headers["X-STRIFE-KEY"] = instance.domain_key;
    }

    if (instance.api_key) {
      config.headers["X-BACKNINE-AUTHENTICATION"] = instance.api_key;
    }

    if (instance.activity_id) {
      config.headers["X-QNA-ACTIVITY-ID"] = instance.activity_id;
    }

    return config;
  });

  return $httpClient;
};
