<template>
  <buttons-input
    v-bind="props"
    v-model="model"
    :saving="savingBuffer.saving"
    :error-messages="errorMessages"
    @update:model-value="debounceAndValidate"
    @click="debounceAndValidate"
  />
</template>

<script setup>
import ButtonsInput from "#src/components/inputs/ButtonsInput.vue";
import {
  useInput,
  useInputProps,
} from "#src/composables/savable-input.composable";
import { inject } from "vue";
const pinia = inject("pinia");
const props = defineProps({
  ...useInputProps(),
  disabled: Boolean,
  ignoreChangelessClick: Boolean,
  label: {
    type: String,
    required: false,
    default: "",
  },
  items: {
    type: Array,
    required: true,
  },
  justify: {
    type: String,
    default: "start",
  },
  size: {
    type: String,
    default: "default",
  },
  ariaLabelledBy: {
    type: String,
    required: false,
    default: null,
  },
});

const { model, errorMessages, savingBuffer, debounceAndValidate } = useInput(
  props,
  pinia,
  10
);
</script>
