import { createVuetify } from "vuetify";
import { mdi, aliases } from "vuetify/iconsets/mdi-svg";
import "vuetify/styles";

export default (cspNonce, { light, dark } = {}) =>
  createVuetify({
    ssr: true,
    theme: {
      cspNonce,
      defaultTheme: "light",
      variations: {
        colors: ["primary", "section", "accent"],
        darken: 2,
        lighten: 1,
      },
      themes: {
        light: {
          dark: false,
          colors: {
            primary: light?.primary || "#1871c9",
            accent: light?.accent || "#28a746",
            success: "#28a746",
            error: "#ff5252",
            info: "#2196f3",

            // Layout colors
            surface: "#ffffff",
            section: "#f8f8f8",
            background: "#ffffff",
            "app-bars": "#f5f5f5",

            // Basic input (no data filled)
            basic: "#464646",
            "basic-icon": "#7f7f7f",

            // button-specific
            female: "#d11a58",
            male: "#2f71aa",
            previously: "#9d571b",
            "vitality-none": "#f3f3f3",
            "vitality-bronze": "#cd8c32",
            "vitality-silver": "#a4a4a4",
            "vitality-gold": "#daa520",
            "vitality-platinum": "#e5e4e2",
          },
        },
        dark: {
          dark: true,
          colors: {
            primary: dark?.primary || "#4188d9",
            accent: dark?.accent || "#28a746",
            success: "#4caf50",
            error: "#ff5252",
            info: "#17a2b8",

            // Layout Colors
            section: "#2d2d2d",
            surface: "#121212",
            background: "#121212",
            "app-bars": "#2d2d2d",

            // Basic input (no data filled)
            basic: "#767676",
            "basic-icon": "#464646",

            // Button Specific
            female: "#e81d62",
            male: "#337ab7",
            previously: "#9d571b",
            "vitality-none": "#f3f3f3",
            "vitality-bronze": "#cd8c32",
            "vitality-silver": "#a4a4a4",
            "vitality-gold": "#daa520",
            "vitality-platinum": "#e5e4e2",
          },
        },
      },
    },
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        mdi,
      },
    },
  });
