import { STATES } from "#src/data/states-and-countries.js";
import unemploymentReasons from "#src/data/unemploymentReasons.js";
import { useSavableProperty } from "#src/composables/savable-property.composable.js";
import {
  validateBoolean,
  validateText,
} from "#src/composables/savable-property-validators.mjs";

import {
  dateToYears,
  parseErrorMessage,
  generateUuid,
  yearsToDate,
} from "#src/util/helpers.js";
import { defineStore } from "#src/stores/state-wrapper.js";
import { HttpIndividualsService } from "#src/services/http-individuals.service.js";

import { useInsuredStore } from "#src/stores/insured.js";

export function useInsuredOccupationStore(insuredType, pinia, hot) {
  const individualService = new HttpIndividualsService(pinia);
  return defineStore(`${insuredType}-occupation`, {
    state: () => ({
      occupation_id: null,
      employer_name: useSavableProperty({
        requestMap: "name",
        group: "employer",
        rules: {
          validLength: validateText(
            () =>
              useInsuredOccupationStore(insuredType, pinia).employer_name.model,
            { minLength: 1, maxLength: 100 }
          ),
        },
      }),
      employer_street_address: useSavableProperty({
        requestMap: "street_address",
        group: "employer_address",
        context: () => useInsuredOccupationStore(insuredType, pinia),
        rules: {
          validLength: validateText(
            () =>
              useInsuredOccupationStore(insuredType, pinia)
                .employer_street_address.model,
            { minLength: 1, maxLength: 255 }
          ),
        },
      }),
      employer_city: useSavableProperty({
        requestMap: "city",
        group: "employer_address",
        rules: {
          validLength: validateText(
            () =>
              useInsuredOccupationStore(insuredType, pinia).employer_city.model,
            { minLength: 1, maxLength: 255 }
          ),
        },
      }),
      employer_state: useSavableProperty({
        requestMap: "state",
        group: "employer_address",
        rules: {
          inList: {
            v: () => {
              const store = useInsuredOccupationStore(insuredType, pinia);
              return STATES.some(
                ({ value }) => value === store.employer_state.model
              );
            },
            message: "Must be in list",
          },
        },
      }),
      employer_zip: useSavableProperty({
        requestMap: "zip",
        group: "employer_address",
        rules: {
          validLength: validateText(
            () =>
              useInsuredOccupationStore(insuredType, pinia).employer_zip.model,
            { exactLength: 5 }
          ),
        },
      }),
      is_employed: useSavableProperty({
        rules: {
          isTrueOrFalse: validateBoolean(
            () =>
              useInsuredOccupationStore(insuredType, pinia).is_employed.model
          ),
        },
      }),
      occupation_name: useSavableProperty({
        requestMap: "occupation",
        group: "occupation",
        rules: {
          validLength: validateText(
            () =>
              useInsuredOccupationStore(insuredType, pinia).occupation_name
                .model,
            { minLength: 1, maxLength: 255 }
          ),
          inList: {
            v: () => {
              const store = useInsuredOccupationStore(insuredType, pinia);
              if (store.is_employed) return true;
              return unemploymentReasons.includes(store.occupation_name.model);
            },
            message: "Must be a valid unemployment reason",
          },
        },
      }),
      occupation_years: useSavableProperty({
        requestMap: "start_date",
        group: "occupation",
        requestFormatter: yearsToDate,
        rules: {
          isNumber: {
            v: () => {
              const store = useInsuredOccupationStore(insuredType, pinia);
              return (
                !isNaN(store.occupation_years.model) &&
                ![null, undefined].includes(store.occupation_years.model)
              );
            },
            message: "Must be a number",
          },
          isAtLeastZero: {
            v: () => {
              const store = useInsuredOccupationStore(insuredType, pinia);
              return store.occupation_years.model >= 0;
            },
            message: "Must be at least 0",
          },
          isLessThanAge: {
            v: () => {
              const store = useInsuredOccupationStore(insuredType, pinia);
              const insured = useInsuredStore(insuredType, pinia);
              return store.occupation_years.model <= insured.age;
            },
            message: () => {
              const store = useInsuredStore(insuredType, pinia);
              return `Cannot be greater than ${store.age}`;
            },
          },
        },
      }),
    }),
    actions: {
      setFromEApp(occupation) {
        if (!occupation) return;
        if (occupation.occupation) {
          this.is_employed.load(
            !unemploymentReasons.some(v => v === occupation.occupation)
          );
        } else if (occupation?.employer?.name) {
          this.is_employed.load(true);
        }

        this.occupation_id = occupation?.id;
        if (occupation?.occupation) {
          this.occupation_name.load(occupation.occupation);
        }
        if (occupation?.start_date) {
          this.occupation_years.load(dateToYears(occupation.start_date));
        }

        if (!occupation.employer) return;

        const employer = occupation.employer;
        if (employer?.name) {
          this.employer_name.load(employer.name);
        }

        const eAddress = employer?.business_address;
        if (eAddress?.street_address) {
          this.employer_street_address.load(eAddress.street_address);
        }
        if (eAddress?.city) {
          this.employer_city.load(eAddress.city);
        }
        if (eAddress?.state) {
          this.employer_state.load(eAddress.state);
        }
        if (eAddress?.zip) {
          this.employer_zip.load(eAddress.zip);
        }
      },
      async saveAttributes(attributes) {
        const reqObj = {};

        const reqUuid = generateUuid();
        attributes.forEach(a => {
          const key = this[a].requestMap;
          const group = this[a].group;
          const value = this[a].format();

          switch (group) {
            case "occupation":
              if (!reqObj.occupation) reqObj.occupation = {};
              reqObj.occupation[key] = value;
              break;
            case "employer":
              if (!reqObj.employer) reqObj.employer = {};
              reqObj.employer[key] = value;
              break;
            case "employer_address":
              if (!reqObj.employer) reqObj.employer = {};
              if (!reqObj.employer.address) reqObj.employer.address = {};
              reqObj.employer.address[key] = value;
              break;
          }

          this[a].requests.push({
            id: reqUuid,
            initiatedAt: new Date().getTime(),
            payload: JSON.stringify(reqObj),
          });
        });

        if (!Object.keys(reqObj).length) return;
        let error, errorMessage;
        try {
          const insured = useInsuredStore(insuredType, pinia);
          const id = await individualService.occupationUpdate(
            insured.id,
            reqObj
          );
          this.occupation_id = id;
        } catch (e) {
          error = e;
          errorMessage = parseErrorMessage(e);
        } finally {
          attributes.forEach(a => {
            this[a].deleteRequest(reqUuid);

            if (errorMessage) this[a].errorMessage = errorMessage;
          });
        }

        if (error) throw error;
      },

      async deleteOccupation() {
        if (!this.occupation_id) return;

        this.occupation_id = null;
        const insured = useInsuredStore(insuredType, pinia);
        await individualService.occupationDelete(insured.id);
      },
    },
  })(pinia, hot);
}
