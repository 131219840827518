<template>
  <v-row
    :justify="center ? 'center' : 'start'"
    align="center"
    dense
    class="ma-0 pa-3"
  >
    <v-row class="app-item-max-width" dense>
      <v-col cols="12" class="reg-text">{{ message }}</v-col>
      <v-col cols="12">
        <v-progress-linear
          v-if="buffer"
          key="buffer"
          :model-value="modelValue"
          stream
          rounded
          :buffer-value="modelValue"
          height="6"
          class="mt-2"
          color="primary"
          :aria-label="message"
        />
        <v-progress-linear
          v-else
          key="indeterminate"
          indeterminate
          rounded
          class="mt-2"
          color="primary"
          height="6"
          :aria-label="message"
        />
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
export default {
  name: "LoadingIndicator",
  props: {
    buffer: Boolean,
    message: {
      type: String,
      default: "",
    },
    center: Boolean,
    modelValue: {
      type: Number,
      default: null,
    },
  },
};
</script>
