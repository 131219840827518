<template>
  <v-row
    :id="`${props.dataTestid}-group`"
    class="mx-0 mt-n1 mb-4"
    :justify="props.dense ? 'start' : 'center'"
    align="center"
    role="radiogroup"
    :aria-labelledby="props.ariaLabelledBy"
  >
    <v-btn
      v-for="(item, index) in ratingItems"
      :key="index"
      class="health-button"
      variant="text"
      size="large"
      v-bind="item"
      style="font-size: 32px"
    />
  </v-row>
</template>

<script setup>
import { ref, watch, toRefs, computed } from "vue";
import { mdiHeartOutline, mdiHeart } from "@mdi/js";
const emit = defineEmits(["click", "update:modelValue"]);
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  emptyIcon: {
    type: String,
    default: mdiHeartOutline,
  },
  fullIcon: {
    type: String,
    default: mdiHeart,
  },
  dataTestid: {
    type: String,
    default: "health",
  },
  modelValue: {
    type: Number,
    default: 0,
  },
  dense: Boolean,
  error: Boolean,
  ariaLabelledBy: { type: String, required: true },
});

const { modelValue } = toRefs(props);

const model = ref(modelValue.value);
const currentFocusIndex = ref(0);

const ratingItems = computed(() =>
  props.items.map((i, index) => {
    const classes = props.error ? "text-error" : "";
    const shouldShowFilled = currentFocusIndex.value >= index;

    const selectedIndex = props.items.findIndex(i => i.value === model.value);

    let tabindex;
    if (selectedIndex === -1) tabindex = index === 0 ? 0 : -1;
    else tabindex = selectedIndex === index ? 0 : -1;

    return {
      "aria-label": i.title,
      tabindex,
      role: "radio",
      "aria-checked": model.value === i.value,

      class: classes,
      title: i.text,
      icon: shouldShowFilled ? props.fullIcon : props.emptyIcon,
      color: shouldShowFilled ? "red" : "basic-icon",
      "data-testid": `${props.dataTestid}-${i.value}`,
      onClick: () => selectValue(index),
      onMouseover: () => setHoverIndex(index),
      onMouseleave: () => unsetHoverIndex(index),
      onFocus: () => setHoverIndex(index),
      onKeydown: e => handleKeydown(index, e),
      onBlur: () => unsetHoverIndex(null),
    };
  })
);

function selectValue(index) {
  model.value = props.items[index].value;
  emit("click");
}

function setHoverIndex(el) {
  currentFocusIndex.value = el;
}

function unsetHoverIndex(index) {
  if (modelItemIndex.value || modelItemIndex.value === 0)
    currentFocusIndex.value = modelItemIndex.value;
  else currentFocusIndex.value = index;
}

const modelItemIndex = computed(() =>
  props.items.findIndex(i => i.value === model.value)
);

function handleKeydown(index, e) {
  let focusOn = null;
  if (["Enter", " "].includes(e.key)) return selectValue(index);
  else if (["ArrowRight", "ArrowDown"].includes(e.key)) {
    focusOn = Math.min(currentFocusIndex.value + 1, props.items.length - 1);
  } else if (["ArrowLeft", "ArrowUp"].includes(e.key)) {
    focusOn = Math.max(currentFocusIndex.value - 1, 0);
  }

  if (focusOn !== null) {
    const el = document.querySelector(
      `[data-testid="${ratingItems.value[focusOn]["data-testid"]}"]`
    );
    if (el) el.focus();
  }
}

if (modelItemIndex.value || modelItemIndex.value === 0) {
  setHoverIndex(modelItemIndex.value);
}

watch(model, v => emit("update:modelValue", v));
watch(modelValue, v => {
  if (model.value !== v) model.value = v;
});
</script>

<style lang="scss">
.health-button:hover {
  opacity: unset;
}
</style>
