<template>
  <div class="white-space-prewrap">
    <template v-for="(t, index) in splitText" :key="index">
      <a v-if="t.isLink" :href="t.text" target="_blank">{{ t.text }}</a>
      <a v-else-if="t.isEmail" :href="`mailto:${t.text}`">{{ t.text }}</a>
      <span
        v-else
        :class="{ 'opacity-80': !darker, 'opacity-100': fullOpacity }"
        >{{ t.text }}</span
      >
    </template>
  </div>
</template>

<script setup>
import { computed, toRef } from "vue";

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  darker: Boolean,
  fullOpacity: Boolean,
});

const text = toRef(props, "text");

const urlRegex = new RegExp(/(https:\/\/[^\s"]+)/g);
const emailRegex = new RegExp(
  /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g
);

const combinedRegex = new RegExp(
  /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b|(https:\/\/[^\s"]+)/g
);

const splitText = computed(() => {
  const result = text.value
    .replace(combinedRegex, "|||$&|||")
    .split("|||")
    .filter(Boolean);

  return result.map(t => {
    return {
      isLink: urlRegex.test(t),
      isEmail: emailRegex.test(t),
      text: t,
    };
  });
});
</script>
