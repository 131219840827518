import { validateFormStructure } from "#src/components/form-builder/FormBuilderTemplates.js";
import {
  useInsuredFirstNameProperty,
  useInsuredLastNameProperty,
} from "#src/composables/insured-form-property.composable.js";
import { useInsuredStore } from "#src/stores/insured.js";
import { mdiAccount } from "@mdi/js";
import { storeToRefs } from "pinia";

export const useNameForm = (pinia, module, { autofocus } = {}) => {
  const { first_name } = storeToRefs(useInsuredStore(module, pinia));

  const firstNameField = () => {
    return useInsuredFirstNameProperty(pinia, module, {
      prependInnerIcon: mdiAccount,
      autofocus: autofocus && !first_name.value.model,
      mdColSize: 6,
    });
  };

  const lastNameField = () => {
    return useInsuredLastNameProperty(pinia, module, {
      prependInnerIcon: mdiAccount,
      mdColSize: 6,
    });
  };

  function buildStructure() {
    return [
      {
        fields: [firstNameField(), lastNameField()],
        key: `${module}-name-root`,
      },
    ];
  }

  function validate({ throwOnFailure = false } = {}) {
    return validateFormStructure(buildStructure(), throwOnFailure);
  }

  return { buildStructure, validate };
};
