import { steps, stepToRouteName } from "#src/enumerations/step-enumeration.js";
import { defineStore } from "#src/stores/state-wrapper.js";

import { useFlowStore } from "#src/stores/flow.js";
import { useStepNameStore } from "#src/stores/step-name.js";

export const useStepCalculatorStore = (pinia, hot) =>
  defineStore("step-calculator", {
    state: () => ({ inactive: false }),
    getters: {
      step: () => steps.CALCULATOR,
      routeName: s => stepToRouteName[s.step],
      next: () => {
        const name = useStepNameStore(pinia);
        return name.step;
      },
      previous: () => null,
      hidesFooter: () => true,
      controlsNext: () => true,
      hidesHeader: () => true,
    },
    actions: {
      setStepRequirements() {
        const flow = useFlowStore(pinia);
        this.inactive = !flow.initialStepIsCalculator;
      },
    },
  })(pinia, hot);
