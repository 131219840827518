import { useContactForm } from "#src/components/steps/contact/contact-form.composable.mjs";
import { REQS } from "#src/enumerations/data-requirements.js";
import { steps, stepToRouteName } from "#src/enumerations/step-enumeration.js";
import { defineStore } from "#src/stores/state-wrapper.js";
import { useStepAddressStore } from "#src/stores/step-address.js";
import { useStepQuoteStore } from "#src/stores/step-quote.js";

export const useStepContactStore = (pinia, hot) =>
  defineStore("step-contact", {
    state: () => ({
      requiredFields: {
        name: false,
        sendClientIntroMessage: false,
      },
    }),
    getters: {
      step: () => steps.CONTACT,
      routeName: s => stepToRouteName[s.step],
      next: () => {
        const address = useStepAddressStore(pinia);
        if (address.inactive) return address.next;
        return address.step;
      },
      previous: () => {
        const quote = useStepQuoteStore(pinia);
        return quote.step;
      },
    },
    actions: {
      generateForm(module, args) {
        return useContactForm(pinia, module, args);
      },
      setStepRequirements(srq) {
        this.requiredFields.name = srq[REQS.INSURED_NAME];
        this.requiredFields.sendClientIntroMessage =
          srq[REQS.SEND_CLIENT_INTRO_MESSAGE];
      },
    },
  })(pinia, hot);
