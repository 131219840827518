export const MODES = {
  ANNUALLY: 1,
  QUARTERLY: 4,
  SEMI_ANNUALLY: 2,
  MONTHLY: 12,
};

export const SUPPORTED_MODES = Object.values(MODES);

export default [
  {
    value: MODES.MONTHLY,
    title: "Paying Monthly",
  },
  {
    value: MODES.QUARTERLY,
    title: "Paying Quarterly",
  },
  {
    value: MODES.SEMI_ANNUALLY,
    title: "Paying Semi-Annually",
  },
  {
    value: MODES.ANNUALLY,
    title: "Paying Annually",
  },
];
