<template>
  <v-alert v-bind="props.alertProps">
    <v-checkbox
      v-model="model"
      class="checkbox-opacity partial-opacity checkbox-label"
      density="compact"
      hide-details="auto"
      :color="color"
      :base-color="baseColor"
      :error-messages="errorMessages"
      :data-testid="props.dataTestid"
      v-bind="$attrs"
      @update:model-value="validateAndSave"
    >
      <template #append-inner>
        <active-save-indicator :controller="savingBuffer" />
      </template>
    </v-checkbox>
  </v-alert>
</template>

<script setup>
import ActiveSaveIndicator from "#src/components/shared/ActiveSaveIndicator.vue";
import {
  useInput,
  useInputProps,
} from "#src/composables/savable-input.composable";
import { inject } from "vue";
const pinia = inject("pinia");
const props = defineProps({
  ...useInputProps(),
  alertProps: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const {
  model,
  errorMessages,
  savingBuffer,
  validateAndSave,
  color,
  baseColor,
} = useInput(props, pinia, 0);
</script>
