<template>
  <div>
    <v-select
      :id="props.dataTestid"
      v-model="model"
      data-lpignore="true"
      variant="outlined"
      density="compact"
      :aria-label="$attrs?.label"
      :items="props.items"
      :class="inputClasses"
      :color="color"
      :base-color="baseColor"
      :error-messages="errorMessages"
      :data-testid="props.dataTestid"
      v-bind="$attrs"
      @update:model-value="validateAndSave"
    >
      <template #append-inner>
        <active-save-indicator :controller="savingBuffer" />
      </template>
      <template v-if="$slots.message" #message>
        <slot name="message" />
      </template>
    </v-select>
  </div>
</template>

<script setup>
import ActiveSaveIndicator from "#src/components/shared/ActiveSaveIndicator.vue";
import {
  useInput,
  useInputProps,
} from "#src/composables/savable-input.composable";
import { inject } from "vue";
const pinia = inject("pinia");
const props = defineProps({
  ...useInputProps(),
  items: {
    type: Array,
    required: true,
  },
});

const {
  model,
  inputClasses,
  color,
  baseColor,
  errorMessages,
  savingBuffer,
  validateAndSave,
} = useInput(props, pinia, 0);
</script>
