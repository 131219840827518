<template>
  <step-layout show-logo>
    <template #subtitle>
      <span class="text-center" :class="{ 'text-error': error }">
        {{ subtitleText }}
      </span>
    </template>
    <template #body>
      <v-form @submit.prevent="submitLogin">
        <v-row align="center" justify="center">
          <v-col cols="8" class="app-item-max-width">
            <date-input
              v-model="birthdate"
              label="Birthdate"
              name="birthdate"
              autocomplete="off"
              placeholder="mm/dd/yyyy"
              variant="outlined"
              density="compact"
              data-testid="birthdate-input"
              :prepend-inner-icon="mdiCalendar"
              :autofocus="instance.activeFirstInput"
              :class="{
                'success-field': birthdateValidation.success,
                'error-field': birthdateValidation.errorMessages.length,
              }"
              :base-color="birthdateValidation.success ? 'success' : 'basic'"
              :color="birthdateValidation.success ? 'success' : 'primary'"
              :error-messages="birthdateValidation.errorMessages"
              @update:model-value="handleBirthdateInput"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <v-row>
        <v-col align="center" justify="center">
          <v-btn
            rounded
            color="accent"
            type="button"
            class="text-none"
            variant="elevated"
            style="width: 7rem"
            :disabled="loading"
            :loading="loading"
            data-testid="login"
            @click="submitLogin"
          >
            <v-icon color="white" :icon="mdiLogin" /> Login
          </v-btn>
          <v-btn
            rounded
            text
            data-testid="start-over"
            class="text-none"
            role="button"
            tabindex="-1"
            @click="cancelLogin"
          >
            <v-icon :icon="mdiRestart" /> Start Over
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </step-layout>
</template>

<script setup>
import DateInput from "#src/components/inputs/DateInput.vue";
import StepLayout from "#src/components/shared/StepLayout.vue";

import { parse } from "date-fns/parse";
import { isValid } from "date-fns/isValid";
import { parseErrorMessage } from "#src/util/helpers";
import { mdiCalendar, mdiLogin, mdiRestart } from "@mdi/js";
import { ref, computed, inject } from "vue";
import { steps } from "#src/enumerations/step-enumeration";

import { useInstanceSettingsStore } from "#src/stores/instance-settings";
import { useCaseStore } from "#src/stores/case";
import { useEappStore } from "#src/stores/electronic-application";
import { useDialogStore } from "#src/stores/dialog";

const emit = defineEmits(["reset-app", "go-to-active-step"]);

const pinia = inject("pinia");

const instance = useInstanceSettingsStore(pinia);
const caseStore = useCaseStore(pinia);
const eAppStore = useEappStore(pinia);
const dialog = useDialogStore(pinia);

const birthdate = ref(null);
const dirty = ref(false);
const loading = ref(false);
const error = ref(null);

const subtitleText = computed(() => {
  if (caseStore.id) {
    if (loading.value) {
      return "Please wait while we fetch the insured's application status.";
    }
    return "Enter the insured's birthdate to view their application status.";
  } else {
    if (loading.value) return "Please wait while we fetch your application.";
    return "Enter the insured's birthdate to continue their application.";
  }
});

const birthdateValidation = computed(() => {
  try {
    const errorMessages = [];
    if (dirty.value) {
      const valid = isValid(parse(birthdate.value, "yyyy-MM-dd", new Date()));
      if (!valid) errorMessages.push("Invalid Date");
      if (error.value) errorMessages.push(error.value);
    }

    const success = !errorMessages.length && !error.value && birthdate.value;
    return { success, errorMessages };
  } catch (e) {
    return { success: false, errorMessages: ["Invalid Date"] };
  }
});

function handleBirthdateInput() {
  dirty.value = false;
  error.value = null;
}

function cancelLogin() {
  emit("reset-app", true);
}
async function submitLogin() {
  dirty.value = true;
  const isValid = [birthdateValidation.value].every(
    v => v.success && !v.errorMessages.length
  );

  if (!isValid || loading.value) return;

  error.value = null;
  loading.value = true;

  try {
    // Automatically redirects via flow's navToStep
    if (caseStore.id) await caseStore.loginToCase(birthdate.value);
    else {
      const eApp = await eAppStore.fetchEapp({ birthdate: birthdate.value });
      //Advisor use only prevents clients from filling out the application past the quote step

      const pastQuoteStep = eApp.step > steps.QUOTE;
      if (eAppStore.isAdvisorUseOnly && pastQuoteStep) {
        const { isAgent } = await dialog.showConfirmUserIsAgentDialog();
        if (!isAgent)
          throw "You must be an insurance professional to proceed with this application";
      }
      emit("go-to-active-step", eApp.step);
    }
  } catch (e) {
    console.error(e);
    //if first option ~ something happens in setting is broken
    let errorMessage = "There has been an error loading your application";
    if (typeof e === "string") {
      errorMessage = e;
    } else if (e?.data?.error) {
      errorMessage = e.data.error;
    } else if (e?.response?.data) {
      errorMessage = parseErrorMessage(e);
    }
    error.value = errorMessage;
    loading.value = false;
  }
}
function focus() {
  document.querySelector('[data-testid="birthdate-input"] input')?.focus();
}
defineExpose({ focus });
</script>
