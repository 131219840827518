import { PRODUCTS, CONCEPTS } from "#src/structures/ProductType.js";
import {
  CREDITING_RATES,
  LOAN_TYPES,
  MAX_INCOME_AGE,
  RIDER_OPTIONS,
  RIDER_PERCENTAGE_OPTIONS,
  VITALITY_LEVELS,
  WITHOUT_CHRONIC_LTC,
  WITH_CHRONIC_ILLNESS_RIDER,
  WITH_CHRONIC_ILLNESS_LTC_RIDER,
  WITH_FULL_CHRONIC_ILLNESS_LTC_RIDER,
  WITH_LTC_RIDER,
} from "#src/data/quoteParams.js";
import { insuredQuoteData } from "#src/structures/quote-params/quote-params-helpers.js";
import { closestCalc, dateToYears, isBoolean } from "#src/util/helpers.js";
import { BENEFIT_PERIOD_OPTIONS } from "#src/structures/quote-params/LinkedBenefit.js";
import modes from "#src/data/modes.js";

export const LAPSE_PROTECTION_PREMIUM = "Lapse Protection Premium";
export const MIN_NON_MEC_DEATH_BENEFIT = "Min Non-MEC DB";
export const MAX_NON_MEC_PREMIUM = "Maximum Non-MEC Premium";
export const TARGET_DEATH_BENEFIT = "Target Death Benefit";
export const DEATH_BENEFIT_SOLVE_CASH_VALUE_AT_AGE =
  "DB Solve Cash Value at Age";
export const SPECIFY_PREMIUM_AND_DEATH_BENEFIT =
  "Specify Premium and Death Benefit";
export const TARGET_PREMIUM = "Target Premium";
export const PREMIUM_SOLVE_CASH_VALUE_AT_AGE =
  "Premium Solve Cash Value at Age";
export const LAPSE_PROTECTION_DEATH_BENEFIT = "Lapse Protection Death Benefit";

const DB_MIN_NON_MEC_ITEM = {
  value: MIN_NON_MEC_DEATH_BENEFIT,
  text: "Min Non-MEC Death Benefit",
  display: "initial_death_benefit",
};
const DB_TARGET_ITEM = {
  value: TARGET_DEATH_BENEFIT,
  text: "Target Death Benefit",
  display: "initial_death_benefit",
};
const DB_CASH_AT_AGE_ITEM = {
  value: DEATH_BENEFIT_SOLVE_CASH_VALUE_AT_AGE,
  text: "Death Benefit Solve Cash Value at Age",
  display: "initial_death_benefit",
};
const DB_LAPSE_PROTECTION_ITEM = {
  value: LAPSE_PROTECTION_DEATH_BENEFIT,
  text: "Lapse Protection Death Benefit",
  display: "initial_death_benefit",
};
const NO_SOLVE_ITEM = {
  value: SPECIFY_PREMIUM_AND_DEATH_BENEFIT,
  text: "No Solve",
};
const PREMIUM_MAX_NON_MEC_ITEM = {
  value: MAX_NON_MEC_PREMIUM,
  text: "Max Non-MEC Premium",
};
const PREMIUM_TARGET_ITEM = { value: TARGET_PREMIUM, text: "Target Premium" };
const PREMIUM_CASH_AT_AGE_ITEM = {
  value: PREMIUM_SOLVE_CASH_VALUE_AT_AGE,
  text: "Premium Solve Cash Value at Age",
};
const PREMIUM_LAPSE_PROTECTION_ITEM = {
  value: LAPSE_PROTECTION_PREMIUM,
  text: LAPSE_PROTECTION_PREMIUM,
};
// Utilized by PIUL, PVUL, UL, AIUL
function BaseUniversalLife(type) {
  const requestCategories = {
    [PRODUCTS.ACCUMULATION_IUL]: "universal_life.indexed.accumulation",
    [PRODUCTS.PROTECTION_IUL]: "universal_life.indexed.protection",
    [PRODUCTS.PROTECTION_VUL]: "universal_life.protection.variable",
    [PRODUCTS.UNIVERSAL_LIFE]: "universal_life.none",
  };

  const requestCategory = requestCategories[type];
  const fetchableTypes = [];
  const categories = [];
  if (requestCategory) {
    categories.push(requestCategory);
    fetchableTypes.push(type);
  }

  const displays = {
    [PRODUCTS.PROTECTION_IUL]: "Protection IUL",
    [PRODUCTS.PROTECTION_VUL]: "Protection VUL",
    [PRODUCTS.ACCUMULATION_IUL]: "Accumulation IUL",
    [PRODUCTS.UNIVERSAL_LIFE]: "Universal Life",
    [CONCEPTS.ALL_UL]: "All Universal Life",
  };

  const display = displays[type];

  const AIUL_DEFAULTS = {
    solve: MAX_NON_MEC_PREMIUM,
    pay_duration: -1,
    partner_discount: null,
    cash_value: 1,
    age_value: 120,
    lapse_protection_to_age: 100,
    crediting_rate: "Default Rate",
    filter_chronic_illness_and_ltc: WITHOUT_CHRONIC_LTC,
    ltc_rider_percentage: 4,
    income_solve: "Max",
    income_start_age: 66,
    income_end_age: 90,
    loan_type: "Fixed",
    vitality_level: "Bronze",
    save_age: false,
  };
  const AIUL_SOLVES = {
    death_benefit_solves: [
      DB_MIN_NON_MEC_ITEM,
      DB_TARGET_ITEM,
      DB_CASH_AT_AGE_ITEM,
    ],
    no_solves: [NO_SOLVE_ITEM],
    premium_solves: [
      PREMIUM_MAX_NON_MEC_ITEM,
      PREMIUM_TARGET_ITEM,
      PREMIUM_CASH_AT_AGE_ITEM,
    ],
  };
  const PIUL_DEFAULTS = {
    solve: PREMIUM_SOLVE_CASH_VALUE_AT_AGE,
    pay_duration: -1,
    partner_discount: null,
    cash_value: 1,
    age_value: 120,
    lapse_protection_to_age: 100,
    crediting_rate: "Default Rate",
    filter_chronic_illness_and_ltc: WITHOUT_CHRONIC_LTC,
    ltc_rider_percentage: 4,
    income_solve: "No",
    income_start_age: 66,
    income_end_age: 90,
    loan_type: "Fixed",
    vitality_level: "Bronze",
    save_age: false,
  };
  const PIUL_SOLVES = {
    death_benefit_solves: [
      DB_MIN_NON_MEC_ITEM,
      DB_TARGET_ITEM,
      DB_CASH_AT_AGE_ITEM,
      DB_LAPSE_PROTECTION_ITEM,
    ],
    no_solves: [NO_SOLVE_ITEM],
    premium_solves: [
      PREMIUM_MAX_NON_MEC_ITEM,
      PREMIUM_TARGET_ITEM,
      PREMIUM_CASH_AT_AGE_ITEM,
      PREMIUM_LAPSE_PROTECTION_ITEM,
    ],
  };
  const PVUL_DEFAULTS = {
    solve: LAPSE_PROTECTION_PREMIUM,
    pay_duration: -1,
    partner_discount: null,
    cash_value: 1,
    age_value: 120,
    lapse_protection_to_age: 100,
    crediting_rate: "Default Rate",
    filter_chronic_illness_and_ltc: WITHOUT_CHRONIC_LTC,
    ltc_rider_percentage: 4,
    income_solve: "No",
    income_start_age: 66,
    income_end_age: 90,
    loan_type: "Fixed",
    vitality_level: "Bronze",
    save_age: false,
  };
  const PVUL_SOLVES = {
    death_benefit_solves: [
      DB_MIN_NON_MEC_ITEM,
      DB_TARGET_ITEM,
      DB_CASH_AT_AGE_ITEM,
      DB_LAPSE_PROTECTION_ITEM,
    ],
    no_solves: [NO_SOLVE_ITEM],
    premium_solves: [
      PREMIUM_MAX_NON_MEC_ITEM,
      PREMIUM_TARGET_ITEM,
      PREMIUM_CASH_AT_AGE_ITEM,
      PREMIUM_LAPSE_PROTECTION_ITEM,
    ],
  };
  const UL_DEFAULTS = {
    solve: PREMIUM_SOLVE_CASH_VALUE_AT_AGE,
    pay_duration: -1,
    partner_discount: null,
    cash_value: 1,
    age_value: 120,
    lapse_protection_to_age: 120,
    crediting_rate: "Default Rate",
    filter_chronic_illness_and_ltc: WITHOUT_CHRONIC_LTC,
    ltc_rider_percentage: 4,
    income_solve: "No",
    income_start_age: 66,
    income_end_age: 90,
    loan_type: "Fixed",
    vitality_level: "Bronze",
    save_age: false,
  };

  const UL_SOLVES = {
    death_benefit_solves: [
      DB_MIN_NON_MEC_ITEM,
      DB_CASH_AT_AGE_ITEM,
      DB_LAPSE_PROTECTION_ITEM,
    ],
    no_solves: [NO_SOLVE_ITEM],
    premium_solves: [
      PREMIUM_MAX_NON_MEC_ITEM,
      PREMIUM_TARGET_ITEM,
      PREMIUM_CASH_AT_AGE_ITEM,
      PREMIUM_LAPSE_PROTECTION_ITEM,
    ],
  };

  //these are needed when "All" subtype is selected
  const ALL_DEFAULTS = {
    solve: PREMIUM_SOLVE_CASH_VALUE_AT_AGE,
    pay_duration: -1,
    partner_discount: null,
    cash_value: 1,
    age_value: 120,
    lapse_protection_to_age: 120,
    crediting_rate: "Default Rate",
    filter_chronic_illness_and_ltc: WITHOUT_CHRONIC_LTC,
    ltc_rider_percentage: 4,
    income_solve: "No",
    income_start_age: 66,
    income_end_age: 90,
    loan_type: "Fixed",
    vitality_level: "Bronze",
    subtype: null,
    save_age: false,
  };
  const ALL_SOLVES = {
    death_benefit_solves: [
      DB_MIN_NON_MEC_ITEM,
      DB_TARGET_ITEM,
      DB_CASH_AT_AGE_ITEM,
      DB_LAPSE_PROTECTION_ITEM,
    ],
    no_solves: [NO_SOLVE_ITEM],
    premium_solves: [
      PREMIUM_MAX_NON_MEC_ITEM,
      PREMIUM_TARGET_ITEM,
      PREMIUM_CASH_AT_AGE_ITEM,
      PREMIUM_LAPSE_PROTECTION_ITEM,
    ],
  };

  const defaults = {
    [PRODUCTS.ACCUMULATION_IUL]: AIUL_DEFAULTS,
    [PRODUCTS.PROTECTION_IUL]: PIUL_DEFAULTS,
    [PRODUCTS.PROTECTION_VUL]: PVUL_DEFAULTS,
    [PRODUCTS.UNIVERSAL_LIFE]: UL_DEFAULTS,
    [CONCEPTS.ALL_UL]: ALL_DEFAULTS,
  }[type];

  const solves = {
    [PRODUCTS.ACCUMULATION_IUL]: AIUL_SOLVES,
    [PRODUCTS.PROTECTION_IUL]: PIUL_SOLVES,
    [PRODUCTS.PROTECTION_VUL]: PVUL_SOLVES,
    [PRODUCTS.UNIVERSAL_LIFE]: UL_SOLVES,
    [CONCEPTS.ALL_UL]: ALL_SOLVES,
  }[type];

  return {
    get type() {
      return CONCEPTS.ALL_UL;
    },
    get subtype() {
      if (type === CONCEPTS.ALL_UL) return null;
      return type;
    },
    fetchableTypes,
    categories,
    solves,
    display,
    requestCategory,
    get allSolves() {
      return [
        ...this.solves.death_benefit_solves,
        ...this.solves.no_solves,
        ...this.solves.premium_solves,
      ].map(v => v.value);
    },
    get isPermanent() {
      return true;
    },
    get partnerDiscountOptions() {
      return [
        { text: "Single", value: null, requiresJointInfo: null },
        { text: "Married", value: "one", requiresJointInfo: null },
      ];
    },
    get defaults() {
      return { ...defaults, categories };
    },
    get modes() {
      return modes;
    },
    get incomeSolves() {
      return ["No", "Max"];
    },
    get ltcRiderOptions() {
      return RIDER_OPTIONS;
    },
    get ltcRiderPercentageOptions() {
      return RIDER_PERCENTAGE_OPTIONS;
    },
    get loanTypes() {
      return LOAN_TYPES;
    },
    get creditingRates() {
      return CREDITING_RATES;
    },
    permitFetchableType(type) {
      if (!requestCategories[type]) throw "Must be a supported type";
      fetchableTypes.push(type);
      categories.push(requestCategories[type]);
    },
    showPremium(params) {
      return [
        MIN_NON_MEC_DEATH_BENEFIT,
        TARGET_DEATH_BENEFIT,
        DEATH_BENEFIT_SOLVE_CASH_VALUE_AT_AGE,
        LAPSE_PROTECTION_DEATH_BENEFIT,
        SPECIFY_PREMIUM_AND_DEATH_BENEFIT,
      ].includes(params.solve);
    },
    showFaceAmount(params) {
      return [
        SPECIFY_PREMIUM_AND_DEATH_BENEFIT,
        MAX_NON_MEC_PREMIUM,
        TARGET_PREMIUM,
        PREMIUM_SOLVE_CASH_VALUE_AT_AGE,
        LAPSE_PROTECTION_PREMIUM,
      ].includes(params.solve);
    },
    showLapseProtectionToAge(params) {
      return [
        LAPSE_PROTECTION_DEATH_BENEFIT,
        LAPSE_PROTECTION_PREMIUM,
      ].includes(params.solve);
    },
    showIncomeSolve(params) {
      return [
        MIN_NON_MEC_DEATH_BENEFIT,
        TARGET_DEATH_BENEFIT,
        SPECIFY_PREMIUM_AND_DEATH_BENEFIT,
        MAX_NON_MEC_PREMIUM,
        TARGET_PREMIUM,
      ].includes(params.solve);
    },
    showCashValue(params) {
      return [
        DEATH_BENEFIT_SOLVE_CASH_VALUE_AT_AGE,
        PREMIUM_SOLVE_CASH_VALUE_AT_AGE,
      ].includes(params.solve);
    },
    showAgeValue(params) {
      return [
        DEATH_BENEFIT_SOLVE_CASH_VALUE_AT_AGE,
        PREMIUM_SOLVE_CASH_VALUE_AT_AGE,
      ].includes(params.solve);
    },
    showCreditingRate(params) {
      return [
        MIN_NON_MEC_DEATH_BENEFIT,
        TARGET_DEATH_BENEFIT,
        DEATH_BENEFIT_SOLVE_CASH_VALUE_AT_AGE,
        SPECIFY_PREMIUM_AND_DEATH_BENEFIT,
        MAX_NON_MEC_PREMIUM,
        TARGET_PREMIUM,
        PREMIUM_SOLVE_CASH_VALUE_AT_AGE,
      ].includes(params.solve);
    },
    showLoanType(params) {
      if (!this.showIncomeSolve(params)) return false;
      return params.income_solve === "Max";
    },
    showLtcRiderPercentage(params) {
      return [
        WITH_CHRONIC_ILLNESS_LTC_RIDER,
        WITH_FULL_CHRONIC_ILLNESS_LTC_RIDER,
        WITH_LTC_RIDER,
        WITH_CHRONIC_ILLNESS_RIDER,
      ].includes(params.filter_chronic_illness_and_ltc);
    },
    showPartnerDiscount(params) {
      return [
        WITH_CHRONIC_ILLNESS_LTC_RIDER,
        WITH_FULL_CHRONIC_ILLNESS_LTC_RIDER,
        WITH_LTC_RIDER,
      ].includes(params.filter_chronic_illness_and_ltc);
    },
    showIncomeAges(params) {
      if (!this.showIncomeSolve(params)) return false;
      return params.income_solve === "Max";
    },
    showHealth(conversion) {
      return !conversion;
    },
    renderRules(params, options = { conversion: false }) {
      const premium = this.showPremium(params);
      const faceAmount = this.showFaceAmount(params);
      const lapseProtectionToAge = this.showLapseProtectionToAge(params);
      const ltcRiderPercentage = this.showLtcRiderPercentage(params);
      const incomeSolve = this.showIncomeSolve(params);
      const cashValue = this.showCashValue(params);
      const ageValue = this.showAgeValue(params);
      const incomeAges = this.showIncomeAges(params);
      const partnerDiscount = this.showPartnerDiscount(params);
      return {
        ageValue,
        cashValue,
        faceAmount,
        incomeStartAge: incomeAges,
        incomeEndAge: incomeAges,
        incomeSolve,
        lapseProtectionToAge,
        saveAge: true,
        subtype: true,
        ltcRider: true,
        ltcRiderPercentage,
        payDuration: true,
        partnerDiscount,
        premium,
        insuredHealth: this.showHealth(options.conversion),
        solve: true,
        mode: true,
      };
    },
    advancedRenderRules(params) {
      return {
        section1035ExchangeAmount: true,
        loanType: this.showLoanType(params),
        creditingRate: this.showCreditingRate(params),
      };
    },
    eAppParser(model = {}) {
      const allSolves = [
        ...this.solves.death_benefit_solves,
        ...this.solves.no_solves,
        ...this.solves.premium_solves,
      ];

      const useModelSolve = allSolves.some(
        ({ value }) => value === model?.solve
      );
      let solve = this.defaults.solve;
      if (useModelSolve) solve = model?.solve;

      let pay_duration = this.defaults.pay_duration;
      if (model.pay_duration) pay_duration = model.pay_duration;

      let vitality_level = model?.vitality_level;

      if (!VITALITY_LEVELS.some(({ value }) => value === vitality_level)) {
        vitality_level = this.defaults.vitality_level;
      }

      let creditingRate = model?.crediting_rate;
      if (!CREDITING_RATES.some(({ value }) => value === creditingRate)) {
        creditingRate = this.defaults.crediting_rate;
      }

      let saveAge = model.save_age;
      if (!isBoolean(saveAge)) saveAge = this.defaults.save_age;

      let loan_type = this.defaults.loan_type;
      if (LOAN_TYPES.some(({ value }) => model.loan_type === value)) {
        loan_type = model.loan_type;
      }

      let filter_chronic_illness_and_ltc =
        model?.filter_chronic_illness_and_ltc;
      if (!filter_chronic_illness_and_ltc) {
        filter_chronic_illness_and_ltc =
          this.defaults.filter_chronic_illness_and_ltc;
      }

      let ltc_rider_percentage = +model?.ltc_rider_percentage;
      if (!ltc_rider_percentage || isNaN(ltc_rider_percentage)) {
        ltc_rider_percentage = this.defaults.ltc_rider_percentage;
      }

      if (filter_chronic_illness_and_ltc === WITHOUT_CHRONIC_LTC) {
        ltc_rider_percentage = undefined;
      }

      let partnerDiscount = model?.partner_discount;
      if (partnerDiscount === "both") partnerDiscount = "one";
      else if (
        !this.partnerDiscountOptions.some(
          ({ value }) => value === partnerDiscount
        )
      ) {
        partnerDiscount = this.defaults.partner_discount;
      }

      let income_start_age = model?.income_start_age;
      let income_end_age = model?.income_end_age;
      if (model?.age) {
        if (income_start_age < model.age) {
          income_start_age = Math.max(model.age + 10, 66);
          income_end_age = Math.max(income_start_age + 10, 90);
        }
      }

      if (!income_start_age) income_start_age = this.defaults.income_start_age;
      if (!income_end_age) income_end_age = this.defaults.income_end_age;

      let face_amount = model?.face_amount || model?.initial_death_benefit;
      if (!face_amount) face_amount = 1000000;

      let benefit_period = this.defaults.benefit_period;
      if (model?.benefit_period) {
        benefit_period = closestCalc(
          model.benefit_period,
          BENEFIT_PERIOD_OPTIONS.map(({ value }) => value)
        );
      }

      let section_1035_exchange_amount = null;
      if (model?.section_1035_exchange?.amount) {
        section_1035_exchange_amount = model.section_1035_exchange.amount;
      } else if (model?.section_1035_exchange_amount) {
        section_1035_exchange_amount = model.section_1035_exchange_amount;
      }

      let premium = this.defaults.premium;
      if (model?.premium || model?.premium === 0) premium = model.premium;

      let age_value = this.defaults.age_value;
      if (model?.age_value || model?.age_value === 0)
        age_value = model.age_value;

      return {
        benefit_period,
        premium,
        solve,
        pay_duration: +pay_duration,
        cash_value: model?.cash_value || this.defaults.cash_value,
        age_value,
        lapse_protection_to_age:
          model?.lapse_protection_to_age ||
          this.defaults.lapse_protection_to_age,
        crediting_rate: creditingRate,
        filter_chronic_illness_and_ltc,
        ltc_rider_percentage,
        income_solve: model?.income_solve || this.defaults.income_solve,
        income_start_age,
        income_end_age,
        loan_type,
        section_1035_exchange_amount,
        face_amount,
        vitality_level,
        partner_discount: partnerDiscount,
        save_age: saveAge,
      };
    },
    toQuoteRequest({ eAppParams = {}, insured, agentId, conversion }) {
      const paramObj = eAppParams;

      return {
        agent: { id: agentId },
        compare: false,

        ...insuredQuoteData(insured),

        selected_type: eAppParams.selected_type,
        state: eAppParams.state,

        mode: paramObj.mode,
        ...this.toQuoteScenario(paramObj, { conversion }),
      };
    },
    toQuoteScenario(paramObj = {}, options = { conversion: false }) {
      let section_1035_exchange = undefined;
      if (paramObj.section_1035_exchange_amount) {
        section_1035_exchange = {
          enabled: true,
          amount: paramObj.section_1035_exchange_amount,
        };
      }

      const premiumOrFaceAmount = {};
      const renderRules = this.renderRules(paramObj, options);
      if (renderRules.premium) {
        premiumOrFaceAmount.premium = paramObj.premium;
      }
      if (renderRules.faceAmount) {
        premiumOrFaceAmount.face_amount = paramObj.face_amount;
      }

      let categories = [];
      if (paramObj.subtype) {
        categories.push(requestCategories[paramObj.subtype]);
      } else {
        categories = this.categories;
      }

      let income_start_age = paramObj.income_start_age,
        income_end_age = paramObj.income_end_age;

      if (paramObj.income_solve === "Max") {
        income_start_age = income_start_age || this.defaults.income_start_age;
        income_end_age = income_end_age || this.defaults.income_end_age;
      }

      let ltc_rider_percentage = paramObj.ltc_rider_percentage;
      if (paramObj.filter_chronic_illness_and_ltc === WITHOUT_CHRONIC_LTC) {
        ltc_rider_percentage = undefined;
      }

      return {
        categories,
        age_value: paramObj.age_value,
        cash_value: paramObj.cash_value,
        crediting_rate: paramObj.crediting_rate,
        filter_chronic_illness_and_ltc: paramObj.filter_chronic_illness_and_ltc,
        income_solve: paramObj.income_solve,
        income_start_age,
        income_end_age,
        lapse_protection_to_age: paramObj.lapse_protection_to_age,
        loan_type: paramObj.loan_type,
        ltc_rider_percentage,
        pay_duration: paramObj.pay_duration,
        partner_discount: paramObj.partner_discount,
        solve: paramObj.solve,
        vitality_level: paramObj.vitality_level,
        section_1035_exchange,

        save_age: paramObj.save_age,
        //conditionals
        ...premiumOrFaceAmount,
      };
    },
    generatePayDurationOptions(birthdate) {
      const years = dateToYears(birthdate);
      const payOptions = [];
      for (let i = years + 1; i <= 100; i++) {
        payOptions.push({
          value: i - years,
          title: `${i - years} Year${i - years === 1 ? "" : "s"} / To age ${i}`,
        });
      }
      payOptions.push({
        value: -1,
        title: "Life",
      });
      return payOptions;
    },
    generateAgeValueOptions(birthdate) {
      const years = dateToYears(birthdate);

      const ages = [];
      for (let i = years + 1; i <= 120; i++) {
        ages.push({ text: `Age ${i}`, value: i });
      }
      return ages;
    },
    generateLapseProtectionToAgeOptions(birthdate) {
      const years = dateToYears(birthdate);
      const ages = [];
      for (let i = years + 1; i <= 120; i++) {
        ages.push({ text: `Age ${i}`, value: i });
      }
      return ages;
    },
    generateIncomeAges(birthdate) {
      const years = dateToYears(birthdate);
      const ages = [];
      for (let i = 0; i < MAX_INCOME_AGE - years; i++) {
        ages.push(years + i);
      }
      return ages;
    },
  };
}

export function AccumulationIUL() {
  return BaseUniversalLife(PRODUCTS.ACCUMULATION_IUL);
}

export function ProtectionIUL() {
  return BaseUniversalLife(PRODUCTS.PROTECTION_IUL);
}

export function ProtectionVUL() {
  return BaseUniversalLife(PRODUCTS.PROTECTION_VUL);
}

export function UniversalLife() {
  return BaseUniversalLife(PRODUCTS.UNIVERSAL_LIFE);
}

export function AllUniversalLife() {
  return BaseUniversalLife(CONCEPTS.ALL_UL);
}
