import { stepToRouteName, steps } from "#src/enumerations/step-enumeration.js";
import { defineStore } from "#src/stores/state-wrapper.js";

export const CRITICAL_FAILURE_REASONS = {
  SERVER_BLOCKED: "SERVER_BLOCKED",
  FAILED_TO_BOOT: "FAILED_TO_BOOT",
  BAD_DOMAIN: "BAD_DOMAIN",
  NO_PRODUCTS: "NO_PRODUCTS",
  REQUIRES_SUBSCRIPTION: "REQUIRES_SUBSCRIPTION",
};

export const useStepCriticalFailure = (pinia, hot) =>
  defineStore("step-critical-failure", {
    state: () => ({ criticalFailureReason: null }),
    getters: {
      step: () => steps.CRITICAL_FAILURE,
      routeName: s => stepToRouteName[s.step],
      next: () => null,
      previous: () => null,
      hidesFooter: () => true,
      controlsNext: () => true,
      hidesHeader: () => true,
    },
  })(pinia, hot);
