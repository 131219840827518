<template>
  <v-row class="ma-0" justify="start" align="center">
    <div
      v-for="(item, index) in props.items"
      :key="index"
      class="checkbox-width mr-3"
    >
      <v-checkbox
        :id="props.dataTestid"
        v-model="model"
        class="checkbox-opacity partial-opacity px-1"
        density="compact"
        hide-details
        :label="item.title"
        :class="inputClasses"
        :base-color="baseColor"
        :color="color"
        :data-testid="`${props.dataTestid}-${item.title}`"
        :error-messages="errorMessages"
        :value="item.value"
        @update:model-value="debounceAndValidate"
      >
        <template #label>
          <span @click.prevent>{{ item.title }}</span>
        </template>
        <template v-if="$slots.message" #message>
          <slot name="message" />
        </template>
      </v-checkbox>
    </div>
    <active-save-indicator :controller="savingBuffer" class="ml-1" />
  </v-row>
</template>

<script setup>
import ActiveSaveIndicator from "#src/components/shared/ActiveSaveIndicator.vue";
import {
  useInput,
  useInputProps,
} from "#src/composables/savable-input.composable";
import { inject } from "vue";

const pinia = inject("pinia");
const props = defineProps({
  ...useInputProps(),
  items: { type: Array, required: true },
  label: { type: String, required: false, default: "" },
});

const {
  model,
  inputClasses,
  color,
  baseColor,
  errorMessages,
  savingBuffer,
  debounceAndValidate,
} = useInput(props, pinia, 300, {
  successClass: "success-checkbox",
  errorClass: "error-checkbox",
});
</script>
