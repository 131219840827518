import {
  AutocompleteField,
  CurrencyField,
  CustomButtonsField,
  FormFieldAdapter,
  SelectField,
  TextField,
  TextareaField,
  YesNoField,
} from "#src/components/form-builder/FormBuilderTemplates.js";
import {
  BEST_TIME_TO_CALL_OPTIONS,
  PURPOSE_OPTIONS,
} from "#src/data/refer-info.js";
import { useEappStore } from "#src/stores/electronic-application.js";
import { mdiClock, mdiCurrencyUsd, mdiMessageReply, mdiSitemap } from "@mdi/js";

function genBuilderProps(key, additionalProps) {
  return {
    mdColSize: additionalProps?.mdColSize || 6,
    colSize: 12,
    key,
    slots: additionalProps?.slots || undefined,
  };
}

export function useEappBestTimeToCallProperty(pinia, additionalProps = {}) {
  return FormFieldAdapter(
    SelectField({
      dataTestid: "best-time-to-call",
      label: "Best Time to Call",
      placeholder: "Best Time to Call",
      prependInnerIcon: mdiClock,
      items: BEST_TIME_TO_CALL_OPTIONS,
      savableProperty: useEappStore(pinia).best_time_to_call,
    }),
    genBuilderProps("best_time_to_call", additionalProps),
    {
      overrideSave: () =>
        useEappStore(pinia).saveAttributes(["best_time_to_call"]),
      ...additionalProps,
    }
  );
}

export function useEappPurposeOfInsuranceProperty(pinia, additionalProps = {}) {
  return FormFieldAdapter(
    SelectField({
      dataTestid: "purpose-of-insurance",
      label: "Purpose of Insurance",
      placeholder: "Purpose of Insurance",
      prependInnerIcon: mdiSitemap,
      items: PURPOSE_OPTIONS,
      savableProperty: useEappStore(pinia).purpose_of_insurance,
    }),
    genBuilderProps("purpose_of_insurance", additionalProps),
    {
      overrideSave: () =>
        useEappStore(pinia).saveAttributes(["purpose_of_insurance"]),
      ...additionalProps,
    }
  );
}

export function useEappReferNotesProperty(pinia, additionalProps = {}) {
  return FormFieldAdapter(
    TextareaField({
      dataTestid: "refer-notes",
      label: "Additional Details",
      placeholder: "Additional Details",
      icon: mdiMessageReply,
      savableProperty: useEappStore(pinia).refer_notes,
    }),
    genBuilderProps("refer_notes", additionalProps),
    {
      overrideSave: () => useEappStore(pinia).saveAttributes(["refer_notes"]),
      ...additionalProps,
    }
  );
}

export function useEappReferTextProperty(pinia, additionalProps = {}) {
  return FormFieldAdapter(
    CustomButtonsField({
      dataTestid: "refer-send-text",
      label: "Send introduction text?",
      savableProperty: useEappStore(pinia).send_refer_text,
      items: [
        { title: "Yes", value: true, dataTestid: "refer-send-text-yes" },
        { title: "No", value: false, dataTestid: "refer-send-text-no" },
      ],
    }),
    genBuilderProps("send_refer_text", additionalProps),
    {
      overrideSave: () =>
        useEappStore(pinia).saveAttributes(["send_refer_text"]),
      ...additionalProps,
    }
  );
}

export function useEappOtherPendingInsuranceAmountToBeAcceptedProperty(
  pinia,
  additionalProps = {}
) {
  return FormFieldAdapter(
    CurrencyField({
      dataTestid: "pending-coverage-amount",
      placeholder: "Pending Coverage Amount",
      prependInnerIcon: mdiCurrencyUsd,
      label: "Pending Coverage Amount",
      savableProperty:
        useEappStore(pinia).other_pending_insurance_amount_to_be_accepted,
    }),
    genBuilderProps(
      "other_pending_insurance_amount_to_be_accepted",
      additionalProps
    ),
    {
      overrideSave: () =>
        useEappStore(pinia).saveAttributes([
          "other_pending_insurance_amount_to_be_accepted",
        ]),
      ...additionalProps,
    }
  );
}

export function useEappTiaProperty(pinia, additionalProps = {}) {
  return FormFieldAdapter(
    YesNoField({
      dataTestid: "tia",
      savableProperty: useEappStore(pinia).tia,
    }),
    genBuilderProps("tia", additionalProps),
    {
      overrideSave: () => useEappStore(pinia).saveAttributes(["tia"]),
      ...additionalProps,
    }
  );
}

export function useEappRoundRobinAgentIdProperty(pinia, additionalProps = {}) {
  return FormFieldAdapter(
    AutocompleteField({
      label: "Refer to a specific advisor?",
      dataTestid: "round-robin-agent",
      items: additionalProps.items,
      savableProperty: useEappStore(pinia).round_robin_agent_id,
    }),
    genBuilderProps("round_robin_agent_id", additionalProps),
    {
      overrideSave: () =>
        useEappStore(pinia).saveAttributes(["round_robin_agent_id"]),
      ...additionalProps,
    }
  );
}

export function useEappDemoNameProperty(pinia, additionalProps = {}) {
  return FormFieldAdapter(
    TextField({
      dataTestid: "demo-name",
      items: additionalProps.items,
      savableProperty: useEappStore(pinia).demo_name,
    }),
    genBuilderProps("demo_name", additionalProps),
    {
      overrideSave: () => useEappStore(pinia).saveAttributes(["demo_name"]),
      ...additionalProps,
    }
  );
}
